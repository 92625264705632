import React, { useState, useReducer, useEffect } from 'react';
import { CardContent, Typography, styled, makeStyles, useTheme } from '@material-ui/core';
import {
  Collapse,
  TextField,
  Checkbox,
  CheckboxList,
  DateField,
  StateReducer,
  stateReducer,
  ElementProps,
  minWidthMediaQueries,
  maxWidthMediaQueries,
  Card,
  useDebouncedCallback,
} from '@axial-healthcare/axial-react';
import { isValid } from 'date-fns';
import { useRouteQueryParams } from 'src/constants/utilities';
import { defaultFiltersState, FilterNames, PatientFilters, FilterConfig } from './filters-provider';

interface PatientIntelligenceFiltersProps {
  initialValues: PatientFilters;
  setFilters: (state: Partial<PatientFilters>) => void;
  filterConfigs: FilterConfig[];
  className?: string;
}
const PatientIntelligenceFilters: React.FC<PatientIntelligenceFiltersProps> = ({
  initialValues,
  setFilters,
  filterConfigs,
  className,
}: PatientIntelligenceFiltersProps): React.ReactElement => {
  const { standardFilters, collapsibleFilters } = useStyles();
  const [hasFiltersSet, setHasFiltersSet] = useState<boolean>(false);

  const onClear = (): void => {
    setFilters(defaultFiltersState);
  };

  return (
    <Card className={className}>
      {/* Desktop Filters */}
      <div className={standardFilters}>
        <FilterHeader className={standardFilters} showClear={hasFiltersSet} onClear={onClear} style={{ padding: 16 }} />
        <FiltersContent
          filterConfigs={filterConfigs}
          filters={initialValues}
          setFilters={setFilters}
          setHasFiltersSet={setHasFiltersSet}
        />
      </div>
      {/* Mobile Filters */}
      <Collapse
        title={<FilterHeader showClear={hasFiltersSet} onClear={onClear} />}
        className={collapsibleFilters}
        CollapseIconProps={{ right: true, style: { fontSize: '1.1rem' } }}
        ButtonBaseProps={{ disableRipple: true, style: { alignItems: 'center', padding: 16 } }}
        CollapseContentProps={{ style: { padding: 0 } }}
      >
        <FiltersContent
          filterConfigs={filterConfigs}
          filters={initialValues}
          setFilters={setFilters}
          setHasFiltersSet={setHasFiltersSet}
        />
      </Collapse>
    </Card>
  );
};

interface FilterHeaderProps {
  showClear: boolean;
  onClear: () => void;
  className?: string;
  style?: React.CSSProperties;
}
const FilterHeader: React.FC<FilterHeaderProps> = ({
  showClear,
  onClear,
  className,
  style,
}: FilterHeaderProps): React.ReactElement => {
  const { typography } = useTheme();

  const handleClear = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    event.stopPropagation();
    onClear();
  };

  return (
    <Typography
      className={className}
      variant="h3"
      style={{
        fontWeight: 'normal',
        fontFamily: typography.body1.fontFamily,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...style,
      }}
    >
      Filter Patients
      {showClear ? <ClearAllButton onClick={handleClear}>Clear All</ClearAllButton> : null}
    </Typography>
  );
};

interface FiltersContentProps {
  filterConfigs: FilterConfig[];
  filters: PatientFilters;
  setFilters: React.Dispatch<Partial<PatientFilters>>;
  setHasFiltersSet: React.Dispatch<boolean>;
}
const FiltersContent: React.FC<FiltersContentProps> = ({
  filterConfigs,
  filters,
  setFilters,
  setHasFiltersSet,
}: FiltersContentProps): React.ReactElement => {
  const [filterInputValues, setFilterInputValues] = useReducer<StateReducer<PatientFilters>>(stateReducer, filters);

  const query: URLSearchParams = useRouteQueryParams();
  const initialNotificationTypeFilter: string | null = query.get('type');

  useEffect(() => {
    setHasFiltersSet(checkHasFiltersSet(filterInputValues));
  }, [filterInputValues, setHasFiltersSet]);

  useEffect(() => {
    if (!checkHasFiltersSet(filters)) {
      setFilterInputValues(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (initialNotificationTypeFilter) {
      setFilters({ notificationType: [initialNotificationTypeFilter] });
      setFilterInputValues({ notificationType: [initialNotificationTypeFilter] });
    }
  }, [initialNotificationTypeFilter, setFilters]);

  const checkHasFiltersSet = (filtersToCheck: PatientFilters): boolean => {
    return Object.keys(filtersToCheck).some((key: string) => {
      const value = filtersToCheck[key as FilterNames];
      return Array.isArray(value) ? value.length > 0 : !!value;
    });
  };

  const setFiltersWithDelay: (value: Partial<PatientFilters>) => void = useDebouncedCallback(setFilters, 600);
  // tslint:disable-next-line typedef
  const buildTextOnChange =
    ({ key, debounce }: { key: FilterNames; debounce?: boolean }) =>
    (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const updatedFiltersValue: Partial<PatientFilters> = { [key]: event.target.value };
      setFilterInputValues(updatedFiltersValue);
      debounce ? setFiltersWithDelay(updatedFiltersValue) : setFilters(updatedFiltersValue);
    };

  const buildDateOnChange =
    (key: FilterNames) =>
    (date: Date | null): void => {
      if (date === null || isValid(date)) {
        setFilters({ [key]: date });
      }
      setFilterInputValues({ [key]: date });
    };

  const buildCheckboxListOnChange =
    (key: FilterNames) =>
    (value: string[]): void => {
      setFilters({ [key]: value });
      setFilterInputValues({ [key]: value });
    };

  const buildCheckboxOnChange =
    (key: FilterNames) =>
    (_changeEvent: React.ChangeEvent, checked: boolean): void => {
      setFilters({ [key]: checked });
      setFilterInputValues({ [key]: checked });
    };

  return (
    <CardContent style={{ paddingTop: 8 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {filterConfigs.map((filter: FilterConfig, index: number) => {
          const commonStyles = ({ marginTop }: { marginTop?: number } = {}): React.CSSProperties => ({
            ...(index !== 0 ? { marginTop: marginTop !== undefined ? marginTop : 8 } : {}),
          });

          if (filter.type === 'text' && filter.TextField) {
            return (
              <TextField
                style={commonStyles()}
                size="small"
                fullWidth={true}
                key={filter.key}
                variant="outlined"
                value={filterInputValues[filter.key]}
                onChange={buildTextOnChange({ key: filter.key, debounce: true })}
                onBlur={buildTextOnChange({ key: filter.key })}
                {...filter.TextField}
              />
            );
          }
          if (filter.type === 'checkbox' && filter.Checkbox) {
            return (
              <Checkbox
                key={filter.key}
                checked={filterInputValues[filter.key] as boolean}
                CheckboxProps={{ onChange: buildCheckboxOnChange(filter.key) }}
                {...filter.Checkbox}
              />
            );
          }
          if (filter.type === 'checkbox-list' && filter.CheckboxList) {
            const { options, ...restCheckboxList } = filter.CheckboxList;
            return (
              <CheckboxList
                style={{ ...commonStyles({ marginTop: 26 }), width: '100%' }}
                key={filter.key}
                value={filterInputValues[filter.key] as string[]}
                onChange={buildCheckboxListOnChange(filter.key)}
                options={options || []}
                {...restCheckboxList}
              />
            );
          }
          if (filter.type === 'date' && filter.DatePicker) {
            return (
              <DateField
                inputVariant="outlined"
                style={commonStyles()}
                fullWidth={true}
                key={filter.key}
                onChange={buildDateOnChange(filter.key)}
                value={filterInputValues[filter.key] as Date}
                {...filter.DatePicker}
              />
            );
          }
          return null;
        })}
      </div>
    </CardContent>
  );
};

const ClearAllButton: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  lineHeight: '21px',
  textDecoration: 'underline',
  fontSize: '0.8rem',
  marginLeft: 8,
  color: theme.palette.text.primary,
  fontFamily: theme.typography.body2.fontFamily,
  '&:hover': { cursor: 'pointer' },
}));

type StyleClasses = Record<'standardFilters' | 'collapsibleFilters', string>;
const useStyles: () => StyleClasses = makeStyles({
  collapsibleFilters: {
    [minWidthMediaQueries.largeDesktop]: {
      display: 'none !important',
    },
  },
  standardFilters: {
    [maxWidthMediaQueries.desktop]: {
      display: 'none !important',
    },
  },
});

export { PatientIntelligenceFilters };
