import React, { ReactElement, useState, ChangeEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, useTheme } from '@material-ui/core';
import { StyledList } from '@axial-healthcare/axial-react';
import { CollapsibleText } from 'src/components/collapsible-text';
import { TextBlock } from 'src/components/text-block';
import { moduleRoutes } from 'src/modules/constants';
import { SurveyContinue } from '../../components/survey-continue';
import { SurveyQuestion } from '../../components/survey-question';

const OpioidReductionPatientEngagementView: React.FC<{ faqsUrl: string }> = ({
  faqsUrl,
}: {
  faqsUrl: string;
}): ReactElement => {
  const [selection, setSelection] = useState('');

  const { typography } = useTheme();

  const updateSelection = (ev: ChangeEvent<HTMLInputElement>): void => {
    setSelection(ev.target.value);
  };

  return (
    <>
      <Typography variant="h2">Patient-Centric Opioid Reduction Principles</Typography>
      <CollapsibleText title="When Opioid Reduction Is The Goal" initCollapsed={false}>
        <StyledList
          style={{ marginTop: 0 }}
          items={[
            <>
              Assess any/all negative impacts from opioid use (e.g., cognitive effects, fatigue, poor sleep, effort to
              obtain scripts, stigma, etc.)
            </>,
            <>
              Communicate the long term risks of opioids and why using less medication is in their best interest, doing
              so will minimize perceptions of injustice and blame
            </>,
            <>
              Assess and provide education on how psychobehavioral factors (e.g., anxiety, poor pacing) can lead to
              greater use of opioids
            </>,
            <>
              Declare your multimodal treatment philosophy by emphasizing that opioids may be only one part of a care
              plan, multimodal treatment may include non-opioid medications, physical therapy, cognitive behavioral
              therapy, etc.
            </>,
            <>Emphasize self-management by partnering with patients in reducing their opioid risks</>,
            <>Assess patient motivation and readiness to reduce opioids</>,
            <>Ask patients what their concerns are about reducing opioids</>,
            <>
              Go slow as most taper guidelines suggest taper schedules that are too aggressive for patients on multiple
              medications and high opioid doses. A good target is substantial reduction at 4 months, and as low as
              possible at 10 months
            </>,
            <>
              Provide specific resources by printing out and reviewing the Opioid Reduction Plan and{' '}
              <NavLink to={`${faqsUrl}/patients`}>patient FAQs</NavLink> with the patient
            </>,
            <>
              Provide ongoing support by scheduling follow up visits to check in on the patient's overall wellness and
              mental health as they progress through the opioid reduction plan
            </>,
            <>
              Remember that not everyone will taper completely. The goal is to get patients as low as possible in 10
              months
            </>,
          ]}
        />
        <p style={{ fontSize: '0.7rem', marginTop: 30, marginBottom: 0 }}>
          Adapted from work by Beth Darnall PhD &copy; 2017
        </p>
      </CollapsibleText>
      <CollapsibleText title="Talking To Your Patient: Conversation Tips And Scripts">
        <span style={{ fontWeight: typography.fontWeightBold }}>Example script:</span>
        <TextBlock style={{ fontStyle: 'italic' }}>
          I was reviewing your chart and noticed that you've been on opioids for [length of time] without major
          improvement. New federal guidelines are asking doctors to reduce opioids for chronic pain as the data suggests
          they don't work well in the long term for some patients, and can cause a lot of problems and health risks.
          Interestingly, research also shows that when people who have been on opioids for years reduce their dose, they
          do better! In general, their pain actually decreases and mood improves. Side effects go away, and health risks
          decline. For all of these reasons, I think the best plan for your pain is to get you on a gradual opioid
          reduction program. So gradual your body may not notice the medicine is being reduced, resulting in no or
          minimal side effects. We would take 6-10 months to get you down as low as possible. We will focus on treating
          your pain differently, getting you connected with self-management resources, and maybe using some lower risk
          non-opioid medications. I would like to partner with you on this. I will follow you closely and we will go
          very gradually to help you succeed.
        </TextBlock>
        <StyledList
          items={[
            <>If patient is not interested in reducing opioids, ask why</>,
            <>
              Assess history of withdrawal symptoms. Patients often believe that they will experience withdrawals and
              increased pain if medications are reduced too quickly
              <div style={{ fontWeight: typography.fontWeightBold }}>Examples:</div>
              <ul style={{ listStyleType: 'circle' }}>
                <li style={{ fontStyle: 'italic' }}>
                  "Have you ever missed a dose of medication, or had withdrawals before?"
                </li>
                <li style={{ fontStyle: 'italic' }}>
                  "When done very gradually most people do not have more pain - and studies show that many find their
                  pain actually gets better."
                </li>
              </ul>
            </>,
            <>
              Educate patients about the distinction between withdrawal symptoms, "baseline pain" and what they can
              expect from a gradual opioid reduction
            </>,
            <>
              Assure patients. Emphasize that they will still have access to acute pain care as needed, but that the
              long-term goal may be to resume the opioid reduction afterward
              <div style={{ fontWeight: typography.fontWeightBold }}>Examples:</div>
              <ul style={{ listStyleType: 'circle' }}>
                <li style={{ fontStyle: 'italic' }}>
                  "We will stay in close communication so that in the unlikely event your pain increases we can learn
                  from it and understand why it's happening. We can also pause the reduction and work with your body."
                </li>
                <li style={{ fontStyle: 'italic' }}>
                  "It's not about taking something away from you. It's about treating your pain better, with lower
                  risks."
                </li>
              </ul>
            </>,
          ]}
        />
        <p style={{ fontSize: '0.7rem', marginTop: 30, marginBottom: 0 }}>
          <p>Adapted from work by Beth Darnall PhD &copy; 2017</p>
          <p>
            DISCLAIMER: The content within these pages is intended to provide support to practicing clinicians as they
            make patient care decisions. The clinician should decide whether the suggested concepts and scripting should
            be adapted for each provider/patient conversation and relationship. The suggested opioid reduction
            approaches are not fixed protocols and need to be considered in light of each unique patient. The clinician
            should decide whether the suggested language should be used as written; provided that it does not serve as a
            substitute for standard medical practices, protocols or procedures. The resources within this clinical
            decision support platform are not intended to substitute for the advice of a provider or other health care
            professional.
          </p>
        </p>
      </CollapsibleText>
      <div style={{ marginTop: 30 }}>
        <SurveyQuestion
          label="Choose the option that most applies"
          onChange={updateSelection}
          layout="column"
          options={[
            {
              value: `${moduleRoutes.opioidReduction}/report`,
              label: 'Patient could benefit from reducing opioids and is ready',
            },
            {
              value: '/1',
              label: 'Patient risk benefit assessment of continued opioid use is needed',
            },
            {
              value: '/monitor',
              label: 'Patient could benefit from opioid reduction but is not ready',
            },
          ]}
        />
        {selection ? <SurveyContinue to={selection} isSurvey={!selection.includes('report')} /> : null}
      </div>
    </>
  );
};

export { OpioidReductionPatientEngagementView };
