import React from 'react';
import { Grid, styled, Typography, useTheme } from '@material-ui/core';
import {
  CollapseIcon,
  axialColors,
  usePrintStyles,
  ElementProps,
  maxWidthMediaQueries,
  minWidthMediaQueries,
} from '@axial-healthcare/axial-react';
import { getScoreColor } from '../../index';
import { RimReportCategoryMeasure } from '../../interfaces';
import { ScoreBar } from '../score-bar';
import { MemberList } from './member-list';

interface QualityMeasureDetailProps {
  measure: RimReportCategoryMeasure;
  isReportLatest: boolean;
}
const QualityMeasureDetail: React.FC<QualityMeasureDetailProps> = ({
  measure: {
    description,
    thresholds: { threshold1 },
    member_count,
    members,
  },
  isReportLatest,
}: QualityMeasureDetailProps): React.ReactElement => {
  const thresholdNum: number = parseFloat(threshold1);
  const maximizingPercentage: number | undefined = isNaN(thresholdNum) ? undefined : thresholdNum * 100;
  return (
    <>
      <p>{description}</p>
      <MeasureColumnsContainer>
        <div className="maximize-info">
          <MeasureDetailHeader>How many patients would maximize my score?</MeasureDetailHeader>
          {maximizingPercentage ? <div>Fewer than {maximizingPercentage}%</div> : null}
        </div>
        <div className="contributing-members">
          <MeasureDetailHeader>
            {`${member_count} contributing ${member_count === 1 ? 'patient' : 'patients'}`}
          </MeasureDetailHeader>
          <MemberList members={members} isReportLatest={isReportLatest} />
        </div>
      </MeasureColumnsContainer>
    </>
  );
};

interface QualityMeasureSummaryProps {
  measure: RimReportCategoryMeasure;
  collapsed: boolean;
  idAttribute?: string;
}
const QualityMeasureSummary: React.FC<QualityMeasureSummaryProps> = ({
  measure,
  collapsed,
  idAttribute,
}: QualityMeasureSummaryProps): React.ReactElement => {
  const { display_name, score, score_max, points } = measure;
  const { palette, typography } = useTheme();
  const { noPrint } = usePrintStyles();

  return (
    <Grid
      id={idAttribute}
      style={{ fontWeight: 'normal' }}
      container={true}
      direction="row"
      justify="space-between"
      wrap="nowrap"
      alignItems="center"
    >
      <MeasureTitleContainer id={idAttribute}>
        <Typography
          id={idAttribute}
          variant="h3"
          style={{ fontSize: '0.9rem', color: palette.text.primary, fontFamily: typography.body2.fontFamily }}
        >
          {display_name}
        </Typography>
        <QualityMeasureScore idAttribute={idAttribute} score={score} scoreMax={score_max} scoreThreshold={points} />
      </MeasureTitleContainer>
      <ExpandText className={noPrint} id={idAttribute}>
        {collapsed ? 'Details' : 'Collapse'}
        <CollapseIcon id={idAttribute} collapsed={collapsed} style={{ marginLeft: 6 }} />
      </ExpandText>
    </Grid>
  );
};

interface QualityMeasureScoreProps {
  score: string | null;
  scoreMax: string;
  scoreThreshold: number | null;
  idAttribute?: string;
}
const QualityMeasureScore: React.FC<QualityMeasureScoreProps> = ({
  score,
  scoreMax,
  scoreThreshold,
  idAttribute,
}: QualityMeasureScoreProps): React.ReactElement => {
  const scoreNum: number = score ? parseInt(score, 10) : 0;
  const scoreMaxNum: number = parseInt(scoreMax, 10);

  const scoreColor: { color: string; borderColor?: string } = getScoreColor(scoreThreshold);

  return (
    <Grid id={idAttribute} container={true} direction="row" wrap="nowrap" alignItems="center">
      <div id={idAttribute} style={{ width: 60 }}>
        <ScoreBar idAttribute={idAttribute} score={scoreNum} scoreMax={scoreMaxNum} height={8} {...scoreColor} />
      </div>
      <span id={idAttribute} style={{ color: scoreColor.borderColor || axialColors.gray, marginLeft: 6 }}>
        {score === null ? 'N/A' : `${scoreNum}/${scoreMaxNum} points`}
      </span>
    </Grid>
  );
};

const ExpandText: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  textTransform: 'uppercase',
  color: axialColors.gray50,
  fontSize: '0.75rem',
  marginLeft: 10,
});

const MeasureTitleContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  '@media screen': { flex: 1 },
});

const MeasureColumnsContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 20,
  '& > .contributing-members': { flexGrow: 3, flexBasis: 0 },
  '& > .maximize-info': { flexGrow: 2, flexBasis: 0 },
  [maxWidthMediaQueries.portraitPhone]: {
    flexDirection: 'column-reverse',
    '& > .contributing-members': { flexBasis: 'auto', marginBottom: 20 },
    '& > .maximize-info': { flexBasis: 'auto' },
  },
  [minWidthMediaQueries.landscapePhone]: {
    '& > .contributing-members': { marginLeft: 20 },
  },
});

const MeasureDetailHeader: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: 6,
}));

export { QualityMeasureSummary, QualityMeasureDetail };
