import React, { ReactElement, useState, ChangeEvent } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { StyledList, AxialIcon } from '@axial-healthcare/axial-react';
import { moduleRoutes } from 'src/modules/constants';
import { SurveyContinue } from '../../components/survey-continue';
import { SurveyQuestion } from '../../components/survey-question';
import { RecommendationCard } from '../../components/recommendation-card';

const OpioidReductionRiskBenefitAnalysisView: React.FC = (): ReactElement => {
  const [selection, setSelection] = useState('');
  const { palette } = useTheme();

  const updateSelection = (ev: ChangeEvent<HTMLInputElement>): void => {
    setSelection(ev.target.value);
  };

  return (
    <>
      <Typography variant="h2" color="primary">
        Risk Benefit Analysis
      </Typography>
      <Typography variant="h3">Factors supporting continued opioid use include:</Typography>
      <StyledList
        style={{ marginBottom: 30 }}
        bulletIcon={{ icon: AxialIcon.okCircledEmpty, style: { color: palette.primary.main } }}
        items={[
          'Minimal side effects',
          'Improved quality of life',
          'Involvement in daily living activities',
          'Potential for long-term treatment on stable dose',
          'Risk mitigation strategies in place',
          'Pain well controlled',
          'Improved mood',
          'Boost in social interactions',
        ]}
      />
      <Typography variant="h3">Factors increasing the risk of continued opioid use include:</Typography>
      <StyledList
        style={{ marginBottom: 30 }}
        bulletIcon={{ icon: AxialIcon.cancelCircled }}
        items={[
          'Concomitant benzodiazepine use',
          'Development of non opioid-related Substance Use Disorder',
          'Adverse effects (e.g., vomiting, nausea, pruritus, sedation)',
          'Comorbid unstable or unmanaged psychiatric disorders',
          'Significant physical medical comorbidities',
        ]}
      />
      <SurveyQuestion
        label="Do the benefits of taking opioids outweigh the risks?"
        onChange={updateSelection}
        options={[
          { value: 'monitor', label: 'Yes' },
          { value: 'report', label: 'No' },
        ]}
      />
      <Actions selection={selection} />
    </>
  );
};

interface ActionProps {
  selection: string;
}
const Actions: React.FC<ActionProps> = ({ selection }: ActionProps): ReactElement | null => {
  if (selection === 'monitor') {
    return (
      <>
        <RecommendationCard title="Monitor the patient">
          It is important to continue monitoring the patient.
        </RecommendationCard>
        <SurveyContinue to="/monitor" text="View tips for monitoring" />
      </>
    );
  } else if (selection === 'report') {
    return (
      <>
        <RecommendationCard title="Let's move forward">
          Consider a patient-centric approach to reducing opioids.
        </RecommendationCard>
        <SurveyContinue to={`${moduleRoutes.opioidReduction}/report`} isSurvey={false} text="Go to report" />
      </>
    );
  }

  return null;
};

export { OpioidReductionRiskBenefitAnalysisView };
