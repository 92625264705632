import React, { createRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { FormikProps, FormikErrors } from 'formik';
import { Button, RequestWrapper, RequestWrapperState, AxialIcon, axialColors } from '@axial-healthcare/axial-react';
import { DialogTitle, DialogContent, Dialog, DialogActions, Typography, IconButton, useTheme } from '@material-ui/core';
import { practiceApi } from 'src/constants/api';
import { DocumentUploadForm } from './form';
import { UploadFormValues, UploadCategory } from './interfaces';
import { wayspringColors } from '../..';

interface PatientDocumentUploadProps {
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
  episodeId: string;
  patientName: string;
}
const PatientDocumentUpload: React.FC<PatientDocumentUploadProps> = ({
  open,
  onClose,
  episodeId,
  patientName,
}: PatientDocumentUploadProps): React.ReactElement => {
  return (
    <Dialog open={open} onClose={onClose} scroll="paper" maxWidth="sm" fullWidth={true} disableBackdropClick={true}>
      <UploadDialog onClose={onClose} patientName={patientName} episodeId={episodeId} />
    </Dialog>
  );
};

type FormRef = React.RefObject<FormikProps<UploadFormValues>>;
interface UploadDialogProps {
  onClose: (event: React.MouseEvent) => void;
  episodeId: string;
  patientName: string;
}
const UploadDialog: React.FC<UploadDialogProps> = ({
  onClose,
  episodeId,
  patientName,
}: UploadDialogProps): React.ReactElement => {
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [formRefs, setFormRefs] = useState<{ [key: string]: FormRef }>({ '1': createRef() });

  const { typography } = useTheme();

  const submitAllForms = async (): Promise<void> => {
    const formKeys: string[] = Object.keys(formRefs);

    const validationResults: boolean[] = await Promise.all(
      formKeys.map((key: string): Promise<boolean> => {
        const form: FormikProps<UploadFormValues> | null = formRefs[key].current;
        if (!form) {
          return new Promise((resolve: (data: boolean) => void): void => resolve(false));
        }

        return form.validateForm().then((errors: FormikErrors<UploadFormValues>) => {
          return Object.keys(errors).every((fieldKey: string) => !errors[fieldKey as keyof UploadFormValues]);
        });
      })
    );

    if (validationResults.every((result: boolean) => result === true)) {
      formKeys.forEach((key: string): void => {
        formRefs[key].current?.submitForm();
      });
    } else {
      // force validation errors to render
      formKeys.forEach((key: string): void => {
        formRefs[key].current?.setTouched({ document: true, category: true, note: true }, false);
      });
    }
  };

  return (
    <>
      <DialogTitle
        style={{
          padding: '8px 12px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        disableTypography={true}
      >
        {patientName ? <Typography variant="h6">{`Patient Referral for ${patientName}`}</Typography> : null}
        <IconButton
          aria-label="Close Document Upload"
          size="small"
          style={{ marginTop: -12, marginBottom: -12 }}
          onClick={onClose}
        >
          <i className={AxialIcon.cancel} style={{ color: axialColors.gray25, fontSize: '1.2rem' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <RequestWrapper
          requestConfig={{
            request: (): Promise<UploadCategory[]> =>
              practiceApi
                .get<{ categories: UploadCategory[] }>('/referrals/upload-categories/')
                .then((response: AxiosResponse<{ categories: UploadCategory[] }>) => response.data.categories),
          }}
          ErrorContainerProps={{ style: { border: 'none' } }}
        >
          {({ data }: RequestWrapperState<UploadCategory[]>): React.ReactElement => (
            <>
              <Typography variant="h3" style={{ marginBottom: 16 }}>
                Upload documents
              </Typography>
              {Object.keys(formRefs).map((key: string, index: number) => (
                <DocumentUploadForm
                  key={key}
                  innerRef={formRefs[key]}
                  episodeId={episodeId}
                  categories={data}
                  setLoadingCount={setLoadingCount}
                  setCompletedCount={setCompletedCount}
                  onRemove={
                    index !== 0
                      ? (): void => {
                          const { [key]: refToRemove, ...restRefs } = formRefs;
                          setFormRefs(restRefs);
                        }
                      : undefined
                  }
                />
              ))}
              {loadingCount === 0 && completedCount === 0 ? (
                <Button
                  startIcon={AxialIcon.plusCircled}
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: wayspringColors.green, fontFamily: typography.body2.fontFamily }}
                  onClick={(): void => {
                    const keys: string[] = Object.keys(formRefs);
                    const lastKey: number = parseInt(keys[keys.length - 1], 10);
                    setFormRefs({ ...formRefs, [(lastKey + 1).toString()]: createRef() });
                  }}
                >
                  Add Document
                </Button>
              ) : null}
            </>
          )}
        </RequestWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ fontFamily: typography.body2.fontFamily }}
          onClick={onClose}
          variant={completedCount === Object.keys(formRefs).length ? 'contained' : 'text'}
          disabled={loadingCount > 0}
        >
          Close
        </Button>
        {completedCount === 0 && loadingCount === 0 ? (
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: wayspringColors.green, fontFamily: typography.body2.fontFamily }}
            onClick={submitAllForms}
          >
            Upload Documents
          </Button>
        ) : null}
      </DialogActions>
    </>
  );
};

export { PatientDocumentUpload };
