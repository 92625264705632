import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@material-ui/core';
import { Button } from '@axial-healthcare/axial-react';
import { UserAttestPostBody } from './user-provider/interface';

interface AttestationModalProps {
  open: boolean;
  attest: (body: UserAttestPostBody) => void;
}
const AttestationModal: React.FC<AttestationModalProps> = ({
  open,
  attest,
}: AttestationModalProps): React.ReactElement => {
  const { palette, typography } = useTheme();

  const handleAttest = (attested: boolean): (() => void) => {
    return (): void => {
      attest({ attested });
    };
  };

  return (
    <Dialog onClose={handleAttest(false)} maxWidth="sm" open={open} disableBackdropClick={true}>
      <DialogTitle style={{ color: palette.primary.main, borderBottom: `1px solid ${palette.divider}` }}>
        <span style={{ fontWeight: typography.fontWeightBold, fontSize: '1.4rem' }}>Attestation</span>
      </DialogTitle>
      <DialogContent>
        <p>
          As outlined in the Health Insurance Portability and Accountability Act of 1996 (HIPAA), protected health
          information is strictly confidential and should never be given, nor confirmed, to anyone who is not authorized
          under applicable law, statute, and/or regulation to receive this information.
        </p>
        <p>By proceeding, I hereby attest that I am legally authorized to view this information.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAttest(false)}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleAttest(true)}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AttestationModal };
