import React from 'react';
import { usePrintStyles, AxialIcon } from '@axial-healthcare/axial-react';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import { OrrDownloadButton } from '../../../../components/download-button';
import { ListCard } from '../../components/list-card';
import { OrrPreview } from './orr-preview';

interface OpioidRiskReportViewProps {
  memberId: string;
}
const OpioidRiskReportView: React.FC<OpioidRiskReportViewProps> = ({
  memberId,
}: OpioidRiskReportViewProps): React.ReactElement => {
  const { noPrint } = usePrintStyles();
  return (
    <TwoColumnLayout row={{ flexGrow: { columnOne: 2, columnTwo: 1 } }} column={{ reverse: true }}>
      <OrrPreview memberId={memberId} />
      <ListCard
        className={noPrint}
        title="Patient Tailored Reports"
        items={[
          {
            text: 'Opioid Risk Report',
            subtext: 'PDF Report',
            icon: AxialIcon.filePdf,
            action: memberId ? (
              <OrrDownloadButton
                memberId={memberId}
                idAttribute="detail-orr-download"
                iconOnly={true}
                iconButtonProps={{ size: 'small' }}
              />
            ) : undefined,
          },
        ]}
      />
    </TwoColumnLayout>
  );
};

export { OpioidRiskReportView };
