import React from 'react';
import { Grid, styled, Theme, makeStyles, Input, useTheme } from '@material-ui/core';
import {
  Select,
  SelectOption,
  SelectOptionSection,
  axialColors,
  usePrintStyles,
  mergeCssClasses,
  ElementProps,
  maxWidthMediaQueries,
  minWidthMediaQueries,
  formatDateString,
} from '@axial-healthcare/axial-react';
import { API_REPORTING_MONTH_FORMAT, DISPLAY_REPORTING_MONTH_FORMAT } from 'src/constants/api';
import { RimSummary } from '../interfaces';
import { RimScoreDisplay } from './rim-score-display';

interface ProviderStratHeaderProps {
  score?: number;
  reportMonth: { summaries: RimSummary[]; selectedMonth?: string; handleMonthChange: (month: string) => void };
  patientCount?: number;
}
const ProviderStratHeader: React.FC<ProviderStratHeaderProps> = ({
  score,
  reportMonth: { summaries, selectedMonth, handleMonthChange },
  patientCount,
}: ProviderStratHeaderProps): React.ReactElement => {
  const reportDateOptions: SelectOptionSection[] = [
    {
      header: {
        style: { lineHeight: '24px' },
        children: <ReportingMonthOptionHeader />,
      },
      key: 'single_section',
      options: summaries.map(({ assessed_through_yyyy_mm: date }: RimSummary) => ({
        value: date,
        label: formatDateString(date, API_REPORTING_MONTH_FORMAT, DISPLAY_REPORTING_MONTH_FORMAT),
        idAttribute: `rim-month-select-${date}`,
      })),
    },
  ];

  const styles: StylesType = useStyles();
  const { noPrint, onlyPrint } = usePrintStyles();

  const { moving_average: scoreAverage } =
    summaries.find((summary: RimSummary) => summary.assessed_through_yyyy_mm === selectedMonth) || {};

  const changeHandler = (event: React.ChangeEvent<{ value: unknown }>): void => {
    handleMonthChange(event.target.value as string);
  };

  return (
    <Grid container={true} direction="row" wrap="nowrap" alignItems="center" style={{ margin: '10px 0 20px' }}>
      <RimScoreDisplay
        className={mergeCssClasses(styles.portraitPhoneBadge, noPrint)}
        size={130}
        score={score}
        scoreAverage={scoreAverage}
      />
      <RimScoreDisplay className={styles.standardBadge} size={180} score={score} scoreAverage={scoreAverage} />
      <HeaderItemContainer>
        <HeaderItem>
          <div>Reporting Month</div>
          {selectedMonth ? (
            <Select
              variant="standard"
              id="rim-month-select"
              value={selectedMonth}
              optionSections={reportDateOptions}
              OptionComponent={buildReportingMonthOption(summaries)}
              onChange={changeHandler}
              input={<Input />}
              renderValue={(value: unknown): React.ReactNode =>
                formatDateString(value as string, API_REPORTING_MONTH_FORMAT, DISPLAY_REPORTING_MONTH_FORMAT)
              }
              className={styles.rimMonthSelect}
              formControlProps={{ style: { marginBottom: 10 }, className: noPrint }}
            />
          ) : null}
          <HeaderText
            className={onlyPrint}
            content={selectedMonth}
            transformContent={(content: string): string =>
              formatDateString(content, API_REPORTING_MONTH_FORMAT, DISPLAY_REPORTING_MONTH_FORMAT)
            }
          />
        </HeaderItem>
        <HeaderItem>
          <div>Total Patients</div>
          <HeaderText content={patientCount} />
        </HeaderItem>
      </HeaderItemContainer>
    </Grid>
  );
};

const ReportingMonthOptionHeader: React.FC = (): React.ReactElement => {
  const { typography } = useTheme();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        color: axialColors.gray25,
        fontWeight: typography.fontWeightBold,
        fontSize: '0.7rem',
      }}
    >
      <span>Month</span>
      <span>Score</span>
    </div>
  );
};

const buildReportingMonthOption =
  (summaries: RimSummary[]): React.ComponentType<SelectOption> =>
  ({ value, label, idAttribute }: SelectOption): React.ReactElement => {
    const { typography } = useTheme();
    const summaryForDate: RimSummary | undefined = summaries.find(
      (summary: RimSummary) => summary.assessed_through_yyyy_mm === value
    );
    const scoreForDate: number | undefined | null = summaryForDate?.overall_score;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(scoreForDate ? {} : { color: axialColors.gray50 }),
        }}
        id={idAttribute}
      >
        <span>{label}</span>
        <span style={{ fontSize: '0.8rem', fontWeight: typography.fontWeightBold, paddingLeft: 30, lineHeight: 0 }}>
          {scoreForDate || 'N/A'}
        </span>
      </div>
    );
  };

interface HeaderTextProps {
  content?: string | number;
  className?: string;
  transformContent?: (content: string) => string;
}
const HeaderText: React.FC<HeaderTextProps> = ({
  content,
  className,
  transformContent,
}: HeaderTextProps): React.ReactElement => {
  let contentText: string = content !== undefined ? content.toString() : '';
  const isContentUndefined: boolean = !content && content !== 0;

  if (transformContent) {
    contentText = transformContent(contentText);
  }

  return (
    <HeaderTextContainer className={className} isEmpty={isContentUndefined}>
      {isContentUndefined ? 'N/A' : contentText}
    </HeaderTextContainer>
  );
};

const HeaderItem: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  marginLeft: 70,
  [maxWidthMediaQueries.portraitPhone]: {
    marginLeft: '8vw',
    fontSize: '4vw',
  },
});

interface HeaderTextContainerProps extends ElementProps<HTMLDivElement> {
  isEmpty: boolean;
  theme?: Theme;
}
const HeaderTextContainer: React.ComponentType<HeaderTextContainerProps> = styled(
  ({ isEmpty, theme, ...restProps }: HeaderTextContainerProps) => <div {...restProps} />
)(({ isEmpty, theme }: HeaderTextContainerProps) => {
  const h2Styles: React.CSSProperties = theme?.typography?.h2 || {};
  return {
    ...h2Styles,
    marginTop: 5,
    color: isEmpty ? axialColors.gray50 : theme?.palette?.primary?.dark,
    whiteSpace: 'pre',
    [maxWidthMediaQueries.portraitPhone]: {
      fontSize: '5vw',
    },
  };
});

const HeaderItemContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

type StylesType = Record<'rimMonthSelect' | 'portraitPhoneBadge' | 'standardBadge', string>;
const useStyles: () => StylesType = makeStyles((theme) => {
  return {
    rimMonthSelect: {
      fontSize: '1.5rem',
      fontFamily: theme.typography.body1.fontFamily,
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
      [maxWidthMediaQueries.portraitPhone]: {
        fontSize: '5vw',
      },
    },
    portraitPhoneBadge: {
      [minWidthMediaQueries.landscapePhone]: { display: 'none !important' },
    },
    standardBadge: {
      [maxWidthMediaQueries.portraitPhone]: { display: 'none !important' },
    },
  };
});

export { ProviderStratHeader };
