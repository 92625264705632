import React, { useState } from 'react';
import { NavLink, useLocation, matchPath, NavLinkProps } from 'react-router-dom';
import { styled, IconButton, Menu, MenuItem, MenuProps, makeStyles } from '@material-ui/core';
import { Location } from 'history';
import { axialColors, ElementProps, AxialIcon, mergeCssClasses } from '@axial-healthcare/axial-react';

interface SubNavProps {
  items: NavLinkProps[];
  layout?: 'vertical' | 'horizontal';
  ListProps?: Omit<ElementProps<HTMLUListElement>, 'children'>;
}
const SubNav: React.FC<SubNavProps> = ({
  items,
  layout = 'horizontal',
  ListProps: { className, ...restListProps } = {},
}: SubNavProps): React.ReactElement => {
  const location: Location = useLocation();
  const { subNavContainer } = useStyles();
  return (
    <ul
      className={mergeCssClasses(subNavContainer, layout === 'vertical' ? 'vertical' : '', className || '')}
      {...restListProps}
    >
      {items.map((link: NavLinkProps) => {
        return (
          <li key={getNavLinkTo(link, location)}>
            <NavLink {...link} />
          </li>
        );
      })}
    </ul>
  );
};

interface SubNavDropDownProps extends ElementProps<HTMLDivElement> {
  items: NavLinkProps[];
  MenuProps?: Omit<MenuProps, 'open' | 'children'>;
}
const SubNavDropDown: React.FC<SubNavDropDownProps> = ({
  items,
  MenuProps: menuProps,
  className,
  ...restProps
}: SubNavDropDownProps): React.ReactElement => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  const location: Location = useLocation();

  const { subNavContainer } = useStyles();

  const activeLink: NavLinkProps | undefined = items.find((navLink: NavLinkProps) =>
    matchPath(location.pathname, {
      path: getNavLinkTo(navLink, location),
      exact: true,
    })
  );

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = (): void => {
    setMenuAnchor(null);
  };

  return (
    <div className={mergeCssClasses(subNavContainer, className || '')} {...restProps}>
      {activeLink ? (
        <div className="dropdown active" style={{ cursor: 'default' }}>
          {activeLink.children}
        </div>
      ) : null}
      <IconButton onClick={openMenu}>
        <i style={{ fontSize: '1.2rem' }} className={AxialIcon.ellipses} />
      </IconButton>
      <Menu
        open={!!menuAnchor}
        onClose={closeMenu}
        anchorEl={menuAnchor}
        classes={{ list: mergeCssClasses(subNavContainer, 'vertical') }}
        keepMounted={true}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        {...menuProps}
      >
        {items.map((navLink: NavLinkProps) => {
          return (
            <MenuItem key={getNavLinkTo(navLink, location)}>
              <NavLink onClick={closeMenu} {...navLink} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

interface SubNavIconItemProps extends ElementProps<HTMLDivElement> {
  icon?: string;
  text?: string;
}
const SubNavIconItem: React.FC<SubNavIconItemProps> = ({
  icon,
  text,
  ...restProps
}: SubNavIconItemProps): React.ReactElement => {
  return (
    <IconItemContainer {...restProps}>
      {icon ? <i className={icon} /> : null}
      {text}
    </IconItemContainer>
  );
};

const getNavLinkTo = (navLink: NavLinkProps, location: Location): string | undefined => {
  return typeof navLink.to === 'string'
    ? navLink.to
    : typeof navLink.to !== 'function'
    ? navLink.to.pathname
    : navLink.to(location).toString();
};

const IconItemContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'inline-block',
  '& > i': {
    marginRight: 6,
  },
  '& > i:before': {
    fontWeight: 600,
  },
});

const useStyles: () => Record<'subNavContainer', string> = makeStyles((theme) => {
  return {
    subNavContainer: {
      marginTop: 0,
      listStyle: 'none',
      '& li, & .dropdown': {
        display: 'inline-block',
        marginLeft: 20,
      },
      '&:not(.vertical)': { padding: 0, margin: '0px 0px 10px 0px' },
      '&.vertical li': { display: 'block', margin: '0 0 10px 0' },
      '& li a': { display: 'block', paddingBottom: 4 },
      '& a, & .dropdown': {
        fontFamily: theme.typography.body1.fontFamily,
        borderBottom: '2px transparent solid',
        padding: 0,
        borderRadius: 0,
        textDecoration: 'none',
        color: axialColors.gray,
      },
      '& a.active, & .dropdown.active': {
        color: theme.palette.primary.main,
        borderBottomColor: theme.palette.primary.main,
      },
      '& li:first-child, & .dropdown:first-child': {
        marginLeft: 0,
      },
    },
  };
});

export { SubNavDropDown, SubNavIconItem, SubNav };
