import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
  TableCellConfig,
  TableRowConfig,
  PaginationProps,
  Table,
  Results,
  axialColors,
  Card,
  formatDateString,
  dateToString,
  RequestWrapper,
  RequestWrapperState,
} from '@axial-healthcare/axial-react';
import { useTheme } from '@material-ui/core';
import { practiceApi } from 'src/constants/api';
import { DISPLAY_DATE_FORMAT, API_DATE_FORMAT } from 'src/constants/api';
import { getDisplayName } from 'src/constants/utilities';
import { Member, MemberNotification } from '../../interfaces';
import { OrrDownloadButton } from '../../components/download-button';
import { NewIndicator } from '../../components/new-indicator';
import { MemberNotificationTags } from '../../components/notification-tags';
import { PatientFilters } from './filters-provider';

interface PatientTableWithDataProps {
  filters: PatientFilters;
}
const PatientTableWithData: React.FC<PatientTableWithDataProps> = ({
  filters: { lastName, firstName, dateOfBirth, notificationType, newNotifications },
}: PatientTableWithDataProps): React.ReactElement => {
  const { url } = useRouteMatch();
  const { typography } = useTheme();

  return (
    <RequestWrapper<Results<Member>>
      LoadingProps={{ fullPage: true }}
      hidePagination={true}
      requestConfig={{
        request: ({ cancelToken, paginationQueryParams }): Promise<AxiosResponse<Results<Member>>> => {
          return practiceApi.get<Results<Member>>('/members/', {
            cancelToken,
            params: {
              ...(paginationQueryParams || {}),
              ...(lastName ? { last_name: lastName } : {}),
              ...(firstName ? { first_name: firstName } : {}),
              ...(dateOfBirth ? { dob: dateToString(dateOfBirth, API_DATE_FORMAT) } : {}),
              ...(notificationType.length > 0 ? { notification_type: notificationType.join(',') } : {}),
              ...(newNotifications ? { unviewed: true } : {}),
            },
          });
        },
        paginationOptions: { resultsPerPage: 14 },
        dependencies: [lastName, firstName, dateOfBirth, notificationType, newNotifications],
      }}
    >
      {({ data: members, pagination }: RequestWrapperState<Results<Member>>) => {
        const columns: TableCellConfig[] = [
          { key: 'new', value: 'New', style: { width: 20 } },
          { key: 'name', value: 'Name', style: { width: 200 } },
          { key: 'dob', value: 'DOB' },
          // { key: 'medd', value: 'MEDD', style: { textAlign: 'center' } },
          { key: 'notifications', value: 'Notifications' },
          { key: 'orr', value: 'Opioid Risk Report', style: { textAlign: 'right' } },
        ];
        const rows: TableRowConfig[] = (members.results || []).map((memberDetail: Member) => {
          const {
            member: { first_name: firstName, last_name: lastName, birth_date, axial_member_id, member_notifications },
          } = memberDetail;
          return {
            key: axial_member_id.toString(),
            link: {
              internalLink: {
                to: {
                  pathname: `${url}/${axial_member_id}`,
                },
              },
            },
            cells: [
              {
                key: 'new',
                value: member_notifications.some((notification: MemberNotification) => notification.unviewed) ? (
                  <NewIndicator />
                ) : null,
              },
              {
                key: 'name',
                style: { fontWeight: typography.fontWeightBold },
                value: getDisplayName({ firstName, lastName }),
              },
              {
                key: 'dob',
                value: formatDateString(birth_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT),
                style: { color: axialColors.gray50 },
              },
              // { key: 'medd', value: medd, style: { textAlign: 'center' } },
              {
                key: 'notifications',
                value: <MemberNotificationTags notifications={member_notifications} />,
              },
              {
                key: 'orr',
                style: { textAlign: 'right' },
                value: (
                  <OrrDownloadButton
                    idAttribute="table-orr-download"
                    memberId={axial_member_id.toString()}
                    buttonProps={{
                      style: { fontSize: '0.7rem', padding: '2px 6px 2px 12px', minWidth: 'auto' },
                    }}
                  />
                ),
              },
            ],
          };
        });

        const tablePagination: PaginationProps | undefined = pagination
          ? { ...pagination, count: members.count || 0 }
          : undefined;

        return (
          <Card>
            <Table
              idAttribute="patient-intelligence-table"
              columns={columns}
              rows={rows}
              pagination={tablePagination}
              NoResultsProps={{ resultsName: 'patients' }}
              ScrollMessage={{ style: { textAlign: 'center' } }}
            />
          </Card>
        );
      }}
    </RequestWrapper>
  );
};

export { PatientTableWithData };
