import React from 'react';
import { styled } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { ElementProps, maxWidthMediaQueries, minWidthMediaQueries } from '@axial-healthcare/axial-react';

interface ColumnLayoutProps {
  flexGrow?: { rowOne: number; rowTwo: number };
  reverse?: boolean;
  styles?: {
    rowOne?: CreateCSSProperties<TwoColumnLayoutProps>;
    rowTwo?: CreateCSSProperties<TwoColumnLayoutProps>;
    both?: CreateCSSProperties<TwoColumnLayoutProps>;
    parent?: CreateCSSProperties<TwoColumnLayoutProps>;
  };
}
interface RowLayoutProps {
  flexGrow?: { columnOne: number; columnTwo: number };
  reverse?: boolean;
  styles?: {
    columnOne?: CreateCSSProperties<TwoColumnLayoutProps>;
    columnTwo?: CreateCSSProperties<TwoColumnLayoutProps>;
    both?: CreateCSSProperties<TwoColumnLayoutProps>;
    parent?: CreateCSSProperties<TwoColumnLayoutProps>;
  };
}
interface TwoColumnLayoutProps extends ElementProps<HTMLDivElement> {
  column?: ColumnLayoutProps;
  row?: RowLayoutProps;
  additionalStyles?: CreateCSSProperties<TwoColumnLayoutProps>;
}
const TwoColumnLayout: React.ComponentType<TwoColumnLayoutProps> = styled(
  ({ column, row, additionalStyles, ...restProps }: TwoColumnLayoutProps) => <div {...restProps} />
)(({ column, row, additionalStyles }: TwoColumnLayoutProps) => {
  const firstSelector = '& > :first-child';
  const secondSelector = '& > :nth-child(2)';
  const bothSelector = '& > :first-child, & > :nth-child(2)';

  return {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [maxWidthMediaQueries.desktop]: {
      flexDirection: column?.reverse ? 'column-reverse' : 'column',
      [bothSelector]: { width: '100%', flexBasis: 'auto', ...column?.styles?.both },
      [firstSelector]: { flexGrow: column?.flexGrow?.rowOne, ...column?.styles?.rowOne },
      [secondSelector]: {
        flexGrow: column?.flexGrow?.rowTwo,
        ...(column?.reverse ? { marginBottom: 30 } : { marginTop: 30 }),
        ...column?.styles?.rowTwo,
      },
      ...column?.styles?.parent,
    },
    [minWidthMediaQueries.largeDesktop]: {
      flexDirection: row?.reverse ? 'row-reverse' : 'row',
      [bothSelector]: { flexBasis: row?.flexGrow ? 0 : undefined, ...row?.styles?.both },
      [firstSelector]: {
        flexGrow: row?.flexGrow?.columnOne,
        ...(row?.reverse ? { marginLeft: 30 } : { marginRight: 30 }),
        ...row?.styles?.columnOne,
      },
      [secondSelector]: { flexGrow: row?.flexGrow?.columnTwo, ...row?.styles?.columnTwo },
      ...row?.styles?.parent,
    },
    ...additionalStyles,
  };
});

export { TwoColumnLayout };
