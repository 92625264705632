import React from 'react';
import { AxiosResponse } from 'axios';
import { RequestLoadingProps, RequestWrapper, RequestWrapperState, Results } from '@axial-healthcare/axial-react';
import { practiceApi } from 'src/constants/api';
import { PracticeOptions, MemberNotificationCount, MemberNotificationType } from '../interfaces';

export interface NotificationWithCount {
  key: string;
  label: string;
  count: number;
}

const NotificationTypesContext: React.Context<NotificationWithCount[]> = React.createContext(
  [] as NotificationWithCount[]
);

const useNotificationTypesContext = (): NotificationWithCount[] => {
  const context: NotificationWithCount[] = React.useContext(NotificationTypesContext);
  if (context === undefined) {
    throw new Error('useNotificationTypesContext must be used inside a NotificationTypesProvider');
  }
  return context;
};

interface Props {
  LoadingProps?: RequestLoadingProps;
  children: React.ReactNode;
}
const NotificationTypesProvider: React.FC<Props> = ({
  LoadingProps: loadingProps,
  children,
}: Props): React.ReactElement => {
  return (
    <RequestWrapper<NotificationWithCount[]>
      LoadingProps={loadingProps}
      requestConfig={{
        request: ({ cancelToken }): Promise<NotificationWithCount[]> => {
          return Promise.all([
            practiceApi.get<PracticeOptions>('/options').then((response: AxiosResponse<PracticeOptions>) => {
              return response.data.notification_types;
            }),
            practiceApi
              .get<Results<MemberNotificationCount>>('/members/notification-counts/', { cancelToken })
              .then((response: AxiosResponse<Results<MemberNotificationCount>>) => {
                return response.data.results;
              }),
          ]).then(([types, counts]: [MemberNotificationType[], MemberNotificationCount[]]): NotificationWithCount[] => {
            return types.map((notificationType: MemberNotificationType) => {
              return {
                key: notificationType.notification_type_abbreviation,
                label: notificationType.notification_type_description,
                count:
                  counts.find((notificationCount: MemberNotificationCount): boolean => {
                    return notificationCount.notification_type_code === notificationType.notification_type_code;
                  })?.notification_count || 0,
              };
            });
          });
        },
      }}
    >
      {({ data }: RequestWrapperState<NotificationWithCount[]>) => (
        <NotificationTypesContext.Provider value={data}>{children}</NotificationTypesContext.Provider>
      )}
    </RequestWrapper>
  );
};

export { NotificationTypesProvider, useNotificationTypesContext };
