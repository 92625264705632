import React, { ReactNode } from 'react';
import { styled, Typography, CardContent, useTheme } from '@material-ui/core';
import { StyledList, axialColors, AxialIcon, ElementProps, Card } from '@axial-healthcare/axial-react';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import { AxialSummary } from '../components/axial-summary';

const LandingView: React.FC = (): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <>
      <AxialSummary />

      <CardContainer>
        <SectionTitle icon={AxialIcon.okCircled}>
          As an <span style={{ color: palette.primary.light }}>unauthenticated user</span>, you have access to:
        </SectionTitle>
        <CardContent>
          <Feature
            title="Free Interactive Decision Support Tools"
            screenshot={`${process.env.PUBLIC_URL}/screenshots/ort-screenshot.jpg`}
          >
            <p>Enabling safer, patient-centric care decisions, providers use the Opioid Reduction Tool to:</p>
            <StyledList
              items={[
                'Determine if opioid reduction is beneficial',
                'Assess patient motivation + readiness',
                'Determine a safe reduction pace',
                'Create and print patient-facing reduction plans',
                'Download relevant assessments',
              ]}
            />
            <p>
              Available under Support Tools on the left, the tool can be used on your mobile device, tablet, or laptop.
            </p>
            <p>More tools coming soon.</p>
          </Feature>
        </CardContent>
      </CardContainer>
      <CardContainer>
        <SectionTitle icon={AxialIcon.key}>
          By becoming an <span style={{ color: palette.primary.light }}>authenticated</span> user, you gain access to:
        </SectionTitle>
        <CardContent>
          <Feature title="RIM Report" screenshot={`${process.env.PUBLIC_URL}/screenshots/rim-screenshot.jpg`}>
            Improving health outcomes, a Risk Identification and Mitigation (RIM) Report shows how a providers’ opioid
            prescribing may be impacting patient safety.
          </Feature>
          <Feature
            title="Patient Intelligence"
            screenshot={`${process.env.PUBLIC_URL}/screenshots/patient-intelligence-screenshot.jpg`}
          >
            Alerting providers to patients flagged with opioid-related risks such as:
            <StyledList
              items={[
                'ED visits for opioid overdose',
                'Opioids from multiple prescribers and/or pharmacies ',
                'Concurrent opioid and benzodiazepine prescriptions',
              ]}
            />
            Reporting offering a snapshot view of:
            <StyledList
              items={[
                'Prioritized patient notifications',
                'Recent patient diagnoses + prescription information ',
                'Tailored clinical considerations',
              ]}
            />
          </Feature>
        </CardContent>
      </CardContainer>
      <CardContainer>
        <SectionTitle icon={AxialIcon.axialPractice}>
          How can <span style={{ color: palette.primary.light }}>you</span> request access?
        </SectionTitle>
        <CardContent>
          To request a login account or receive additional support, please contact axialHealthcare at{' '}
          <strong style={{ color: palette.primary.main }}>615-475-5059</strong> from Monday-Friday during 9am-5pm CST or
          email us at{' '}
          <a href="mailto:accesssupport@axialhealthcare.com" style={{ color: palette.primary.main }}>
            <strong>accesssupport@axialhealthcare.com</strong>
          </a>
          .
        </CardContent>
      </CardContainer>
    </>
  );
};

interface FeatureProps {
  title: string;
  children: ReactNode;
  screenshot?: string;
}
const Feature: React.FC<FeatureProps> = (props: FeatureProps): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <FeatureWrapper>
      <TwoColumnLayout row={{ flexGrow: { columnOne: 1, columnTwo: 1 } }}>
        {props.screenshot ? (
          // minHeight is a weird fix for an IE11 flex bug around image ratio
          // https://github.com/philipwalton/flexbugs/issues/75#issuecomment-134702421
          <div style={{ minHeight: 1 }}>
            <img
              style={{ width: '100%', border: `1px ${axialColors.gray10} solid` }}
              src={props.screenshot}
              alt={`screenshot-${props.title}`}
            />
          </div>
        ) : undefined}
        <div>
          <Typography variant="h2" style={{ paddingBottom: 5, margin: 0, color: palette.primary.main }}>
            {props.title}
          </Typography>
          {props.children}
        </div>
      </TwoColumnLayout>
    </FeatureWrapper>
  );
};

const FeatureWrapper: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => {
  return {
    '&:not(:last-child)': {
      paddingBottom: 30,
      marginBottom: 30,
      borderBottom: `1px ${theme.palette.divider} solid`,
    },
  };
});

interface SectionTitleProps {
  children: ReactNode;
  icon: string;
}
const SectionTitle: React.FC<SectionTitleProps> = (props: SectionTitleProps): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <Typography
      variant="h3"
      style={{
        background: palette.primary.main,
        color: 'white',
        padding: '20px',
        borderBottom: `1px ${palette.divider} solid`,
      }}
    >
      <i style={{ color: palette.primary.light, marginRight: 5, fontSize: '1.5rem' }} className={props.icon} />{' '}
      {props.children}
    </Typography>
  );
};

const CardContainer = styled(Card)({
  marginBottom: 10,
  '&:last-child': {
    marginBottom: 0,
  },
});

export { LandingView };
