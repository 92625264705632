import React, { useState } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import {
  CircularProgressProps,
  withStyles,
  CircularProgress,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from '@material-ui/core';
import { Button, axialColors, AxialIcon } from '@axial-healthcare/axial-react';
import { practiceApi } from 'src/constants/api';

interface OrrDownloadButtonProps {
  memberId: string;
  idAttribute?: string;
  iconOnly?: boolean;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
}
const OrrDownloadButton: React.FC<OrrDownloadButtonProps> = ({
  memberId,
  idAttribute,
  iconOnly,
  buttonProps,
  iconButtonProps,
}: OrrDownloadButtonProps): React.ReactElement => {
  const { style: buttonStyle, ...restButton } = buttonProps || { style: {} };
  const { style: iconButtonStyle, ...restIconButton } = iconButtonProps || { style: {} };

  const [loading, setLoading] = useState<boolean>(false);

  const downloadOrr = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    setLoading(true);
    practiceApi
      .get<{ url: string }>(`/orrs/${memberId}/pdf/`)
      .then(({ data }: AxiosResponse<{ url: string }>) => {
        if (data.url) {
          window.open(data.url);
        }
      })
      .catch((error: AxiosError) => {
        console.error('Fetch ORR PDF download failed:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const id: string = idAttribute ? `${idAttribute}-${memberId}` : '';
  return iconOnly ? (
    <IconButton
      {...restIconButton}
      id={id}
      style={{ color: axialColors.gray50, ...iconButtonStyle }}
      disabled={loading}
      onClick={downloadOrr}
    >
      {loading ? <ButtonLoadingIndicator id={id} size={20} /> : <i id={id} className={AxialIcon.download} />}
    </IconButton>
  ) : (
    <Button
      variant="outlined"
      startIcon={
        loading ? <ButtonLoadingIndicator id={id} size={15} style={{ marginLeft: '-4px' }} /> : AxialIcon.download
      }
      {...restButton}
      style={{ color: axialColors.gray50, fontSize: '0.8rem', ...buttonStyle }}
      disabled={loading}
      id={id}
      onClick={downloadOrr}
    >
      PDF
    </Button>
  );
};

const ButtonLoadingIndicator: React.ComponentType<CircularProgressProps> = withStyles({
  root: {
    color: axialColors.gray50,
  },
})(CircularProgress);

export { OrrDownloadButton };
