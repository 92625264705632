import React from 'react';
import { Grid, styled, GridProps, useTheme } from '@material-ui/core';
import { Collapse, axialColors, ElementProps, usePrintStyles, formatDateString } from '@axial-healthcare/axial-react';
import { DISPLAY_DATE_FORMAT, API_DATE_FORMAT } from 'src/constants/api';
import { RimReportCategoryMember } from '../../interfaces';
import { CategoryDetailHeader } from './index';
import { NavLink } from 'react-router-dom';
import { moduleRoutes } from 'src/modules/constants';

interface MemberListProps {
  members: RimReportCategoryMember[];
  isReportLatest?: boolean;
  className?: string;
}
const MemberList: React.FC<MemberListProps> = ({
  members,
  isReportLatest,
  className,
}: MemberListProps): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <MemberListContainer className={className}>
      {members.map((member: RimReportCategoryMember, index: number) => {
        const { first_name, last_name, birth_date, axial_member_id, npi_relationship } = member;
        const itemStyles: React.CSSProperties = {
          fontSize: '0.875rem',
          padding: '4px 0',
        };
        const canLinkToMember: boolean = !!npi_relationship && !!isReportLatest;
        const memberName: string = (first_name || '') + (last_name ? ` ${last_name}` : '');

        return (
          <Grid
            container={true}
            direction="row"
            justify="space-between"
            wrap="nowrap"
            key={member.axial_member_id}
            style={index !== 0 ? { ...itemStyles, borderTop: `1px solid ${palette.divider}` } : itemStyles}
          >
            <Grid item={true}>
              {canLinkToMember ? (
                <NavLink to={`${moduleRoutes.patientIntelligence}/${axial_member_id}`} className="member-link">
                  {memberName}
                </NavLink>
              ) : (
                memberName
              )}
            </Grid>
            <MemberListItemDate item={true}>
              {formatDateString(birth_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT)}
            </MemberListItemDate>
          </Grid>
        );
      })}
    </MemberListContainer>
  );
};
const MemberListContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  paddingRight: 8,
  '@media screen': {
    maxHeight: 450,
    overflowY: 'auto',
    '& .member-link': {
      color: theme.palette.primary.main,
    },
  },
  '@media print': {
    maxWidth: 600,
    '& .member-link': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}));
const MemberListItemDate: React.ComponentType<GridProps> = styled(Grid)(({ theme }) => ({
  color: axialColors.gray25,
  '@media print': {
    color: theme.palette.text.primary,
  },
}));

interface CategoryMemberProps extends MemberListProps, MemberListCountProps {
  style?: React.CSSProperties;
  className?: string;
  categoryName: string;
  isReportLatest: boolean;
}
const CategoryMembers: React.FC<CategoryMemberProps> = ({
  members,
  memberCount,
  className,
  style,
  categoryName,
  isReportLatest,
}: CategoryMemberProps): React.ReactElement => {
  const { noPrint } = usePrintStyles();

  const idAttribute = `rim-members-trigger-${categoryName}`;

  const buildMemberListHeader = (collapsed: boolean): React.ReactElement => (
    <span style={collapsed ? {} : { fontSize: '0.72rem' }}>{collapsed ? 'Expand' : 'Collapse'} Patient Names</span>
  );
  return (
    <div className={className} style={style}>
      <CategoryDetailHeader>Patients</CategoryDetailHeader>
      <MemberListCount memberCount={memberCount} />
      {memberCount > 0 ? (
        <>
          <Collapse
            title={buildMemberListHeader}
            idAttribute={idAttribute}
            ButtonBaseProps={{
              className: noPrint,
              style: {
                textTransform: 'uppercase',
                fontSize: '0.75rem',
                color: axialColors.gray50,
                whiteSpace: 'pre',
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
            CollapseContentProps={{ style: { padding: 0 } }}
          >
            <MemberList members={members} isReportLatest={isReportLatest} />
          </Collapse>
        </>
      ) : null}
    </div>
  );
};

interface MemberListCountProps {
  memberCount: number;
}
const MemberListCount: React.FC<MemberListCountProps> = ({ memberCount }: MemberListCountProps): React.ReactElement => {
  const { palette, typography } = useTheme();
  return (
    <p style={{ fontWeight: 'lighter', margin: 0 }}>
      You have prescribed opioids to{' '}
      <span style={{ color: palette.primary.dark, fontWeight: typography.fontWeightBold, whiteSpace: 'pre' }}>
        {memberCount + (memberCount === 1 ? ' patient' : ' patients')}
      </span>{' '}
      in the reported month
    </p>
  );
};

export { MemberList, CategoryMembers };
