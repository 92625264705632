import React, { ReactElement, ReactNode } from 'react';
import { styled, useTheme } from '@material-ui/core';
import { AxialIcon, ElementProps, Card } from '@axial-healthcare/axial-react';

export interface RecommendationCardProps {
  title: string;
  children: ReactNode;
}
const RecommendationCard: React.FC<RecommendationCardProps> = ({
  title,
  children,
}: RecommendationCardProps): ReactElement => {
  const { palette } = useTheme();
  return (
    <Card style={{ marginBottom: 20, background: palette.primary.main, color: 'white' }}>
      <div style={{ padding: '15px 20px', display: 'flex' }}>
        <CardIcon>
          <i className={AxialIcon.lightBulb} />
        </CardIcon>
        <div>
          <CardRecommendationTitle>Recommendation</CardRecommendationTitle>
          <CardTitle>{title}</CardTitle>
        </div>
      </div>
      {children ? <CardContent>{children}</CardContent> : undefined}
    </Card>
  );
};

const CardIcon: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  fontSize: '1.5rem',
  background: 'white',
  border: `4px ${theme.palette.primary.dark} solid`,
  width: 45,
  height: 45,
  borderRadius: 40,
  textAlign: 'center',
  marginRight: 10,
  color: theme.palette.primary.main,
}));

const CardRecommendationTitle: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  color: theme.palette.primary.light,
}));

const CardTitle: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: '1.25rem',
}));

const CardContent: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  fontSize: '0.75rem',
  padding: '15px 20px',
  background: theme.palette.primary.dark,
}));

export { RecommendationCard };
