import React from 'react';
import { axialColors, ElementProps } from '@axial-healthcare/axial-react';

const NewIndicator: React.FC<ElementProps<HTMLDivElement>> = ({
  style,
  ...restProps
}: ElementProps<HTMLDivElement>): React.ReactElement => {
  return (
    <div
      style={{
        height: 10,
        width: 10,
        borderRadius: 5,
        backgroundColor: axialColors.yellow,
        margin: '0 auto',
        ...style,
      }}
      {...restProps}
    />
  );
};

export { NewIndicator };
