import React from 'react';
import { RequestWrapperTable, TableRowConfig, axialColors, formatDateString } from '@axial-healthcare/axial-react';
import { practiceApi } from 'src/constants/api';
import { DISPLAY_DATE_FORMAT, API_DATE_FORMAT } from 'src/constants/api';
import { MemberNotificationTags } from '../../../components/notification-tags';
import { MemberNotification, MemberNotificationSupplemental } from '../../../interfaces';
import { PatientDetailTableProps } from '../views/dashboard';
import { Theme, useTheme } from '@material-ui/core';

const NotificationTable: React.FC<PatientDetailTableProps<MemberNotification>> = ({
  memberId,
  limit,
  resultsPerPage,
  TableProps: tableProps,
}: PatientDetailTableProps<MemberNotification>): React.ReactElement => {
  const theme = useTheme();
  return (
    <RequestWrapperTable<MemberNotification>
      TableProps={{
        ...tableProps,
        columns: [
          { key: 'tag', value: 'Notification' },
          { key: 'notification-name', value: 'Full Name' },
          { key: 'start-date', value: 'Effective Date' },
          { key: 'status', value: 'Status' },
          { key: 'supplemental', value: 'More Information' },
          ...(tableProps?.columns || []),
        ],
        mapDataToRow: mapNotificationToRow(theme),
      }}
      axiosRequestConfig={{
        request: {
          api: practiceApi,
          url: `/members/${memberId}/notifications/`,
          ...(limit ? { params: { limit } } : {}),
        },
        paginationOptions: resultsPerPage ? { resultsPerPage } : undefined,
      }}
    />
  );
};

const mapNotificationToRow =
  (theme: Theme) =>
  (notification: MemberNotification): TableRowConfig => {
    const color: string | undefined = notification.active ? undefined : axialColors.gray50;
    return {
      key: notification.member_notification_hash_key,
      cells: [
        {
          key: 'tag',
          value: <MemberNotificationTags color={color} notifications={[notification]} />,
        },
        {
          key: 'notification-name',
          style: {
            fontWeight: theme.typography.fontWeightBold,
            color,
          },
          value: notification.notification_type.notification_type_description,
        },
        {
          key: 'start-date',
          style: { color: axialColors.gray50 },
          value: formatDateString(notification.start_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT),
        },
        {
          key: 'status',
          style: { color },
          value: notification.active
            ? 'Active'
            : `Expired ${formatDateString(notification.end_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT)}`,
        },
        {
          key: 'supplemental',
          style: { color },
          value: notification.supplemental ? (
            <NotificationSupplementalInformation supplemental={notification.supplemental} />
          ) : null,
        },
      ],
    };
  };

const NotificationSupplementalInformation: React.FC<{ supplemental: MemberNotificationSupplemental }> = ({
  supplemental,
}: {
  supplemental: MemberNotificationSupplemental;
}): React.ReactElement => {
  const { provider_name, start_date, icd_code, icd_description } = supplemental;
  return (
    <>
      {provider_name ? <div>Location: {provider_name}</div> : null}
      {start_date || icd_code ? (
        <div>
          Date of Visit: {start_date ? formatDateString(start_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT) : 'Unknown'},
          ICD: {icd_code || 'Unknown'}
        </div>
      ) : null}
      {icd_description ? <div> Description: {icd_description}</div> : null}
    </>
  );
};

export { NotificationTable };
