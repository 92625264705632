import React from 'react';
import { AxiosResponse } from 'axios';
import { styled } from '@material-ui/core';
import {
  ScrollMessage,
  ElementProps,
  maxWidthMediaQueries,
  Card,
  RequestWrapper,
  RequestWrapperState,
} from '@axial-healthcare/axial-react';
import { practiceApi } from 'src/constants/api';

interface OrrPreviewProps {
  memberId: string;
}
const OrrPreview: React.FC<OrrPreviewProps> = ({ memberId }: OrrPreviewProps): React.ReactElement => {
  return (
    <RequestWrapper<string>
      LoadingProps={{ fullPage: true }}
      requestConfig={{
        request: ({ cancelToken }): Promise<string> => {
          return practiceApi
            .get<string>(`/orrs/${memberId}/html/`, { cancelToken })
            .then((response: AxiosResponse<string>) =>
              response.data.replace('<style ', '<style nonce="NTQwZGI3ODEtYmY1Yy00OGU4LTkwYzctYWEyMWMyMjMwMmUw" ')
            );
        },
        dependencies: [memberId],
        condition: (): boolean => !!memberId,
      }}
    >
      {({ data: html }: RequestWrapperState<string>) => {
        return (
          <PreviewContainer>
            <ScrollMessage />
            <div style={{ overflowX: 'auto' }}>
              <Card className="orr-card">
                <div dangerouslySetInnerHTML={{ __html: html || '' }} />
              </Card>
            </div>
          </PreviewContainer>
        );
      }}
    </RequestWrapper>
  );
};

const PreviewContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  width: '100%',
  [maxWidthMediaQueries.portraitPhone]: {
    '& .orr-card': { minWidth: 900 },
  },
});

export { OrrPreview };
