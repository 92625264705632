enum EnvironmentTypes {
  local = 'local',
  development = 'development',
  staging = 'staging',
  production = 'production',
}
enum PracticeFeatures {
  // some clients have data for a 3 month avg; the ui pieces for this can be turned on/off in the api
  // feature flag key: provider-strat.moving-average
  providerStratMovingAverage,
}
export type PracticeFeatureList = {
  [key in PracticeFeatures]?: boolean;
};
interface Environment {
  api: string;
  featureList: PracticeFeatureList;
}

const config: { [key in EnvironmentTypes]: Environment } = {
  local: {
    api: '',
    featureList: {},
  },
  development: {
    api: 'https://practice-api.development.axhc.us/',
    featureList: {},
  },
  staging: {
    api: 'https://practice-api.staging.axialhealthcare.com/',
    featureList: {},
  },
  production: {
    api: 'https://practice-api.axialhealthcare.com/',
    featureList: {},
  },
};

const environment: Environment = config[process.env.REACT_APP_ENVIRONMENT as EnvironmentTypes] || config.local;

export { environment, PracticeFeatures };
