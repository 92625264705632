import React, { useState, createRef, useEffect, useReducer } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { FormikProps } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  lighten,
  IconButton,
  useTheme,
} from '@material-ui/core';
import {
  Button,
  AxialIcon,
  useRequest,
  Loading,
  Card,
  axialColors,
  stateReducer,
  StateReducer,
  formatDateString,
} from '@axial-healthcare/axial-react';
import { practiceApi, CMResponseWrapper, API_DATE_FORMAT } from 'src/constants/api';
import { PatientDocumentUpload } from '../patient-documents/upload';
import { ReferralFormValues, ReferralForm } from './form';
import { wayspringColors } from '..';

export interface ReferralPostResponse {
  episode_id: string;
}
export interface ReferralPostErrors {
  referrer?: { email?: string[] };
}

interface ReferredEpisode {
  patientName: string;
  episodeId: string;
}
interface DocumentUploadState {
  open: boolean;
  episode?: ReferredEpisode;
}

const NewReferral: React.FC = (): React.ReactElement => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [{ open: isDocumentUploadOpen, episode }, setDocumentUploadState] = useReducer<
    StateReducer<DocumentUploadState>
  >(stateReducer, {
    open: false,
  });

  const { typography } = useTheme();

  const toggleFormDialog = (): void => {
    setIsFormOpen(!isFormOpen);
  };

  const openDocumentUploadModal = (episodeForUpload?: ReferredEpisode): void => {
    setDocumentUploadState({ episode: episodeForUpload, open: true });
    setIsFormOpen(false);
  };

  return (
    <Card style={{ padding: 20, marginBottom: 30 }}>
      <div
        style={{
          color: wayspringColors.green,
          fontWeight: typography.fontWeightBold,
          fontSize: '1.75rem',
          marginBottom: 10,
        }}
      >
        Refer to Wayspring's Recovery Solutions Program
      </div>
      <p>
        Wayspring’s Recovery Solutions program is designed to help your patients successfully navigate their recovery
        journey once they are discharged from your care. By actively engaging patients, our team of Recovery Partners
        and Peer Recovery Specialists work to activate their post-discharge care plan, create a contingency plan, and
        connect them to community resources — all in support of sustained recovery. With a stigma-free, compassionate
        approach, our team guides patients along their path and helps remove any barriers they may encounter.
      </p>
      <p>
        Your access to this portal means that you are already set-up to send a referral to the Recovery Solutions
        program, so click below to get started!
      </p>
      <Button
        startIcon={AxialIcon.plus}
        variant="contained"
        color="primary"
        style={{ backgroundColor: wayspringColors.green, fontFamily: typography.body2.fontFamily }}
        onClick={toggleFormDialog}
      >
        Start Referral
      </Button>
      <Dialog open={isFormOpen} onClose={toggleFormDialog} disableBackdropClick={true} scroll="paper">
        <ReferralDialog onClose={toggleFormDialog} onUploadClick={openDocumentUploadModal} />
      </Dialog>
      {episode ? (
        <PatientDocumentUpload
          open={isDocumentUploadOpen}
          onClose={(): void => setDocumentUploadState({ open: false, episode: undefined })}
          {...episode}
        />
      ) : null}
    </Card>
  );
};

export interface ReferralPostBody {
  note?: string;
  patient: {
    first_name: string;
    last_name: string;
    subscriber_id: string;
    date_of_birth: string;
    phone_number?: string;
  };
  referrer: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
  };
}
interface ReferralDialogProps {
  onClose: () => void;
  onUploadClick: (episode?: ReferredEpisode) => void;
}
const ReferralDialog: React.FC<ReferralDialogProps> = ({
  onClose,
  onUploadClick,
}: ReferralDialogProps): React.ReactElement => {
  const [valuesToSubmit, setValuesToSubmit] = useState<ReferralFormValues>();
  const [postStatus, setPostStatus] = useState<number>();
  const [postErrorCode, setPostErrorCode] = useState<number>();
  const [postErrorMessage, setPostErrorMessage] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<ReferralPostErrors>();
  const [episode, setEpisode] = useState<ReferredEpisode>();

  const { palette, typography } = useTheme();

  // ref for calling submit from outside the form
  const formRef: React.RefObject<FormikProps<ReferralFormValues>> = createRef();

  const { status, httpStatus } = useRequest({
    request: ({ cancelToken }): Promise<void> => {
      if (!valuesToSubmit) {
        return new Promise((_resolve: () => void, reject: (reason: string) => void): void => {
          reject('no data to submit');
        });
      }

      const {
        notes,
        patientFirstName,
        patientLastName,
        patientSubscriberId,
        patientDob,
        patientPhone,
        referrerFirstName,
        referrerLastName,
        referrerPhone,
        referrerEmail,
      }: ReferralFormValues = valuesToSubmit;
      const postBody: ReferralPostBody = {
        note: notes || undefined,
        patient: {
          first_name: patientFirstName,
          last_name: patientLastName,
          subscriber_id: patientSubscriberId,
          date_of_birth: patientDob ? formatDateString(patientDob, 'MM/dd/yyyy', API_DATE_FORMAT) : '',
          phone_number: patientPhone || undefined,
        },
        referrer: {
          first_name: referrerFirstName,
          last_name: referrerLastName,
          phone_number: referrerPhone,
          email: referrerEmail,
        },
      };

      return practiceApi
        .post<CMResponseWrapper<ReferralPostResponse>>('/referrals/', postBody, { cancelToken })
        .then((response: AxiosResponse<CMResponseWrapper<ReferralPostResponse>>): void => {
          setPostStatus(response.status);
          setEpisode({
            episodeId: response.data.data.episode_id,
            patientName: `${patientFirstName} ${patientLastName}`.trim(),
          });
        })
        .catch(({ response }: AxiosError) => {
          if (response?.status) {
            setPostStatus(response.status);
          }
          if (response?.status === 409 && response?.data) {
            const error: string = response.data as string;
            setPostErrorMessage(error);
          }
          if (response?.data.errors) {
            const error: ReferralPostErrors = (response.data as { errors: ReferralPostErrors }).errors;
            setValidationErrors(error);
          }
        });
    },
    // make the post request when form values change but only if they are defined
    dependencies: [valuesToSubmit],
    condition: (): boolean => !!valuesToSubmit,
  });

  // store and reference a copy of "error" so we can clear it out
  // and reset the form
  useEffect(() => {
    if (httpStatus && httpStatus >= 500 && httpStatus < 600) {
      setPostErrorCode(httpStatus);
    } else {
      setPostErrorCode(undefined);
    }
  }, [httpStatus]);

  const submitReferral = (values: ReferralFormValues): void => {
    if (valuesToSubmit) {
      resetForm();
    }
    setValuesToSubmit(values);
  };

  const resetForm = (): void => {
    setValuesToSubmit(undefined);
    setValidationErrors(undefined);
    setPostStatus(undefined);
    setPostErrorCode(undefined);
    setPostErrorMessage(undefined);
  };

  return (
    <>
      <DialogTitle
        style={{
          padding: '8px 12px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        disableTypography={true}
      >
        <Typography variant="h6">{postStatus === 201 ? 'Thank you for referring!' : 'Start Referral'}</Typography>
        <IconButton size="small" style={{ marginTop: -12, marginBottom: -12 }} onClick={onClose}>
          <i className={AxialIcon.cancel} style={{ color: axialColors.gray25, fontSize: '1.2rem' }} />
        </IconButton>
      </DialogTitle>
      {postErrorCode || postErrorMessage || (postStatus && postStatus !== 201) ? (
        <div
          style={{
            backgroundColor: lighten(axialColors.red25, 0.5),
            borderTop: `1px solid ${palette.divider}`,
            padding: 12,
          }}
        >
          <Typography variant="h4" style={{ color: palette.error.main }}>
            {postErrorMessage
              ? postErrorMessage
              : postStatus === 400
              ? 'The referral failed to send. Please check your answers and try again.'
              : 'The referral failed to send. Please try again in a few moments.'}
          </Typography>
        </div>
      ) : null}
      <DialogContent dividers={true}>
        <Loading loading={status === 'loading'} style={{ marginTop: 20, marginBottom: 20 }}>
          {postStatus === 201 ? (
            <>
              You can add supplemental documents to the referral by clicking "Add Documents", begin a new referral by
              clicking "Start Another Referral", or just click "Close" to exit.
            </>
          ) : (
            <ReferralForm
              initialValues={valuesToSubmit}
              serverErrors={validationErrors}
              onSubmit={submitReferral}
              innerRef={formRef}
            />
          )}
        </Loading>
      </DialogContent>
      <DialogActions>
        {postStatus === 201 ? (
          <>
            <Button
              variant="outlined"
              color="primary"
              style={{ backgroundColor: wayspringColors.green }}
              onClick={resetForm}
            >
              Start Another Referral
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: wayspringColors.green }}
              onClick={(): void => onUploadClick(episode)}
            >
              Add Documents
            </Button>
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={status === 'loading'}
              style={{ fontFamily: typography.body2.fontFamily }}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              disabled={status === 'loading'}
              variant="contained"
              color="primary"
              style={{ backgroundColor: wayspringColors.green, fontFamily: typography.body2.fontFamily }}
              onClick={(): void => {
                formRef.current?.submitForm();
              }}
            >
              Send Referral
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
};

export { NewReferral };
