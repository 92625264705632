import React, { ReactElement, useContext, useEffect, useReducer, useCallback } from 'react';
import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { useFeatureFlags, Features } from '@axial-healthcare/axial-react';
import { practiceApi, PROFILE_CHECK_INTERVAL, PRACTICE_API_AUTH_HEADER } from 'src/constants/api';
import { PracticeFeatureList } from 'src/constants/environment';
import {
  ModuleConfig,
  appModules,
  StaticRoutes,
  ModuleTypes,
  apiModuleMap,
  ApiModuleTypes,
} from 'src/modules/constants';
import { UserState, User, UserSwitchPostBody, UserAttestPostBody } from './interface';

const defaultUserState: UserState = {
  user: {},
  isUserLoggedIn: false,
  modules: [],
  isLoading: false,
  showLoading: false,
  declinedAttestation: false,
  deactivated: false,
  login: (): void => {
    return;
  },
  logout: (): void => {
    return;
  },
  updateContext: (): void => {
    return;
  },
  attest: (): void => {
    return;
  },
  resetAttestation: (): void => {
    return;
  },
};
const UserContext: React.Context<UserState> = React.createContext(defaultUserState);
const useUser = (): UserState => {
  const context: UserState = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used inside a UserProvider');
  }
  return context;
};

interface UserProviderState {
  isLoading: boolean;
  showLoading: boolean;
  user: User;
  declinedAttestation: boolean;
  deactivated: boolean;
  loginUrl: string;
}
interface UserProviderProps {
  children: React.ReactNode;
  userState?: Partial<UserProviderState>;
}
const userProviderReducer = (
  currentState: UserProviderState,
  newState: Partial<UserProviderState>
): UserProviderState => {
  return { ...currentState, ...newState };
};

let startLoadingTimeout: NodeJS.Timeout;

const UserProvider: React.FC<UserProviderProps> = ({
  children,
  userState: initUserState,
}: UserProviderProps): ReactElement => {
  const [{ isLoading, showLoading, user: userFromState, declinedAttestation, deactivated, loginUrl }, setState] =
    useReducer(userProviderReducer, {
      isLoading: true,
      showLoading: false,
      declinedAttestation: false,
      deactivated: false,
      user: {},
      loginUrl: '',
      ...initUserState,
    });

  // exported functions
  const login = (): void => {
    window.location.assign(loginUrl);
  };

  const history: History = useHistory();
  const logout = (): Promise<void> => {
    startLoading();
    return practiceApi
      .post('/users/logout/')
      .then((response: AxiosResponse<{ login_url: string }>) => {
        handleLoggedOutUser(response);
        history.push(StaticRoutes.logout);
      })
      .catch((error: AxiosError) => {
        console.error('LOGOUT: Failed to logout user', error);
      })
      .finally(() => stopLoading());
  };

  const updateContext = (body: UserSwitchPostBody): Promise<void> => {
    startLoading();
    return practiceApi
      .post<User>('/users/switch/', body)
      .then((switchResponse: AxiosResponse<User>) => {
        setState({ user: switchResponse.data });
      })
      .catch((error: AxiosError) => {
        console.error('SWITCH: Failed to switch context', error);
      })
      .finally(() => {
        stopLoading();
        history.push(StaticRoutes.root);
      });
  };

  const attest = (body: UserAttestPostBody): Promise<void> => {
    startLoading();
    return practiceApi
      .post<User>('/users/attest/', body)
      .then(() => {
        setState({ declinedAttestation: !body.attested, user: { ...userFromState, needs_attestation: false } });
      })
      .catch((error: AxiosError) => {
        console.error('ATTEST: Failed to update user attestation', error);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const resetAttestation = (): void => {
    setState({ user: { ...userFromState, needs_attestation: true } });
  };

  // utilities
  const handleLoggedOutUser: (response: AxiosResponse<{ login_url: string }> | undefined) => void = useCallback(
    (response: AxiosResponse<{ login_url: string }> | undefined): void => {
      if (response?.data) {
        setState({ loginUrl: response.data.login_url });
      }
      setState({ user: {} });
    },
    []
  );

  const startLoading: () => void = useCallback(() => {
    setState({ isLoading: true });
    startLoadingTimeout = setTimeout(() => {
      setState({ showLoading: true });
    }, 500);
  }, []);

  const stopLoading: () => void = useCallback(() => {
    clearTimeout(startLoadingTimeout);
    setState({ isLoading: false, showLoading: false });
  }, []);

  const { features: featureFlagsFromContext, setFeatures } = useFeatureFlags();
  const buildModuleList = (user: User, featureFlags: Features): ModuleConfig[] => {
    const apiModules: ModuleConfig[] = Object.keys(user.modules || {})
      .map((apiKey: string) => {
        const { configKey } = apiModuleMap[apiKey as ApiModuleTypes] || {};
        return appModules[configKey || ''];
      })
      // remove api modules that don't have a frontend config yet to avoid breaking app
      .filter((module: ModuleConfig) => module !== undefined);
    const localModules: ModuleConfig[] = Object.keys(appModules).reduce(
      (accumulatedModules: ModuleConfig[], currentKey: string) => {
        const currentModule: ModuleConfig = appModules[currentKey as ModuleTypes];

        const moduleIsFromApi = !!currentModule.requiresAuth;
        const moduleIsActive: boolean = !currentModule.featureFlag || featureFlags[currentModule.featureFlag] === true;

        if (!moduleIsFromApi && moduleIsActive) {
          return [...accumulatedModules, currentModule];
        }
        return accumulatedModules;
      },
      []
    );
    return [...apiModules, ...localModules];
  };

  const checkIsUserLoggedIn = (user: User): boolean => !!user.csrf;

  // check module based features when user changes
  useEffect(() => {
    const featureFlagsFromApi: PracticeFeatureList = Object.keys(apiModuleMap).reduce(
      (accumulatedFlags: PracticeFeatureList, apiModuleKey: string) => {
        // get the features from the frontend config for the current module
        const { features: featureMap } = apiModuleMap[apiModuleKey as ApiModuleTypes] || {};
        if (!featureMap) {
          return accumulatedFlags;
        }

        // get api settings for the current module
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const moduleSettings = (userFromState.modules || {})[apiModuleKey as ApiModuleTypes] || {};

        // set a value for each feature based on settings from api
        return {
          ...accumulatedFlags,
          ...Object.keys(featureMap).reduce((accumulatedFlagsForModule: PracticeFeatureList, apiFeatureKey: string) => {
            return { ...accumulatedFlagsForModule, [featureMap[apiFeatureKey]]: !!moduleSettings[apiFeatureKey] };
          }, {} as PracticeFeatureList),
        };
      },
      {}
    );

    setFeatures(featureFlagsFromApi);
  }, [userFromState, setFeatures]);

  // add interceptor to append auth token to request headers
  useEffect(() => {
    const authHeaderRequestInterceptor: number = practiceApi.interceptors.request.use((request: AxiosRequestConfig) => {
      const secureMethods: string[] = ['POST', 'PATCH', 'PUT', 'DELETE'];
      if (userFromState.csrf && secureMethods.includes(request.method?.toUpperCase() || '')) {
        return {
          ...request,
          headers: {
            ...request.headers,
            [PRACTICE_API_AUTH_HEADER]: userFromState.csrf,
          },
        };
      }
      return request;
    });

    return (): void => {
      practiceApi.interceptors.request.eject(authHeaderRequestInterceptor);
    };
  }, [userFromState.csrf]);

  // add interceptor to handle session timeout and deactivated users
  useEffect(() => {
    const authErrorResponseInterceptor: number = practiceApi.interceptors.response.use(
      undefined,
      (error: AxiosError) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          if (error.response.status === 403 && error.response.data.error === 'deactivated') {
            setState({ deactivated: true });
          }
          handleLoggedOutUser(error.response);
        }
        return Promise.reject(error);
      }
    );

    return (): void => {
      practiceApi.interceptors.response.eject(authErrorResponseInterceptor);
    };
  }, [handleLoggedOutUser]);

  // clean up loading
  useEffect(() => {
    return (): void => stopLoading();
  }, [stopLoading]);

  // add profile check on interval
  useEffect(() => {
    let profileTimeout: number;

    const getProfile = ({ isInitialLoad }: { isInitialLoad?: boolean } = {}): Promise<void> => {
      if (isInitialLoad) {
        startLoading();
      }

      return practiceApi
        .get<User>('/users/profile/')
        .then((profileResponse: AxiosResponse<User>) => {
          const { data: user } = profileResponse;
          if (isInitialLoad || user.needs_attestation === true || user.changed === true) {
            setState({ user });
            setState({ deactivated: false });
          }
        })
        .catch((error: AxiosError) => {
          console.error('PROFILE: Failed to get user', error);
        })
        .finally(() => {
          stopLoading();
          profileTimeout = setTimeout(getProfile, PROFILE_CHECK_INTERVAL);
        });
    };

    getProfile({ isInitialLoad: true });

    return (): void => {
      clearTimeout(profileTimeout);
    };
  }, [startLoading, stopLoading]);

  return (
    <UserContext.Provider
      value={{
        user: userFromState,
        isUserLoggedIn: checkIsUserLoggedIn(userFromState),
        modules: isLoading ? [] : buildModuleList(userFromState, featureFlagsFromContext),
        isLoading,
        showLoading,
        login,
        logout,
        updateContext,
        attest,
        declinedAttestation,
        deactivated,
        resetAttestation,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { useUser, UserProvider };
