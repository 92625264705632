import React, { useState } from 'react';
import { Tabs, Tab, makeStyles, useTheme, Theme } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import {
  RequestWrapperTable,
  TableRowConfig,
  axialColors,
  formatPhoneNumber,
  formatDateString,
  RequestWrapper,
  RequestBuilderParams,
  RequestWrapperState,
} from '@axial-healthcare/axial-react';
import { practiceApi } from 'src/constants/api';
import { DISPLAY_DATE_FORMAT, API_DATE_FORMAT } from 'src/constants/api';
import { MemberPrescription, MemberPrescriptionType, PracticeOptions } from '../../../../interfaces';
import { PatientDetailTableProps } from '../../views/dashboard';
import { PrescriptionInfoDialog } from './prescription-info-dialog';

const PrescriptionTable: React.FC<PatientDetailTableProps<MemberPrescription>> = ({
  memberId,
  limit,
  resultsPerPage,
  TableProps: tableProps,
}: PatientDetailTableProps<MemberPrescription>): React.ReactElement => {
  const [selectedPrescriptionType, setSelectedPrescriptionType] = useState<string>('');
  const theme = useTheme();
  const { defaultTab } = useStyles();
  return (
    <>
      <RequestWrapper<PracticeOptions>
        LoadingProps={{ style: { display: 'none', marginTop: 0 } }}
        requestConfig={{
          request: ({ cancelToken }: RequestBuilderParams): Promise<AxiosResponse<PracticeOptions>> => {
            return practiceApi.get<PracticeOptions>('/options/', { cancelToken });
          },
        }}
      >
        {({ data }: RequestWrapperState<PracticeOptions>): React.ReactElement => (
          <div style={{ display: 'flex', borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Tabs
              value={selectedPrescriptionType}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_e: React.ChangeEvent<unknown>, tabValue: string) => {
                setSelectedPrescriptionType(tabValue);
              }}
              style={{
                minHeight: 'auto',
              }}
            >
              <Tab classes={{ root: defaultTab }} label="All" value="" />
              {data.prescription_types?.map((prescriptionType: MemberPrescriptionType) => (
                <Tab
                  classes={{ root: defaultTab }}
                  key={prescriptionType.display_name}
                  label={prescriptionType.display_name}
                  value={prescriptionType.id}
                />
              ))}
            </Tabs>
            <div style={{ flexGrow: 1, textAlign: 'right' }}>
              <PrescriptionInfoDialog prescriptionTypes={data.prescription_types} />
            </div>
          </div>
        )}
      </RequestWrapper>

      <RequestWrapperTable<MemberPrescription>
        TableProps={{
          ...tableProps,
          ...(selectedPrescriptionType
            ? {
                NoResultsProps: {
                  ...tableProps.NoResultsProps,
                  resultsName: tableProps.NoResultsProps?.resultsName?.replace(
                    'prescription',
                    `${selectedPrescriptionType} prescription`
                  ),
                },
              }
            : {}),
          columns: [
            { key: 'date', value: 'Date' },
            { key: 'name', value: 'Name' },
            { key: 'type', value: 'Type' },
            { key: 'quantity', value: 'Quantity' },
            { key: 'days-supply', value: 'Days Supply' },
            { key: 'prescriber', value: 'Provider/Prescriber' },
            { key: 'pharmacy', value: 'Location/Pharmacy' },
            ...(tableProps?.columns || []),
          ],
          mapDataToRow: mapPrescriptionToRow(theme),
        }}
        axiosRequestConfig={{
          request: {
            api: practiceApi,
            url: `/members/${memberId}/prescriptions/`,
            params: {
              ...(limit ? { limit } : {}),
              ...(selectedPrescriptionType ? { prescription_type: selectedPrescriptionType } : {}),
            },
          },
          dependencies: [selectedPrescriptionType],
          paginationOptions: resultsPerPage ? { resultsPerPage } : undefined,
        }}
      />
    </>
  );
};

const mapPrescriptionToRow =
  (theme: Theme) =>
  (prescription: MemberPrescription): TableRowConfig => ({
    key: prescription.fill_key,
    cells: [
      {
        key: 'date',
        style: { color: axialColors.gray50 },
        value: prescription.fill_date
          ? formatDateString(prescription.fill_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT)
          : '',
      },
      { key: 'name', style: { fontWeight: theme.typography.fontWeightBold }, value: prescription.ndc_label_name },
      { key: 'type', value: prescription.prescription_type },
      {
        key: 'quantity',
        style: { color: axialColors.gray50 },
        value: prescription.quantity_dispensed,
      },
      { key: 'days-supply', style: { color: axialColors.gray50 }, value: prescription.days_supply },
      {
        key: 'prescriber',
        style: { color: axialColors.gray50 },
        value: prescription.prescriber ? (
          <div style={{ fontSize: '0.8rem' }}>
            {prescription.prescriber.display_name ? (
              <div style={{ textTransform: 'uppercase', fontWeight: theme.typography.fontWeightBold }}>
                {prescription.prescriber.display_name}
              </div>
            ) : null}
            {prescription.prescriber.phone_number ? (
              <div>{formatPhoneNumber(prescription.prescriber.phone_number)}</div>
            ) : null}
            {prescription.prescriber.npi ? <div>NPI: {prescription.prescriber.npi}</div> : null}
          </div>
        ) : (
          'N/A'
        ),
      },
      {
        key: 'pharmacy',
        style: { color: axialColors.gray50 },
        value: prescription.pharmacy ? (
          <div style={{ fontSize: '0.8rem' }}>
            {prescription.pharmacy.name ? (
              <div style={{ textTransform: 'uppercase', fontWeight: theme.typography.fontWeightBold }}>
                {prescription.pharmacy.name}
              </div>
            ) : null}
            {prescription.pharmacy.phone_number ? (
              <div>{formatPhoneNumber(prescription.pharmacy.phone_number)}</div>
            ) : null}
            {prescription.pharmacy.npi ? <div>NPI: {prescription.pharmacy.npi}</div> : null}
          </div>
        ) : (
          'N/A'
        ),
      },
    ],
  });

const useStyles = makeStyles((theme) => ({
  defaultTab: {
    padding: 12,
    lineHeight: '1em',
    fontSize: '0.875rem',
    minWidth: 'initial',
    minHeight: 'initial',
    textTransform: 'capitalize',
    fontFamily: theme.typography.body1.fontFamily,
  },
}));

export { PrescriptionTable };
