import React from 'react';
import { makeStyles } from '@material-ui/core';
import { LinkWrapper, axialColors, Card } from '@axial-healthcare/axial-react';
import { moduleRoutes } from '../constants';
import {
  NotificationTypesProvider,
  useNotificationTypesContext,
  NotificationWithCount,
} from '../patient-intelligence/components/notification-types-provider';

const PatientIntelligenceSummary: React.FC = (): React.ReactElement => {
  return (
    <NotificationTypesProvider>
      <PatientIntelSummaryContent />
    </NotificationTypesProvider>
  );
};

const PatientIntelSummaryContent: React.FC = (): React.ReactElement => {
  const notificationsWithCount = useNotificationTypesContext();
  const { notificationCountRow } = useStyles();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className={notificationCountRow}
        style={{ fontSize: '0.7rem', paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }}
      >
        <div>Notification Name</div>
        <div className="count">Active</div>
      </div>
      {(notificationsWithCount || []).map((notification: NotificationWithCount) => (
        <LinkWrapper
          key={notification.key}
          style={{ marginTop: 10 }}
          internalLink={{ to: `${moduleRoutes.patientIntelligence}?type=${notification.key}` }}
        >
          <Card className={notificationCountRow}>
            <div>{notification.label}</div>
            <div className="count" style={{ color: axialColors.gray50 }}>
              {notification.count}
            </div>
          </Card>
        </LinkWrapper>
      ))}
    </div>
  );
};

const useStyles: () => Record<'notificationCountRow', string> = makeStyles((theme) => ({
  notificationCountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '14px 16px',
    fontWeight: theme.typography.fontWeightBold,
    '& > .count': {
      width: 50,
      textAlign: 'center',
    },
  },
}));

export { PatientIntelligenceSummary };
