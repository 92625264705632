import React from 'react';
import { Card } from '@axial-healthcare/axial-react';
import { PrescriptionTable } from '../components/prescription-table';

interface PatientPrescriptionsViewProps {
  memberId: string;
}
const PatientPrescriptionsView: React.FC<PatientPrescriptionsViewProps> = ({
  memberId,
}: PatientPrescriptionsViewProps): React.ReactElement => {
  return (
    <Card>
      <PrescriptionTable
        memberId={memberId}
        resultsPerPage={10}
        TableProps={{ NoResultsProps: { resultsName: 'prescription history' } }}
      />
    </Card>
  );
};

export { PatientPrescriptionsView };
