import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { PageNotFoundView } from 'src/views/page-not-found';
import { OpioidReductionIntroView } from './intro';
import { OpioidReductionMonitorView } from './monitor';
import { OpioidReductionPatientEngagementView } from './patient-engagement';
import { OpioidReductionRiskBenefitAnalysisView } from './risk-benefit-analysis';
import { OpioidReductionFirstView } from './survey-first';
import { OpioidReductionSecondView } from './survey-second';

const OpioidReductionSurveyView: React.FC<{ faqsUrl: string }> = ({
  faqsUrl,
}: {
  faqsUrl: string;
}): React.ReactElement => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact={true} path={path}>
        <OpioidReductionIntroView />
      </Route>
      <Route exact={true} path={`${path}/1`}>
        <OpioidReductionFirstView />
      </Route>
      <Route exact={true} path={`${path}/2`}>
        <OpioidReductionSecondView />
      </Route>
      <Route exact={true} path={`${path}/patient-engagement`}>
        <OpioidReductionPatientEngagementView faqsUrl={faqsUrl} />
      </Route>
      <Route exact={true} path={`${path}/risk-benefit-analysis`}>
        <OpioidReductionRiskBenefitAnalysisView />
      </Route>
      <Route exact={true} path={`${path}/monitor`}>
        <OpioidReductionMonitorView />
      </Route>
      <Route path="/*" component={PageNotFoundView} />
    </Switch>
  );
};

export { OpioidReductionSurveyView };
