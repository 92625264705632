import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { Collapse, axialColors, AxialIcon } from '@axial-healthcare/axial-react';

interface CollapsibleTextProps {
  title: string;
  initCollapsed?: boolean;
}
const CollapsibleText: React.FC<CollapsibleTextProps> = ({
  title,
  initCollapsed = true,
  children,
}: React.PropsWithChildren<CollapsibleTextProps>): ReactElement => {
  return (
    <div style={{ borderRadius: 5, border: `1px solid ${axialColors.gray10}`, marginBottom: 5 }}>
      <Collapse
        initCollapsed={initCollapsed}
        CollapseIconProps={{ icon: AxialIcon.down }}
        title={
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
        }
      >
        {children}
      </Collapse>
    </div>
  );
};

export { CollapsibleText };
