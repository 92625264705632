import React, { useState } from 'react';
import { Typography, Button, styled } from '@material-ui/core';
import { axialColors, AxialIcon, Dialog } from '@axial-healthcare/axial-react';
import { MemberPrescriptionType } from '../../../../interfaces';

interface Props {
  prescriptionTypes: MemberPrescriptionType[];
}

const PrescriptionInfoDialog: React.FC<Props> = ({ prescriptionTypes }: Props): React.ReactElement => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  return (
    <>
      <DescriptionButton role="button" onClick={() => setDialogOpen(true)}>
        <i className={AxialIcon.infoCircled} style={{ marginRight: 4 }} />
        What do the prescription types mean?
      </DescriptionButton>
      <Dialog
        open={dialogOpen}
        maxWidth="sm"
        onClose={() => setDialogOpen(false)}
        dialogTitle={{ text: 'Prescription Types', icon: AxialIcon.infoCircled }}
        DialogActionsProps={{
          children: (
            <>
              <Button onClick={() => setDialogOpen(false)}>Close</Button>
            </>
          ),
        }}
      >
        {prescriptionTypes?.map((prescriptionType: MemberPrescriptionType) => (
          <div key={prescriptionType.id}>
            <Typography variant="h3" color="primary">
              {prescriptionType.display_name}
            </Typography>
            <div style={{ marginBottom: 20 }}>{prescriptionType.description}</div>
          </div>
        ))}
      </Dialog>
    </>
  );
};

const DescriptionButton = styled('div')(({ theme }) => ({
  color: axialColors.gray50,
  fontSize: 12,
  margin: 10,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export { PrescriptionInfoDialog };
