import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { messages } from 'src/constants/messages';
import { useCurrentModule } from 'src/modules/constants';
import { MessageCard } from './message-card';
import { ProviderSelect } from './context-select/provider-select';
import { useUser } from './user-provider';
import { AttestationModal } from './attestation-modal';
import { usePrintStyles, useMobileLayoutStyles, mergeCssClasses } from '@axial-healthcare/axial-react';

interface ModuleViewWrapperProps {
  children: React.ReactNode;
}
const ModuleViewWrapper: React.FC<ModuleViewWrapperProps> = ({
  children,
}: ModuleViewWrapperProps): React.ReactElement => {
  const { isUserLoggedIn, user, updateContext, declinedAttestation, resetAttestation, attest } = useUser();
  const { currentModule } = useCurrentModule();

  const styles: Record<StyleKeys, string> = useStyles();
  const { noPrint } = usePrintStyles();
  const { noDesktop } = useMobileLayoutStyles();

  const showProviderSelect: boolean = isUserLoggedIn && !!currentModule?.isProviderContext;
  const requiresAttestation = !!currentModule?.requiresAttestation;

  return (
    <>
      <AttestationModal open={!!requiresAttestation && isUserLoggedIn && !!user.needs_attestation} attest={attest} />
      {declinedAttestation && requiresAttestation ? (
        <MessageCard
          message={messages.declinedAttestation}
          buttons={[{ children: 'Re-attest', onClick: resetAttestation }]}
        />
      ) : (
        <>
          {showProviderSelect ? (
            <ProviderSelect
              updateContext={updateContext}
              providers={user.providers || []}
              currentProvider={user.provider}
              selectProps={{
                formControlProps: { classes: { root: mergeCssClasses(styles.formControl, noPrint, noDesktop) } },
              }}
            />
          ) : null}
          {children}
        </>
      )}
    </>
  );
};

type StyleKeys = 'formControl';
const useStyles: () => Record<StyleKeys, string> = makeStyles(
  createStyles({
    formControl: {
      marginBottom: 10,
      width: '100%',
    },
  })
);

export { ModuleViewWrapper };
