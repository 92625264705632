import { axialColors } from '@axial-healthcare/axial-react';

const thresholdColors: { [key: number]: string } = {
  0: axialColors.gray,
  1: axialColors.red,
  2: '#EC7D43',
  3: axialColors.yellow,
  4: axialColors.blue,
  5: axialColors.green,
  6: axialColors.green,
};

export { thresholdColors };
