import React from 'react';
import { RadioGroup, RadioGroupProps, makeStyles } from '@material-ui/core';
import {
  RadioProps,
  Radio,
  mergeCssClasses,
  maxWidthMediaQueries,
  minWidthMediaQueries,
} from '@axial-healthcare/axial-react';

interface SurveyQuestionProps extends RadioGroupProps {
  label: string | React.ReactElement;
  options: RadioProps[];
  layout?: 'column' | 'row';
}
const SurveyQuestion: React.FC<SurveyQuestionProps> = ({
  options,
  label,
  style,
  layout = 'row',
  className,
  ...restProps
}: SurveyQuestionProps): React.ReactElement => {
  const { radioColumn } = useStyles();
  return (
    <>
      <span style={{ fontSize: '1.3rem' }}>{label}</span>
      <RadioGroup
        className={mergeCssClasses(layout === 'column' ? radioColumn : '', className || '')}
        style={{
          margin: '10px 0 20px',
          display: 'flex',
          flexDirection: layout,
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          ...style,
        }}
        {...restProps}
      >
        {options.map(
          ({ FormControlLabelProps: { style: labelStyle, ...restLabelProps } = {}, ...restOption }: RadioProps) => (
            <Radio
              key={restOption.value}
              FormControlLabelProps={{ style: { width: '100%', marginBottom: 10, ...labelStyle }, ...restLabelProps }}
              {...restOption}
            />
          )
        )}
      </RadioGroup>
    </>
  );
};

const useStyles: () => Record<'radioColumn', string> = makeStyles({
  radioColumn: {
    [maxWidthMediaQueries.portraitPhone]: {
      '& > *': { marginBottom: 16 },
    },
    [minWidthMediaQueries.landscapePhone]: {
      '& > *': { height: 28, marginBottom: 5 },
    },
  },
});

export { SurveyQuestion };
