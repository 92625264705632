import React, { ReactElement } from 'react';
import { styled, CardProps } from '@material-ui/core';
import { ImageElementProps, maxWidthMediaQueries, Card } from '@axial-healthcare/axial-react';
import axialLogo from '@axial-healthcare/axial-branding/images/logo/axialLogo.png';
import { TwoColumnLayout } from 'src/components/two-column-layout';

const AxialSummary: React.FC = (): ReactElement => {
  return (
    <CardContainer>
      <TwoColumnLayout className="axialHeader">
        <div>
          <LogoImage src={axialLogo} alt="axialHealthcare" />
        </div>
        <div style={{ marginTop: 0 }}>
          axialHealthcare is a care optimization company connecting patients, providers, and health plans with an
          end-to-end platform that covers the entire substance use spectrum.
        </div>
      </TwoColumnLayout>
    </CardContainer>
  );
};

const CardContainer: React.ComponentType<CardProps> = styled(Card)({
  marginBottom: 30,
  '& .axialHeader': {
    padding: 40,
    alignItems: 'center',
    [maxWidthMediaQueries.desktop]: {
      padding: 20,
    },
  },
});

const LogoImage: React.ComponentType<ImageElementProps> = styled('img')({
  height: 60,
  display: 'inline-block',
  [maxWidthMediaQueries.tablet]: {
    height: 50,
  },
});

export { AxialSummary };
