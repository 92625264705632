import React from 'react';
import { ButtonProps, styled, CardProps } from '@material-ui/core';
import {
  NavLinkButtonProps,
  NavLinkButton,
  Button,
  minWidthMediaQueries,
  ImageElementProps,
  maxWidthMediaQueries,
  ElementProps,
  Card,
} from '@axial-healthcare/axial-react';
import axialLogo from '@axial-healthcare/axial-branding/images/logo/axialLogo.png';

interface MessageCardProps {
  message: string | React.ReactNode;
  buttons?: Array<ButtonProps | NavLinkButtonProps>;
}
const MessageCard: React.FC<MessageCardProps> = ({ message, buttons }: MessageCardProps): React.ReactElement => {
  return (
    <CardContainer>
      <div style={{ padding: 30 }}>
        <LogoImage src={axialLogo} alt="axialHealthcare" />
        <MessageBody>{message}</MessageBody>
        {(buttons?.length || 0) > 0 ? (
          <div style={{ marginTop: 20 }}>
            {(buttons || []).map((button: ButtonProps | NavLinkButtonProps, index: number) => {
              const props: ButtonProps | NavLinkButtonProps = {
                key: `${index}_${(button.children || '').toString()}`,
                variant: 'contained',
                color: 'primary',
                ...button,
              };
              return (button as NavLinkButtonProps).internalLink ? (
                <NavLinkButton {...(props as NavLinkButtonProps)} />
              ) : (
                <Button {...(props as ButtonProps)} />
              );
            })}
          </div>
        ) : null}
      </div>
    </CardContainer>
  );
};

const CardContainer: React.ComponentType<CardProps> = styled(Card)({
  [minWidthMediaQueries.desktop]: {
    margin: 50,
  },
});

const LogoImage: React.ComponentType<ImageElementProps> = styled('img')({
  height: 45,
  marginBottom: 20,
  display: 'block',
  [maxWidthMediaQueries.tablet]: {
    height: 35,
  },
});

const MessageBody: React.ComponentType<ElementProps<HTMLSpanElement>> = styled('span')({
  [minWidthMediaQueries.desktop]: {
    fontSize: '1.2rem',
  },
  marginBottom: 10,
});

export { MessageCard };
