import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { NavLinkButton, axialColors, AxialIcon, Card } from '@axial-healthcare/axial-react';
import { AxialSummary } from 'src/components/axial-summary';
import { SectionTitle } from 'src/components/section-title';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import { moduleRoutes } from 'src/modules/constants';
import { InfoModal } from '../../components/info-modal/info-modal';
import { useUser } from '../../components/user-provider';
import { ProviderStratSummary } from './provider-strat-summary';
import { PatientIntelligenceSummary } from './patient-intel-summary';

const ProviderDashboardView: React.FC = (): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <>
      <AxialSummary />
      <Typography variant="h1">Dashboard</Typography>
      <UserIntroductionContent />
      <TwoColumnLayout
        row={{
          flexGrow: { columnOne: 2, columnTwo: 1 },
          styles: {
            parent: {
              '& > .patient-intel': { paddingRight: 50, borderRight: `2px solid ${palette.divider}`, maxWidth: 800 },
              '& > .provider-strat': { maxWidth: 250 },
            },
          },
        }}
      >
        <div className="patient-intel">
          <SectionTitle icon={AxialIcon.lightBulb} title="Patient Intelligence" />
          <InfoModal text="What is Patient Intelligence?" style={{ display: 'block', marginBottom: 20 }}>
            Patient Intelligence alerts providers to patients flagged with opioid-related risks (e.g. ED visits for
            opioid overdose, concurrent opioid and benzodiazepine prescriptions, and others) and offers a snapshot view
            of patients’ recent notifications, diagnoses, and prescription information.
          </InfoModal>
          <PatientIntelligenceSummary />
          <SectionLink label="View More Patient Intelligence" to={moduleRoutes.patientIntelligence} />
        </div>
        <div className="provider-strat">
          <div style={{ textAlign: 'center' }}>
            <SectionTitle icon={AxialIcon.hexagon} title="RIM Report" />
            <InfoModal text="What is the RIM Report?" style={{ display: 'block', marginBottom: 20 }}>
              Improving health outcomes, the Risk Identification and Mitigation (RIM) Report shows how a provider’s
              opioid prescribing may be impacting patient safety.
            </InfoModal>
          </div>
          <ProviderStratSummary />
          <SectionLink label="View Full RIM Report" to={moduleRoutes.providerStrat} />
        </div>
      </TwoColumnLayout>
    </>
  );
};

interface SectionLinkProps {
  label: string;
  to: string;
  style?: React.CSSProperties;
}
const SectionLink: React.FC<SectionLinkProps> = ({ label, to, style }: SectionLinkProps): React.ReactElement => {
  return (
    <div style={{ marginTop: 10, textAlign: 'center', ...style }}>
      <NavLinkButton internalLink={{ to }} ButtonProps={{ style: { color: axialColors.gray50, whiteSpace: 'pre' } }}>
        <i className={AxialIcon.rightCircled1} style={{ marginRight: 4 }} />
        {label}
      </NavLinkButton>
    </div>
  );
};

const UserIntroductionContent: React.FC = (): React.ReactElement => {
  const { palette, typography } = useTheme();
  const { user } = useUser();
  const { provider } = user;
  const providerName: string =
    provider?.first_name || provider?.last_name
      ? `${provider.first_name}${provider.last_name ? ` ${provider.last_name}` : ''}`
      : provider?.name || '';
  return (
    <Card style={{ padding: 20, marginBottom: 30 }}>
      <div style={{ color: palette.primary.main, fontWeight: typography.fontWeightBold, marginBottom: 10 }}>
        Welcome to the dashboard{providerName ? ` for ${providerName}` : ''}!
      </div>
      <div>
        A summary of the features available to you is shown below. Click on the “View” button below each section to
        access a detailed view or use the left-hand panel to navigate through the portal.
      </div>
    </Card>
  );
};

export { ProviderDashboardView };
