import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

interface SectionTitleProps {
  icon: string;
  title: string;
  style?: React.CSSProperties;
}
const SectionTitle: React.FC<SectionTitleProps> = ({ icon, title, style }: SectionTitleProps): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <Typography variant="h2" style={{ whiteSpace: 'pre', marginBottom: 10, ...style }}>
      <i style={{ color: palette.primary.main }} className={icon} /> {title}
    </Typography>
  );
};

export { SectionTitle };
