import React from 'react';
import { Card } from '@axial-healthcare/axial-react';
import { NotificationTable } from '../components/notification-table';

interface PatientNotificationsViewProps {
  memberId: string;
}
const PatientNotificationsView: React.FC<PatientNotificationsViewProps> = ({
  memberId,
}: PatientNotificationsViewProps): React.ReactElement => {
  return (
    <Card>
      <NotificationTable
        memberId={memberId}
        resultsPerPage={10}
        TableProps={{ NoResultsProps: { resultsName: 'notification history' } }}
      />
    </Card>
  );
};

export { PatientNotificationsView };
