import React from 'react';
import { CardProps, CardContent, CardContentProps, Typography, useTheme } from '@material-ui/core';
import { axialColors, Card } from '@axial-healthcare/axial-react';

interface ListItemProps extends CardContentProps {
  text: string;
  icon?: string;
  subtext?: string;
  action?: React.ReactNode;
}
interface ListCardProps extends Omit<CardProps, 'children'> {
  title: string;
  items: ListItemProps[];
}
const ListCard: React.FC<ListCardProps> = ({ title, items, ...restCard }: ListCardProps): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <Card {...restCard}>
      <CardContent style={{ borderBottom: `1px solid ${palette.divider}`, fontSize: '1.1rem' }}>
        <Typography variant="h3" style={{ margin: 0 }}>
          {title}
        </Typography>
      </CardContent>
      {items.map((listItem: ListItemProps) => (
        <ListItem key={`${listItem.text}-${listItem.subtext}`} {...listItem} />
      ))}
    </Card>
  );
};
const ListItem: React.FC<ListItemProps> = ({
  icon,
  text,
  subtext,
  action,
  style,
  ...restListItem
}: ListItemProps): React.ReactElement => {
  const { typography } = useTheme();
  return (
    <CardContent
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingBottom: 20,
        ...style,
      }}
      {...restListItem}
    >
      <i style={{ fontSize: '1.3rem', marginRight: 10, color: axialColors.gray50 }} className={icon} />
      <div>
        <div style={{ fontWeight: typography.fontWeightBold }}>{text}</div>
        <div style={{ color: axialColors.gray50, lineHeight: '14px' }}>{subtext}</div>
      </div>
      {action ? <div style={{ marginLeft: 'auto' }}>{action}</div> : null}
    </CardContent>
  );
};

export { ListCard };
