import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { isValid } from 'date-fns';
import {
  TableRowConfig,
  Card,
  formatDateString,
  AxialIcon,
  Select,
  RequestWrapper,
  RequestBuilderParams,
  Table,
  RequestWrapperState,
  DateField,
  TextField,
  Checkbox,
  useDebouncedCallback,
  SelectOption,
} from '@axial-healthcare/axial-react';
import { practiceApi, API_DATE_FORMAT, DISPLAY_DATE_FORMAT, ApiPaginatedResponse, ApiOption } from 'src/constants/api';
import { PatientDocumentUpload } from '../patient-documents/upload';
import { Typography, useTheme } from '@material-ui/core';
import { wayspringColors } from '..';

export interface ReferralEpisode {
  axial_partner: string | null;
  id: string;
  referrer: string | null;
  referral: {
    intake_type: ApiOption;
  };
  status: {
    status: {
      display_name: string;
    };
    sub_status: {
      display_name: string;
    };
  };
  patient: {
    first_name: string;
    last_name: string;
    full_name: string;
    subscriber_id: string | null;
    client_slug: string;
    date_of_birth: string | null;
  };
}

interface ReferralFilters {
  firstName: string;
  lastName: string;
  dob: string | null;
  subscriberId: string;
  status: string;
  isPortalReferred: boolean;
}

const ReferralTable: React.FC = (): React.ReactElement => {
  const [filters, setFilters] = useState<ReferralFilters>({
    firstName: '',
    lastName: '',
    dob: null,
    subscriberId: '',
    status: '',
    isPortalReferred: false,
  });

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [currentOpenItem, setCurrentOpenItem] = useState<{ episodeId: string; patientName: string }>({
    episodeId: '',
    patientName: '',
  });

  const { typography } = useTheme();

  return (
    <RequestWrapper<ApiOption[]>
      requestConfig={{
        request: ({ cancelToken }) => {
          return practiceApi
            .get<{ statuses: ApiOption[] }>('/referrals/statuses/', { cancelToken })
            .then((response: AxiosResponse<{ statuses: ApiOption[] }>) => response.data.statuses);
        },
      }}
    >
      {({ data: statusOptions }: RequestWrapperState<ApiOption[]>) => (
        <>
          <Typography variant="h2" style={{ fontFamily: typography.body2.fontFamily }}>
            <i className={AxialIcon.list} style={{ color: wayspringColors.green }} /> Patient Referral Status
          </Typography>
          <p>
            Click each patient below to upload supplemental documentation. Any patient with a{' '}
            <i className={AxialIcon.star} style={{ color: wayspringColors.green }} /> was referred directly from this
            portal. All other patients were referred by providers and facilities through a Wayspring team member.
          </p>
          <Card>
            <ReferralFilters filters={filters} setFilters={setFilters} statusOptions={statusOptions} />
            <RequestWrapper<ApiPaginatedResponse<ReferralEpisode>>
              hidePagination={true}
              requestConfig={{
                paginationOptions: { resultsPerPage: 20, resetDependencies: [filters] },
                dependencies: [filters],
                request: ({ paginationQueryParams, cancelToken }: RequestBuilderParams) => {
                  return practiceApi.post(
                    `/referrals/search/`,
                    {
                      is_practice_referral: filters.isPortalReferred || null,
                      ...(filters.firstName ? { patient_first_name: filters.firstName } : {}),
                      ...(filters.lastName ? { patient_last_name: filters.lastName } : {}),
                      ...(filters.dob
                        ? { patient_date_of_birth: formatDateString(filters.dob, 'MM/dd/yyyy', API_DATE_FORMAT) }
                        : {}),
                      ...(filters.subscriberId ? { patient_subscriber_id: filters.subscriberId } : {}),
                      ...(filters.status.length === 0 ? {} : { statuses: filters.status.split(',') }),
                    },
                    {
                      cancelToken,
                      params: paginationQueryParams,
                    }
                  );
                },
              }}
            >
              {({ data, pagination }: RequestWrapperState<ApiPaginatedResponse<ReferralEpisode>>) => (
                <>
                  <Table
                    columns={[
                      { key: 'isPortalReferred', value: '', style: { width: 30 } },
                      { key: 'patientFirstName', value: 'Patient First Name' },
                      { key: 'patientLastName', value: 'Patient Last Name' },
                      { key: 'dateOfBirth', value: 'Date of Birth' },
                      { key: 'subscriberId', value: 'Subscriber ID' },
                      { key: 'status', value: 'Status' },
                      { key: 'axialPartner', value: 'Wayspring Recovery Partner' },
                      { key: 'referrer', value: 'Portal Referrer Name' },
                    ]}
                    rows={data.results.map((episode: ReferralEpisode): TableRowConfig => {
                      const statusDetails = statusOptions.find((option: ApiOption) => {
                        return option.display_name === episode.status.status.display_name;
                      });
                      let fullStatus: string;
                      if (statusDetails && referredStatuses.includes(statusDetails.name)) {
                        fullStatus = 'Referred';
                      } else {
                        fullStatus =
                          episode.status.status.display_name === 'Inactive'
                            ? `${episode.status.status.display_name} (${episode.status.sub_status.display_name})`
                            : episode.status.status.display_name;
                      }

                      return {
                        key: episode.id,
                        onClick: (): void => {
                          setCurrentOpenItem({ episodeId: episode.id, patientName: episode.patient.full_name });
                          setIsUploadDialogOpen(true);
                        },
                        cells: [
                          {
                            key: 'isPortalReferred',
                            style: { textAlign: 'center' },
                            value:
                              episode.referral.intake_type.name === 'PRACTICE' ? (
                                <i className={AxialIcon.star} style={{ color: wayspringColors.green }} />
                              ) : null,
                          },
                          { key: 'patientFirstName', value: episode.patient.first_name },
                          { key: 'patientLastName', value: episode.patient.last_name },
                          {
                            key: 'dateOfBirth',
                            value: episode.patient.date_of_birth
                              ? formatDateString(episode.patient.date_of_birth, API_DATE_FORMAT, DISPLAY_DATE_FORMAT)
                              : '',
                          },
                          { key: 'subscriberId', value: episode.patient.subscriber_id },
                          { key: 'status', value: fullStatus },
                          { key: 'axialPartner', value: episode.axial_partner },
                          { key: 'referrer', value: episode.referrer },
                        ],
                      };
                    })}
                    pagination={pagination && data.count ? { ...pagination, count: data.count } : undefined}
                  />
                </>
              )}
            </RequestWrapper>
          </Card>
          <PatientDocumentUpload
            episodeId={currentOpenItem.episodeId}
            patientName={currentOpenItem.patientName}
            open={isUploadDialogOpen}
            onClose={(): void => {
              setIsUploadDialogOpen(false);
            }}
          />
        </>
      )}
    </RequestWrapper>
  );
};

const referredStatuses = ['IDENTIFIED', 'QUALIFIED', 'WRITTEN_CONSENT'];

interface ReferralFiltersProps {
  filters: ReferralFilters;
  setFilters: React.Dispatch<React.SetStateAction<ReferralFilters>>;
  statusOptions: ApiOption[];
}
const ReferralFilters: React.FC<ReferralFiltersProps> = ({
  filters,
  setFilters,
  statusOptions,
}: ReferralFiltersProps): React.ReactElement => {
  const [values, setValues] = useState<ReferralFilters>(filters);

  const { palette } = useTheme();

  const setFiltersWithDelay = useDebouncedCallback((filterName: string, value: string) => {
    setFilters((current: ReferralFilters) => ({ ...current, [filterName]: value.trim() }));
  }, 700);

  return (
    <div
      style={{
        padding: '5px 6px 0px',
        borderBottom: `1px solid ${palette.divider}`,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <TextField
        label="First Name"
        id="first-name"
        style={{ width: 150, margin: 4 }}
        value={values.firstName}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setValues((current: ReferralFilters) => ({ ...current, firstName: value }));
          setFiltersWithDelay('firstName', value);
        }}
      />
      <TextField
        label="Last Name"
        id="last-name"
        style={{ width: 150, margin: 4 }}
        value={values.lastName}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setValues((current: ReferralFilters) => ({ ...current, lastName: value }));
          setFiltersWithDelay('lastName', value);
        }}
      />
      <DateField
        label="Date of Birth"
        id="dob"
        style={{ margin: 4 }}
        value={values.dob}
        onChange={(date: Date, value: string | null | undefined): void => {
          setValues((current: ReferralFilters) => ({ ...current, dob: value || null }));
          if (!value || isValid(date)) {
            setFilters((current: ReferralFilters) => ({ ...current, dob: value || null }));
          }
        }}
      />
      <TextField
        label="Subscriber ID"
        id="subscriber-id"
        style={{ width: 150, margin: 4 }}
        value={values.subscriberId}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setValues((current: ReferralFilters) => ({ ...current, subscriberId: value }));
          setFiltersWithDelay('subscriberId', value);
        }}
      />
      <Select
        label="Status"
        id="status"
        options={[
          { label: 'None', value: '' },
          ...statusOptions.reduce(
            (accumulatedOptions: SelectOption[], statusOption: ApiOption) => {
              const isReferredStatus = referredStatuses.includes(statusOption.name);
              if (isReferredStatus) {
                return accumulatedOptions.map((option: SelectOption) => {
                  if (option.label === 'Referred' && isReferredStatus) {
                    return {
                      ...option,
                      value: [
                        ...(option.value as string).split(',').filter((value: string) => !!value),
                        statusOption.id.toString(),
                      ].join(','),
                    };
                  }
                  return option;
                });
              }
              return [...accumulatedOptions, { label: statusOption.display_name, value: statusOption.id.toString() }];
            },
            [{ label: 'Referred', value: '' }]
          ),
        ]}
        formControlProps={{ style: { margin: 4 } }}
        style={{ width: 150 }}
        value={values.status}
        onChange={({
          target: { value },
        }: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>) => {
          setValues((current: ReferralFilters) => ({ ...current, status: value as string }));
          setFilters((current: ReferralFilters) => ({ ...current, status: value as string }));
        }}
      />
      <Checkbox
        label={
          <>
            Referred through this portal (
            <i className={AxialIcon.star} style={{ color: wayspringColors.green }} />)
          </>
        }
        style={{ margin: 4, display: 'block' }}
        checked={values.isPortalReferred}
        CheckboxProps={{
          id: 'is-portal-referred',
          onChange: ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
            setValues((current: ReferralFilters) => ({ ...current, isPortalReferred: checked }));
            setFilters((current: ReferralFilters) => ({ ...current, isPortalReferred: checked }));
          },
        }}
      />
    </div>
  );
};

export { ReferralTable };
