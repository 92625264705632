import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { ResourceList } from './components/resource-list';
import { useParams, matchPath, useLocation, Route, useRouteMatch } from 'react-router-dom';
import { Typography, styled, useTheme } from '@material-ui/core';
import {
  Results,
  minWidthMediaQueries,
  ElementProps,
  RequestWrapper,
  RequestWrapperState,
} from '@axial-healthcare/axial-react';
import { SubNav } from 'src/components/sub-nav';
import { practiceApi } from 'src/constants/api';
import { moduleRoutes } from '../constants';

interface Category {
  id?: number;
  name: string;
  resource_count: number;
}

interface ResourceLibraryRouteParams {
  categoryId: string;
}

const ResourceLibraryLandingView: React.FC = (): React.ReactElement => {
  const { categoryId } = useParams<ResourceLibraryRouteParams>();
  const { path } = useRouteMatch();
  const { pathname: currentPath } = useLocation();
  const { palette } = useTheme();
  const resourcesPath: string = moduleRoutes.resourceLibrary;

  const [currentCategory, setCurrentCategory] = useState<Category>();

  return (
    <Route path={`${path}/:categoryId?`}>
      <div className="resourceLibrary">
        <Typography variant="h1" style={{ letterSpacing: '-0.8px', marginBottom: '0.8em' }}>
          Resource Library
        </Typography>
        <RequestWrapper<Category[]>
          requestConfig={{
            request: ({ cancelToken, isUnmounted }): Promise<Category[]> => {
              return practiceApi
                .get<Results<Category>>('/resource-library/categories/', { cancelToken })
                .then((response: AxiosResponse<Results<Category>>) => {
                  const categories = [
                    { id: undefined, name: 'All Resources', resource_count: 0 },
                    ...response.data.results,
                  ];
                  if (!isUnmounted) {
                    setCurrentCategory(
                      categories.find((cat: Category) => cat.id?.toString() === categoryId) || categories[0]
                    );
                  }
                  return categories;
                });
            },
          }}
        >
          {({ data: categories }: RequestWrapperState<Category[]>) => {
            return (
              <ResourceLibraryContent className="resourceLibraryContent">
                <div
                  className="resourceLibraryCategories"
                  style={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    marginRight: '40px',
                    minWidth: '200px',
                  }}
                >
                  <Typography variant="h2">Categories</Typography>
                  <SubNav
                    layout="vertical"
                    ListProps={{ className: 'resourceLibraryCategoriesList', style: { padding: 0 } }}
                    items={categories.map((item: Category) => {
                      const to = `${resourcesPath}/${item.id || ''}`;
                      const isActive = !!matchPath(currentPath, { path: to, exact: true });
                      return {
                        exact: true,
                        to,
                        children: item.name,
                        style: {
                          marginBottom: 8,
                          ...(isActive ? {} : { borderBottomColor: palette.divider }),
                        },
                        onClick: (): void => setCurrentCategory(item),
                      };
                    })}
                  />
                </div>
                <ResourceList
                  category={currentCategory?.id?.toString() || ''}
                  categoryName={currentCategory?.name || ''}
                />
              </ResourceLibraryContent>
            );
          }}
        </RequestWrapper>
      </div>
    </Route>
  );
};

const ResourceLibraryContent: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  [minWidthMediaQueries.tablet]: {
    flexFlow: 'row',
  },
});

export { ResourceLibraryLandingView };
