import { useLocation, RouteProps } from 'react-router-dom';
import { Location } from 'history';
import { AxialIcon } from '@axial-healthcare/axial-react';
import { PracticeFeatures } from 'src/constants/environment';
import { LoggedOutView } from 'src/views/logout';
import { ContactUsView } from 'src/views/contact-us';
import { ServerErrorView } from 'src/views/server-error';
import { LandingView } from 'src/views/landing';
import { OpioidReductionView } from './opioid-reduction';
import { PatientIntelligenceView } from './patient-intelligence';
import { ProviderDashboardView } from './provider-dashboard';
import { ProviderStratificationView } from './provider-stratification';
import { ResourceLibraryLandingView } from './resource-library';
import { RecoveryDashboard } from './recovery-dashboard';

enum ModuleTypes {
  providerDashboard = 'providerDashboard',
  recoveryDashboard = 'recoveryDashboard',
  patientIntelligence = 'patientIntelligence',
  providerStrat = 'providerStrat',
  opioidReduction = 'opioidReduction',
  resourceLibrary = 'resourceLibrary',
}

enum ApiModuleTypes {
  patientIntelligence = 'patient-intelligence',
  providerStrat = 'provider-strat',
  providerDashboard = 'provider-dashboard',
  recoveryDashboard = 'recovery-dashboard',
}
const apiModuleMap: {
  [key in ApiModuleTypes]: { configKey: ModuleTypes; features?: { [key: string]: PracticeFeatures } };
} = {
  [ApiModuleTypes.patientIntelligence]: {
    configKey: ModuleTypes.patientIntelligence,
    features: {},
  },
  [ApiModuleTypes.providerStrat]: {
    configKey: ModuleTypes.providerStrat,
    features: {
      'moving-average': PracticeFeatures.providerStratMovingAverage,
    },
  },
  [ApiModuleTypes.providerDashboard]: { configKey: ModuleTypes.providerDashboard },
  [ApiModuleTypes.recoveryDashboard]: {
    configKey: ModuleTypes.recoveryDashboard,
    features: {},
  },
};

enum ModuleCategories {
  reports = 'Reports',
  supportTools = 'Support Tools',
  additionalContent = 'Additional Content',
}
export interface ModuleCategoryConfig {
  title: string;
  order: number;
}
const moduleCategoryConfigs: { [key in ModuleCategories]: ModuleCategoryConfig } = {
  [ModuleCategories.reports]: { title: ModuleCategories.reports, order: 0 },
  [ModuleCategories.supportTools]: { title: ModuleCategories.supportTools, order: 1 },
  [ModuleCategories.additionalContent]: { title: ModuleCategories.additionalContent, order: 2 },
};

export interface ModuleNavDisplay {
  name: string;
  icon: string;
  category: ModuleCategories;
  order: number;
}
export interface ModuleConfig {
  key: ModuleTypes;
  route?: RouteProps;
  external?: string;
  navDisplay: ModuleNavDisplay;
  requiresAuth?: boolean;
  requiresAttestation?: boolean;
  isProviderContext?: boolean;
  featureFlag?: number;
}

const moduleRoutes: { [key in ModuleTypes]: string } = {
  providerDashboard: '/dashboard',
  recoveryDashboard: '/recovery-solutions',
  patientIntelligence: '/patient-intelligence',
  providerStrat: '/rim-report',
  opioidReduction: '/opioid-reduction',
  resourceLibrary: '/resource-library',
};
const appModules: { [key in ModuleTypes]: ModuleConfig } = {
  /**
   * REPORTS
   */
  [ModuleTypes.providerDashboard]: {
    key: ModuleTypes.providerDashboard,
    navDisplay: {
      name: 'Dashboard',
      icon: AxialIcon.home,
      order: 0,
      category: ModuleCategories.reports,
    },
    requiresAuth: true,
    requiresAttestation: true,
    isProviderContext: true,
    route: { path: moduleRoutes.providerDashboard, component: ProviderDashboardView, exact: true },
  },
  [ModuleTypes.recoveryDashboard]: {
    key: ModuleTypes.recoveryDashboard,
    navDisplay: {
      name: 'Recovery Solutions',
      icon: AxialIcon.heart,
      order: 1,
      category: ModuleCategories.reports,
    },
    requiresAuth: true,
    requiresAttestation: true,
    isProviderContext: true,
    route: { path: moduleRoutes.recoveryDashboard, component: RecoveryDashboard, exact: true },
  },
  [ModuleTypes.patientIntelligence]: {
    key: ModuleTypes.patientIntelligence,
    navDisplay: {
      name: 'Patient Intelligence',
      icon: AxialIcon.lightBulb,
      order: 2,
      category: ModuleCategories.reports,
    },
    route: {
      path: moduleRoutes.patientIntelligence,
      component: PatientIntelligenceView,
    },
    requiresAuth: true,
    requiresAttestation: true,
    isProviderContext: true,
  },
  [ModuleTypes.providerStrat]: {
    key: ModuleTypes.providerStrat,
    navDisplay: { name: 'RIM Report', icon: AxialIcon.hexagon, order: 3, category: ModuleCategories.reports },
    route: { path: moduleRoutes.providerStrat, exact: true, component: ProviderStratificationView },
    requiresAuth: true,
    requiresAttestation: true,
    isProviderContext: true,
  },
  /**
   * SUPPORT TOOLS
   */
  [ModuleTypes.opioidReduction]: {
    key: ModuleTypes.opioidReduction,
    navDisplay: {
      name: 'Opioid Reduction',
      icon: AxialIcon.chartDown,
      order: 0,
      category: ModuleCategories.supportTools,
    },
    route: { path: moduleRoutes.opioidReduction, component: OpioidReductionView },
  },
  /**
   * ADDITIONAL CONTENT
   */
  [ModuleTypes.resourceLibrary]: {
    key: ModuleTypes.resourceLibrary,
    navDisplay: {
      name: 'Resource Library',
      icon: AxialIcon.book,
      order: 0,
      category: ModuleCategories.additionalContent,
    },
    route: { path: moduleRoutes.resourceLibrary, component: ResourceLibraryLandingView },
  },
};

enum StaticRoutes {
  root = '/',
  logout = '/logout',
  contactUs = '/contact-us',
  error = '/error',
}
const staticRouteConfigs: RouteProps[] = [
  { path: StaticRoutes.logout, exact: true, component: LoggedOutView },
  { path: StaticRoutes.contactUs, exact: true, component: ContactUsView },
  { path: StaticRoutes.error, exact: true, component: ServerErrorView },
  { path: StaticRoutes.root, exact: true, component: LandingView },
];

interface CurrentModuleResult {
  currentModule: ModuleConfig | undefined;
  currentRouteHasModule: boolean;
}
const useCurrentModule = (): CurrentModuleResult => {
  const location: Location = useLocation();
  const pathParts: string[] = location.pathname.split('/');
  const modules: ModuleConfig[] = Object.keys(appModules).map((key: string) => appModules[key as ModuleTypes]);

  const moduleForPath: ModuleConfig | undefined =
    pathParts.length > 1
      ? modules.find(
          (module: ModuleConfig) =>
            typeof module.route?.path === 'string' && module.route.path.replace('/', '') === pathParts[1]
        )
      : undefined;

  return { currentModule: moduleForPath, currentRouteHasModule: !!moduleForPath };
};

export {
  appModules,
  ModuleTypes,
  ModuleCategories,
  moduleCategoryConfigs,
  useCurrentModule,
  staticRouteConfigs,
  StaticRoutes,
  moduleRoutes,
  apiModuleMap,
  ApiModuleTypes,
};
