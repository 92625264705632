import React from 'react';
import { SelectOption } from '@axial-healthcare/axial-react';
import { UserClient, UserSwitchPostBody } from '../../user-provider/interface';
import { ContextSelect } from '../index';

interface ClientSelectProps {
  clients: UserClient[];
  updateContext: (body: UserSwitchPostBody) => void;
  currentClient?: UserClient;
}
const ClientSelect: React.FC<ClientSelectProps> = ({
  clients,
  currentClient,
  updateContext,
}: ClientSelectProps): React.ReactElement => {
  const clientOptions: SelectOption[] = clients.map((client: UserClient) => ({
    value: client.slug,
    label: client.logo_url,
  }));
  const clientValue: string = currentClient ? currentClient.slug : '';

  return (
    <ContextSelect
      contextType="client"
      updateContext={updateContext}
      options={clientOptions}
      value={clientValue}
      OptionComponent={ClientOption}
    />
  );
};

const ClientOption: React.FC<SelectOption> = ({ value, label, idAttribute }: SelectOption): React.ReactElement => {
  return (
    <div id={idAttribute} style={{ overflow: 'hidden' }}>
      <img
        id={idAttribute}
        style={{ height: 22, display: 'block', margin: '0 auto' }}
        src={label}
        alt={`${value}-client-logo`}
      />
    </div>
  );
};

export { ClientSelect };
