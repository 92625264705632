import React from 'react';
import { Typography, styled, RadioGroup } from '@material-ui/core';
import {
  TextField,
  DateField,
  Radio,
  Button,
  Checkbox,
  axialColors,
  AxialIcon,
  ElementProps,
} from '@axial-healthcare/axial-react';
import { TextBlock } from 'src/components/text-block';
import { InputValues } from './index';

interface ReportFormProps {
  inputValues: InputValues;
  handleStateChange: (attribute: string) => (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange: (date: Date | null) => void;
  togglePatientFAQs: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const ReportForm: React.FC<ReportFormProps> = ({
  inputValues,
  handleStateChange,
  handleDateChange,
  togglePatientFAQs,
  className,
}: ReportFormProps): React.ReactElement => {
  const handlePrint = (): void => {
    window.print();
  };

  return (
    <div className={className}>
      <Typography variant="h3" color="primary">
        Your report
      </Typography>
      <TextBlock>
        Completing the fields linked below will populate the information on the printed opioid reduction care plan shown
        on the left. Note, this information is not retained.
      </TextBlock>
      <FormContainer>
        <TextField
          label="Patient Name"
          fullWidth={true}
          value={inputValues.patientName}
          onChange={handleStateChange('patientName')}
          variant="outlined"
        />
        <DateField
          label="Patient DOB"
          fullWidth={true}
          value={inputValues.patientDOB}
          onChange={handleDateChange}
          inputVariant="outlined"
        />
        <div>
          <div style={{ fontSize: '0.7rem', color: axialColors.gray75, marginBottom: 4 }}>Opioid Reduction Goal</div>
          <RadioGroup value={inputValues.reduceOff} onChange={handleStateChange('reduceOff')}>
            <Radio
              label="Reduce"
              FormControlLabelProps={{ style: { marginBottom: 0, fontSize: '0.9rem' } }}
              value={'reduce'}
            />
            <Radio
              label="Reduce Off"
              FormControlLabelProps={{ style: { marginBottom: 0, fontSize: '0.9rem' } }}
              value={'off'}
            />
          </RadioGroup>
        </div>
        <TextField
          label="Starting MEDD"
          fullWidth={true}
          value={inputValues.startingMEDD}
          onChange={handleStateChange('startingMEDD')}
          variant="outlined"
          type="number"
        />
        <TextField
          label="MEDD Target"
          fullWidth={true}
          value={inputValues.targetMEDD}
          onChange={handleStateChange('targetMEDD')}
          variant="outlined"
          type="number"
          disabled={inputValues.reduceOff === 'off'}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 0, marginTop: 20, display: 'flex' }}
          startIcon={<i className={AxialIcon.printer} />}
          onClick={handlePrint}
        >
          Print this report
        </Button>
        <Checkbox
          checked={inputValues.includeFAQs}
          label="Include Patient FAQs"
          CheckboxProps={{ onChange: togglePatientFAQs }}
        />
      </FormContainer>
    </div>
  );
};

const FormContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  '& > *': { marginBottom: 10 },
});

export { ReportForm };
