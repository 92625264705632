import React from 'react';
import { styled } from '@material-ui/core';
import { axialColors, ElementProps } from '@axial-healthcare/axial-react';

interface ScoreBarProps {
  score: number;
  scoreMax: number;
  color?: string;
  borderColor?: string;
  height?: number;
  idAttribute?: string;
}

const ScoreBar: React.FC<ScoreBarProps> = (props: ScoreBarWrapperProps): React.ReactElement => {
  const { idAttribute } = props;
  return (
    <ScoreBarWrapper id={idAttribute} {...props}>
      <div id={idAttribute} className="bar-empty" />
      <div id={idAttribute} className="bar-filled-container">
        <div id={idAttribute} className="bar-filled" />
      </div>
    </ScoreBarWrapper>
  );
};
interface ScoreBarWrapperProps extends ElementProps<HTMLDivElement>, ScoreBarProps {}
const ScoreBarWrapper: React.ComponentType<ScoreBarWrapperProps> = styled(
  ({ score, scoreMax, color, borderColor, height, idAttribute, ...restProps }: ScoreBarWrapperProps) => (
    <div {...restProps} />
  )
)(({ score, scoreMax, color, borderColor, height = 0 }: ScoreBarWrapperProps) => {
  const borderRadius = 20;
  const barFilledWidth: number = (score * 100) / scoreMax;
  return {
    height,
    position: 'relative',
    '& > .bar-empty': {
      height: '100%',
      backgroundColor: '#fff',
      width: '100%',
      borderRadius,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: borderColor || axialColors.gray25,
    },
    '& > .bar-filled-container': {
      height: '100%',
      width: `${barFilledWidth}%`,
      borderRadius,
      ...(barFilledWidth !== 100 ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}),
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '& > .bar-filled-container > .bar-filled': {
      height: '100%',
      borderTopWidth: height,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderStyle: 'solid',
      borderColor: color,
    },
  };
});

export { ScoreBar };
