import React from 'react';
import { Switch, useRouteMatch, Route, NavLinkProps, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
  AxialIcon,
  useMobileLayoutStyles,
  usePrintStyles,
  mergeCssClasses,
  RequestWrapper,
  RequestWrapperState,
} from '@axial-healthcare/axial-react';
import { SubNavIconItem, SubNavDropDown, SubNav } from 'src/components/sub-nav';
import { MessageCard } from 'src/components/message-card';
import { practiceApi } from 'src/constants/api';
import { moduleRoutes } from 'src/modules/constants';
import { PageNotFoundView } from 'src/views/page-not-found';
import { PatientDetailRouteParams } from '../../index';
import { MemberDetail } from '../../interfaces';
import { PatientDetailHeader } from './components/header';
import { PatientDashboardView } from './views/dashboard';
import { PatientDiagnosesView } from './views/diagnoses';
import { OpioidRiskReportView } from './views/opioid-risk-report';
import { PatientPrescriptionsView } from './views/prescriptions';
import { PatientNotificationsView } from './views/notifications';

const PatientDetailView: React.FC = (): React.ReactElement => {
  const { memberId } = useParams<PatientDetailRouteParams>();
  const { path, url } = useRouteMatch();
  const { noDesktop, noMobile } = useMobileLayoutStyles();
  const { noPrint } = usePrintStyles();

  if (!memberId) {
    return (
      <MessageCard
        message="We couldn't load the patient details. Please try again."
        buttons={[
          {
            internalLink: { to: moduleRoutes.patientIntelligence },
            children: 'Back to search',
            startIcon: <i className={AxialIcon.leftCircled1} />,
          },
        ]}
      />
    );
  }

  return (
    <RequestWrapper<MemberDetail>
      LoadingProps={{ fullPage: true }}
      requestConfig={{
        request: ({ cancelToken }): Promise<AxiosResponse<MemberDetail>> => {
          return practiceApi.get<MemberDetail>(`/members/${memberId}/`, { cancelToken });
        },
        dependencies: [memberId],
        condition: (): boolean => !!memberId,
      }}
    >
      {({ data: member }: RequestWrapperState<MemberDetail>) => {
        const navLinks: NavLinkProps[] = [
          {
            exact: true,
            to: { pathname: url },
            children: <SubNavIconItem icon={AxialIcon.home} text="Dashboard" />,
          },
          {
            exact: true,
            to: { pathname: `${url}/notifications` },
            children: <SubNavIconItem icon={AxialIcon.lightBulb} text="Notifications" />,
          },
          {
            exact: true,
            to: { pathname: `${url}/diagnoses` },
            children: <SubNavIconItem icon={AxialIcon.stethoscope} text="Diagnoses" />,
          },
          {
            exact: true,
            to: { pathname: `${url}/prescriptions` },
            children: <SubNavIconItem icon={AxialIcon.medication} text="Prescriptions" />,
          },
          {
            to: { pathname: `${url}/orr` },
            children: <SubNavIconItem icon={AxialIcon.fileText} text="Opioid Risk Report" />,
          },
        ];

        return (
          <>
            {member ? (
              <>
                <PatientDetailHeader member={member} style={{ marginBottom: 15 }} />
                <SubNav
                  ListProps={{ className: mergeCssClasses(noMobile, noPrint), style: { marginBottom: 30 } }}
                  items={navLinks}
                />
                <SubNavDropDown
                  className={mergeCssClasses(noDesktop, noPrint)}
                  style={{ marginBottom: 30 }}
                  items={navLinks}
                  MenuProps={{ className: noDesktop }}
                />
              </>
            ) : null}

            <Switch>
              <Route exact={true} path={path}>
                <PatientDashboardView memberId={memberId} />
              </Route>
              <Route exact={true} path={`${path}/notifications`}>
                <PatientNotificationsView memberId={memberId} />
              </Route>
              <Route exact={true} path={`${path}/diagnoses`}>
                <PatientDiagnosesView memberId={memberId} />
              </Route>
              <Route exact={true} path={`${path}/prescriptions`}>
                <PatientPrescriptionsView memberId={memberId} />
              </Route>
              <Route path={`${path}/orr`}>
                <OpioidRiskReportView memberId={memberId} />
              </Route>
              <Route path="/*" component={PageNotFoundView} />
            </Switch>
          </>
        );
      }}
    </RequestWrapper>
  );
};

export { PatientDetailView };
