import React from 'react';
import { NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';
import { styled, CardContent, Typography, ButtonBase, useTheme } from '@material-ui/core';
import { axialColors, AxialIcon, ElementProps, Card, RequestWrapperTableProps } from '@axial-healthcare/axial-react';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import { OrrDownloadButton } from '../../../components/download-button';
import { ListCard } from '../components/list-card';
import { DiagnosisTable } from '../components/diagnosis-table';
import { PrescriptionTable } from '../components/prescription-table';
import { NotificationTable } from '../components/notification-table';

const DASHBOARD_DATA_LIMIT = 5;

export interface PatientDetailTableProps<ItemType> {
  TableProps: Partial<RequestWrapperTableProps<ItemType>['TableProps']>;
  memberId: string;
  limit?: number;
  resultsPerPage?: number;
}

interface PatientDashboardViewProps {
  memberId: string;
}
const PatientDashboardView: React.FC<PatientDashboardViewProps> = ({
  memberId,
}: PatientDashboardViewProps): React.ReactElement => {
  const { palette } = useTheme();
  const noResultsStyles: React.CSSProperties = {
    border: 0,
    boxShadow: 'none',
    borderBottom: `1px solid ${palette.divider}`,
    borderRadius: 0,
  };
  const scrollMessageStyles: React.CSSProperties = {
    padding: 10,
    fontSize: '0.9rem',
    color: axialColors.gray75,
    textAlign: 'center',
  };

  return (
    <TwoColumnLayout row={{ flexGrow: { columnOne: 3, columnTwo: 1 } }}>
      <DashboardContentContainer>
        <Card>
          <DashboardSectionHeader title="Recent Notifications" />
          <NotificationTable
            memberId={memberId}
            limit={DASHBOARD_DATA_LIMIT}
            TableProps={{
              NoResultsProps: { resultsName: 'recent notifications', style: noResultsStyles },
              ScrollMessage: { style: scrollMessageStyles },
            }}
          />
          <DashboardSectionFooter to="/notifications" children="View more notification history" />
        </Card>
        <Card>
          <DashboardSectionHeader title="Recent Diagnoses" />
          <DiagnosisTable
            memberId={memberId}
            limit={DASHBOARD_DATA_LIMIT}
            TableProps={{
              NoResultsProps: { resultsName: 'recent diagnoses', style: noResultsStyles },
              ScrollMessage: { style: scrollMessageStyles },
            }}
          />
          <DashboardSectionFooter to="/diagnoses" children="View more diagnosis history" />
        </Card>
        <Card>
          <DashboardSectionHeader title="Recent Prescriptions" />
          <PrescriptionTable
            memberId={memberId}
            limit={DASHBOARD_DATA_LIMIT}
            TableProps={{
              NoResultsProps: { resultsName: 'recent prescriptions', style: noResultsStyles },
              ScrollMessage: { style: scrollMessageStyles },
            }}
          />
          <DashboardSectionFooter to="/prescriptions" children="View more prescription history" />
        </Card>
      </DashboardContentContainer>
      <ListCard
        title="Patient Tailored Reports"
        items={[
          {
            text: 'Opioid Risk Report',
            subtext: 'PDF Report',
            icon: AxialIcon.filePdf,
            action: memberId ? (
              <OrrDownloadButton
                memberId={memberId}
                idAttribute="detail-orr-download"
                iconOnly={true}
                iconButtonProps={{ size: 'small' }}
              />
            ) : undefined,
          },
        ]}
      />
    </TwoColumnLayout>
  );
};

interface DashboardSectionHeaderProps {
  title: string;
}
const DashboardSectionHeader: React.FC<DashboardSectionHeaderProps> = ({
  title,
}: DashboardSectionHeaderProps): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <CardContent
      style={{
        padding: 16,
        borderBottom: `1px solid ${palette.divider}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Typography variant="h3" style={{ margin: 0 }}>
        {title}
      </Typography>
      <span style={{ fontSize: '0.75rem', color: axialColors.gray50 }}>
        Within The Last 12 Months, Limited To The Last 5
      </span>
    </CardContent>
  );
};

const DashboardSectionFooter: React.FC<NavLinkProps> = ({
  to,
  children,
  ...restNavLinkProps
}: NavLinkProps): React.ReactElement => {
  const { url } = useRouteMatch();

  return (
    <FooterContainer>
      <NavLink to={{ pathname: `${url}${to}` }} {...restNavLinkProps}>
        <ButtonBase className="link-button">
          <i className={AxialIcon.rightCircled1} />
          {children}
        </ButtonBase>
      </NavLink>
    </FooterContainer>
  );
};

const FooterContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderTop: `1px solid ${theme.palette.divider}`,
  '& > a': {
    display: 'block',
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    color: axialColors.gray50,
  },
  '& > a > .link-button': {
    height: '100%',
    width: '100%',
    display: 'block',
    padding: 12,
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.8rem',
  },
  '& > a > .link-button > i': { marginRight: 5 },
}));

const DashboardContentContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  '& > * + *': {
    marginTop: 10,
  },
});

export { PatientDashboardView };
