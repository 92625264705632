import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  isValidDate,
  maxWidthMediaQueries,
  minWidthMediaQueries,
  MuiFormik,
  phoneMask,
} from '@axial-healthcare/axial-react';
import { makeStyles } from '@material-ui/core';
import { ReferralPostErrors } from './index';

export interface ReferralFormValues {
  patientFirstName: string;
  patientLastName: string;
  patientDob: string;
  patientSubscriberId: string;
  patientPhone: string;
  referrerFirstName: string;
  referrerLastName: string;
  referrerPhone: string;
  referrerEmail: string;
  notes: string;
}
interface ReferralFormProps {
  initialValues?: ReferralFormValues;
  serverErrors?: ReferralPostErrors;
  onSubmit: (values: ReferralFormValues) => void;
  innerRef?: React.RefObject<FormikProps<ReferralFormValues>>;
}
const ReferralForm: React.FC<ReferralFormProps> = ({
  initialValues,
  serverErrors,
  onSubmit,
  innerRef,
}: ReferralFormProps): React.ReactElement => {
  const { fieldRow } = useStyles();

  useEffect(() => {
    innerRef?.current?.validateForm();
  }, [serverErrors, innerRef]);

  return (
    <MuiFormik<ReferralFormValues>
      innerRef={innerRef}
      formElementProps={{ 'aria-label': 'New Referral Form' }}
      noSubmitButton={true}
      onSubmit={onSubmit}
      initialTouched={{ referrerEmail: !!serverErrors?.referrer?.email }}
      initialValues={
        initialValues || {
          patientFirstName: '',
          patientLastName: '',
          patientDob: '',
          patientSubscriberId: '',
          patientPhone: '',
          referrerFirstName: '',
          referrerLastName: '',
          referrerPhone: '',
          referrerEmail: '',
          notes: '',
        }
      }
      validationSchema={Yup.object().shape({
        patientFirstName: Yup.string().required('Patient first name is required'),
        patientLastName: Yup.string().required('Patient last name is required'),
        patientSubscriberId: Yup.string()
          .required('Subscriber ID is required')
          .matches(/^[a-zA-Z0-9]*$/, 'Subscriber ID can only contain alphanumeric characters'),
        patientDob: Yup.string().required('Date of Birth is required').test('is-valid', 'Invalid date', isValidDate()),
        patientPhone: Yup.string().length(14, 'Please enter a full phone number with area code'),
        referrerFirstName: Yup.string().required('Referrer first name is required'),
        referrerLastName: Yup.string().required('Referrer last name is required'),
        referrerPhone: Yup.string()
          .length(14, 'Please enter a full phone number with area code')
          .required('Referrer phone number is required'),
        referrerEmail: Yup.string()
          .email('Invalid email address')
          .test(
            'server-validation',
            serverErrors?.referrer?.email?.join(' ') || '',
            (value: string | null | undefined) => {
              if (!value) {
                return true;
              }
              const hasServerError = !!serverErrors?.referrer?.email;
              if (hasServerError && value === initialValues?.referrerEmail) {
                return false;
              }
              return true;
            }
          )
          .required('Referrer email is required'),
      })}
      fields={[
        {
          label: 'Patient Information',
          key: 'patient-name',
          fields: [
            { type: 'text', name: 'patientFirstName', TextFieldProps: { label: 'First Name', fullWidth: true } },
            { type: 'text', name: 'patientLastName', TextFieldProps: { label: 'Last Name', fullWidth: true } },
          ],
        },
        {
          key: 'patient-dob-and-id',
          className: fieldRow,
          fields: [
            { type: 'date', name: 'patientDob', DateFieldProps: { label: 'Date of Birth' } },
            { type: 'text', name: 'patientSubscriberId', TextFieldProps: { label: 'Subscriber ID' } },
          ],
        },
        {
          key: 'patient-phone-number',
          fields: [
            {
              type: 'text',
              name: 'patientPhone',
              TextFieldProps: {
                label: 'Phone Number (if available)',
                maskProps: {
                  mask: phoneMask,
                },
                style: { width: '225px' },
              },
            },
          ],
        },
        {
          label: 'Contact Information',
          key: 'referrer-name',
          description:
            'Please include contact information for the referring party. ' +
            'We may need to contact the referrer to gather additional information about the patient',
          fields: [
            { type: 'text', name: 'referrerFirstName', TextFieldProps: { label: 'First Name', fullWidth: true } },
            { type: 'text', name: 'referrerLastName', TextFieldProps: { label: 'Last Name', fullWidth: true } },
          ],
        },
        {
          key: 'referrer-phone-and-email',
          className: fieldRow,
          fields: [
            {
              type: 'text',
              name: 'referrerPhone',
              TextFieldProps: {
                label: 'Phone Number',
                maskProps: {
                  mask: phoneMask,
                },
              },
            },
            { type: 'text', name: 'referrerEmail', TextFieldProps: { label: 'Email' } },
          ],
        },
        {
          label: 'Referral Information',
          key: 'referral-info',
          fields: [
            {
              type: 'text',
              name: 'notes',
              TextFieldProps: { label: 'Notes', multiline: true, rows: 4, rowsMax: 4, fullWidth: true },
            },
          ],
        },
      ]}
    />
  );
};

const useStyles: () => Record<'fieldRow', string> = makeStyles({
  fieldRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    [maxWidthMediaQueries.portraitPhone]: {
      flexDirection: 'column',
      '& > *': { width: '100%' },
    },
    [minWidthMediaQueries.landscapePhone]: {
      flexDirection: 'row',
      '& > *': { flexBasis: 0 },
      '& > :first-child': { marginRight: 10, flexGrow: 1 },
      '& > :last-child': { display: 'flex', flexGrow: 1.5 },
    },
  },
});

export { ReferralForm };
