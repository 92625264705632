import React from 'react';
import { Card, Typography, useTheme } from '@material-ui/core';
import { usePrintStyles, Accordion, axialColors, formatPhoneNumber } from '@axial-healthcare/axial-react';
import { useUser } from 'src/components/user-provider';
import { wayspringColors } from '..';

interface FaqSection {
  title: string;
  text: React.ReactElement | string;
}

const RecoveryFaqs: React.FC = (): React.ReactElement => {
  const { user } = useUser();
  const { palette } = useTheme();
  const { noBorder, noPadding } = usePrintStyles();
  const faqSections: FaqSection[] = [
    {
      title: 'Who is eligible?',
      text: `Patients covered by ${user.client?.name} have free access to our Recovery Solution.`,
    },
    {
      title: 'What happens after referring?',
      text: 'In order to develop a holistic approach to address your patients’ needs, we will work with you to ensure we have the necessary clinical documentation, and any other relevant information, to complete a thorough assessment of each patient. Our team will reach out to you to discuss how we can work together to help each referred patient.',
    },
    ...(user.client?.market?.liaison_name
      ? [
          {
            title: 'Who should I contact if I have questions?',
            text: (
              <>
                Reach out to your Provider Relations Specialist with any questions or concerns about the referral
                process.
                <Typography variant="h4" style={{ color: axialColors.gray75, margin: '6px 0 4px' }}>
                  {user.client?.market?.liaison_name}
                </Typography>
                <a
                  style={{ color: wayspringColors.green, display: 'block' }}
                  href={`mailto:${user.client?.market?.liaison_email}`}
                >
                  {user.client?.market?.liaison_email}
                </a>
                <a
                  style={{ color: wayspringColors.green, display: 'block' }}
                  href={`tel:${user.client?.market?.liaison_phone_number}`}
                >
                  {formatPhoneNumber(user.client?.market?.liaison_phone_number)}
                </a>
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <Card className={noBorder}>
      <Typography
        className={noPadding}
        variant="h3"
        style={{ padding: '14px 18px', borderBottom: `1px solid ${palette.divider}` }}
      >
        Frequently Asked Questions
      </Typography>
      <Accordion
        sections={faqSections.map((faq: FaqSection, index: number) => ({
          key: faq.title,
          style: { pageBreakInside: 'avoid' },
          title: faq.title,
          children: faq.text,
          idAttribute: `faq-trigger-${index + 1}`,
        }))}
      />
    </Card>
  );
};

export { RecoveryFaqs };
