import React from 'react';
import { RequestWrapperTable, TableRowConfig, axialColors, formatDateString } from '@axial-healthcare/axial-react';
import { Theme, useTheme } from '@material-ui/core';
import { practiceApi } from 'src/constants/api';
import { DISPLAY_DATE_FORMAT, API_DATE_FORMAT } from 'src/constants/api';
import { MemberDiagnosis } from '../../../interfaces';
import { PatientDetailTableProps } from '../views/dashboard';

const DiagnosisTable: React.FC<PatientDetailTableProps<MemberDiagnosis>> = ({
  memberId,
  limit,
  resultsPerPage,
  TableProps: tableProps,
}: PatientDetailTableProps<MemberDiagnosis>): React.ReactElement => {
  const theme = useTheme();
  return (
    <>
      <RequestWrapperTable<MemberDiagnosis>
        TableProps={{
          ...tableProps,
          columns: [
            { key: 'date', value: 'Date' },
            { key: 'description', value: 'Description' },
            { key: 'code', value: 'ICD Code' },
            ...(tableProps?.columns || []),
          ],
          mapDataToRow: mapDiagnosisToRow(theme),
        }}
        axiosRequestConfig={{
          request: {
            api: practiceApi,
            url: `/members/${memberId}/diagnosis/`,
            ...(limit ? { params: { limit } } : {}),
          },
          paginationOptions: resultsPerPage ? { resultsPerPage } : undefined,
        }}
      />
    </>
  );
};

const mapDiagnosisToRow =
  (theme: Theme) =>
  (diagnosis: MemberDiagnosis): TableRowConfig => ({
    key: diagnosis.member_diagnosis_key,
    cells: [
      {
        key: 'date',
        style: { color: axialColors.gray50 },
        value: formatDateString(diagnosis.service_from_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT),
      },
      {
        key: 'description',
        style: { fontWeight: theme.typography.fontWeightBold },
        value: diagnosis.diagnosis_description,
      },
      { key: 'code', style: { color: axialColors.gray50 }, value: diagnosis.icd_unformatted_code },
    ],
  });

export { DiagnosisTable };
