import React from 'react';
import { MessageCard } from 'src/components/message-card';
import { messages } from 'src/constants/messages';
import { useCurrentModule } from 'src/modules/constants';

const PageNotFoundView: React.FC = (): React.ReactElement => {
  const { currentRouteHasModule } = useCurrentModule();
  return <MessageCard message={currentRouteHasModule ? messages.invalidModule : messages.pageNotFound} />;
};

export { PageNotFoundView };
