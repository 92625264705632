import React from 'react';
import { BrowserRouter as Router, useLocation, Redirect, Switch, Route, RouteProps } from 'react-router-dom';
import { ThemeProvider, CssBaseline, makeStyles, createStyles, InputBase } from '@material-ui/core';
import {
  AppShell,
  Button,
  NavSection,
  NavSectionItem,
  Loading,
  useMobileLayoutStyles,
  mergeCssClasses,
  axialColors,
  axialTheme,
  FeatureFlagProvider,
} from '@axial-healthcare/axial-react';
import { Location } from 'history';
import { ContactUsButton } from './components/contact-us-button';
import { ProviderSelect } from './components/context-select/provider-select';
import { ModuleViewWrapper } from './components/module-view-wrapper';
import { UserProvider, useUser } from './components/user-provider';
import { UserState } from './components/user-provider/interface';
import { UserSettings } from './components/user-settings';
import { environment } from './constants/environment';
import {
  ModuleConfig,
  moduleCategoryConfigs,
  useCurrentModule,
  StaticRoutes,
  staticRouteConfigs,
  ModuleCategories,
  appModules,
  ModuleTypes,
} from './modules/constants';
import { PageNotFoundView } from './views/page-not-found';

const App: React.FC = (): React.ReactElement => {
  return (
    <Router>
      <FeatureFlagProvider features={environment.featureList}>
        <UserProvider>
          <ThemeProvider theme={axialTheme}>
            <CssBaseline />
            <AppContent />
          </ThemeProvider>
        </UserProvider>
      </FeatureFlagProvider>
    </Router>
  );
};

const AppContent: React.FC = (): React.ReactElement => {
  const { noMobile } = useMobileLayoutStyles();
  const { providerSelectFormControl, providerSelectIcon, providerSelect } = useStyles();
  const userState: UserState = useUser();
  const { user, isUserLoggedIn, isLoading, showLoading, modules, login, logout, updateContext } = userState;

  const routes: RouteProps[] = [
    ...modules.reduce((accumulatedRoutes: RouteProps[], currentModule: ModuleConfig) => {
      if (!currentModule.route) {
        return accumulatedRoutes;
      }
      return [...accumulatedRoutes, currentModule.route];
    }, []),
    ...staticRouteConfigs,
  ];

  const navSections: NavSection[] = buildNavSections({
    modules,
    additionalContent: {
      [ModuleCategories.reports]: isUserLoggedIn ? (
        <ProviderSelect
          updateContext={updateContext}
          providers={user.providers || []}
          currentProvider={user.provider}
          selectProps={{
            input: <InputBase classes={{ input: providerSelect }} />,
            classes: { iconOutlined: providerSelectIcon },
            formControlProps: { classes: { root: mergeCssClasses(providerSelectFormControl, noMobile) } },
          }}
        />
      ) : null,
    },
  });

  return (
    <>
      <InvalidRouteCheck isUserLoggedIn={isUserLoggedIn} isLoading={isLoading} />
      <DefaultRedirect isUserLoggedIn={isUserLoggedIn} isLoading={isLoading} navSections={navSections} />
      <AppShell
        appBar={{
          additionalContent: isUserLoggedIn ? (
            <UserSettings updateContext={updateContext} user={user} logout={logout} />
          ) : !isLoading ? (
            <Button id="login-button" color="primary" variant="contained" onClick={login}>
              Log in
            </Button>
          ) : null,
        }}
        sideNav={{
          navigation: { navSections },
          isLoading: showLoading,
          additionalContent: (closeMenu: () => void): React.ReactElement => <ContactUsButton onClick={closeMenu} />,
        }}
      >
        <Loading fullPage={true} loading={isLoading}>
          <ModuleViewWrapper>
            <Switch>
              {routes.map((route: RouteProps) => (
                <Route key="route" {...route} />
              ))}
              <Route path="/*" component={PageNotFoundView} />
            </Switch>
          </ModuleViewWrapper>
        </Loading>
      </AppShell>
    </>
  );
};

interface InvalidRouteCheckProps {
  isUserLoggedIn: boolean;
  isLoading: boolean;
}
const InvalidRouteCheck: React.FC<InvalidRouteCheckProps> = ({
  isUserLoggedIn,
  isLoading,
}: InvalidRouteCheckProps): React.ReactElement | null => {
  const { pathname }: Location = useLocation();
  const { currentModule } = useCurrentModule();

  const isAuthenticatedRoute: boolean = !!currentModule && !!currentModule.requiresAuth;
  const isLogoutRoute: boolean = pathname === StaticRoutes.logout;

  const isInvalidAuthRoute: boolean = !isUserLoggedIn && !isLoading && isAuthenticatedRoute;

  const isInvalidLogoutRoute: boolean = isUserLoggedIn && isLogoutRoute;

  return isInvalidAuthRoute ? (
    <Redirect to={StaticRoutes.logout} />
  ) : isInvalidLogoutRoute ? (
    <Redirect to={StaticRoutes.root} />
  ) : null;
};

interface DefaultRedirectProps {
  isUserLoggedIn: boolean;
  isLoading: boolean;
  navSections: NavSection[];
}
const DefaultRedirect: React.FC<DefaultRedirectProps> = ({
  isUserLoggedIn,
  isLoading,
  navSections,
}: DefaultRedirectProps): React.ReactElement | null => {
  const { pathname } = useLocation();
  if (
    isUserLoggedIn &&
    !isLoading &&
    navSections.length > 0 &&
    navSections[0].navItems.length > 0 &&
    pathname === StaticRoutes.root
  ) {
    const firstRoutableNavItem: NavSectionItem | undefined = navSections[0].navItems.find(
      (navItem: NavSectionItem) => !!navItem.internalNav
    );
    if (firstRoutableNavItem?.internalNav) {
      return <Redirect to={firstRoutableNavItem.internalNav.to.toString()} />;
    }
  }
  return null;
};

const sortNavSectionModules = (a: NavSectionItem, b: NavSectionItem): number => {
  const {
    navDisplay: { order: orderA },
  } = appModules[a.key as ModuleTypes];
  const {
    navDisplay: { order: orderB },
  } = appModules[b.key as ModuleTypes];

  return orderA - orderB;
};
const sortNavSections = (a: NavSection, b: NavSection): number => {
  const { order: orderA } = moduleCategoryConfigs[a.title as ModuleCategories];
  const { order: orderB } = moduleCategoryConfigs[b.title as ModuleCategories];

  return orderA - orderB;
};

const buildNavSections = ({
  modules,
  additionalContent,
}: {
  modules: ModuleConfig[];
  additionalContent: { [key in ModuleCategories]?: React.ReactNode };
}): NavSection[] => {
  return modules
    .reduce(
      (
        accumulatedSections: NavSection[],
        { key, navDisplay: { category, name, icon }, route, external }: ModuleConfig
      ) => {
        const { path: routePath, exact } = route || {};
        const moduleNavItem: NavSectionItem = {
          key,
          icon,
          label: name,
          ...(routePath ? { internalNav: { to: routePath.toString(), exact } } : {}),
          ...(external ? { externalNav: { href: external } } : {}),
        };

        const sectionIndex: number = accumulatedSections.findIndex((navSection: NavSection) => {
          return navSection.title === category;
        });

        if (sectionIndex >= 0) {
          const currentSectionValue: NavSection = accumulatedSections[sectionIndex];
          const updatedSectionValue: NavSection = {
            ...currentSectionValue,
            navItems: [...currentSectionValue.navItems, moduleNavItem],
          };

          return [
            ...accumulatedSections.slice(0, sectionIndex),
            updatedSectionValue,
            ...accumulatedSections.slice(sectionIndex + 1),
          ];
        }

        return [
          ...accumulatedSections,
          {
            title: category,
            key: category.replace(' ', '-'),
            navItems: [moduleNavItem],
            additionalContent: additionalContent[category],
          },
        ];
      },
      []
    )
    .map((navSection: NavSection) => ({
      ...navSection,
      navItems: navSection.navItems.sort(sortNavSectionModules),
    }))
    .sort(sortNavSections);
};

const useStyles: () => Record<'providerSelectFormControl' | 'providerSelectIcon' | 'providerSelect', string> =
  makeStyles(() =>
    createStyles({
      providerSelectFormControl: {
        marginBottom: 6,
        width: '100%',
        '& .MuiInputBase-root.Mui-focused .MuiSelect-select': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          border: `2px solid ${axialColors.greenDark75}`,
          borderRadius: 4,
        },
      },
      providerSelect: {
        backgroundColor: 'transparent',
        border: `2px solid ${axialColors.greenDark75}`,
        color: '#fff',
      },
      providerSelectIcon: {
        color: '#fff',
      },
    })
  );

export default App;
