import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { PageNotFoundView } from '../../views/page-not-found';
import { PatientDetailView } from './views/detail';
import { PatientSearchView } from './views/search';
import { PatientFiltersProvider } from './views/search/filters-provider';

export interface PatientDetailRouteParams {
  memberId: string;
}
const PatientIntelligenceView: React.FC = (): React.ReactElement => {
  const { path } = useRouteMatch();
  return (
    <PatientFiltersProvider>
      <Switch>
        <Route exact={true} path={path}>
          <PatientSearchView />
        </Route>
        <Route path={`${path}/:memberId`}>
          <PatientDetailView />
        </Route>
        <Route path="/*" component={PageNotFoundView} />
      </Switch>
    </PatientFiltersProvider>
  );
};

export { PatientIntelligenceView };
