import React, { ReactElement, ChangeEvent, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Button } from '@axial-healthcare/axial-react';
import { moduleRoutes } from 'src/modules/constants';
import { yesNoUnsure } from 'src/constants/survey-answers';
import { RecommendationCard } from '../../components/recommendation-card';
import { SurveyContinue } from '../../components/survey-continue';
import { SurveyQuestion } from '../../components/survey-question';
import { ReportType } from '../../constants';

const OpioidReductionSecondView: React.FC = (): ReactElement => {
  const [state, setState] = useReducer(
    (currentState: Questions, newState: { [key: string]: string }): Questions => {
      return { ...currentState, ...newState };
    },
    {
      patientHasHighMEDD: '',
      patientHasBehavioralHealthCondition: '',
      patientTakesBenzos: '',
      patientCanReduceBenzos: '',
    }
  );

  const handleMarkAllNo = (): void => {
    setState({
      patientHasHighMEDD: 'no',
      patientHasBehavioralHealthCondition: 'no',
      patientTakesBenzos: 'no',
      patientCanReduceBenzos: 'no',
    });
  };

  const handleAnswer =
    (question: string): ((ev: ChangeEvent<HTMLInputElement>) => void) =>
    (ev: ChangeEvent<HTMLInputElement>): void => {
      setState({ [question]: ev.target.value });
    };

  return (
    <>
      <Typography variant="h2" color="primary">
        Please answer the following questions
      </Typography>
      <SurveyQuestion
        label="Does your patient have a Morphine Equivalent Daily Dose (MEDD) over 90?"
        value={state.patientHasHighMEDD}
        onChange={handleAnswer('patientHasHighMEDD')}
        options={yesNoUnsure}
      />
      <SurveyQuestion
        label="Does your patient have poorly controlled or severe behavioral health conditions (e.g.,
            depression, anxiety, bipolar disorder, schizophrenia, etc.)?"
        value={state.patientHasBehavioralHealthCondition}
        onChange={handleAnswer('patientHasBehavioralHealthCondition')}
        options={yesNoUnsure}
      />
      <SurveyQuestion
        label="Does your patient currently take benzodiazepines?"
        value={state.patientTakesBenzos}
        onChange={handleAnswer('patientTakesBenzos')}
        options={yesNoUnsure}
      />
      {state.patientTakesBenzos === 'yes' ? (
        <SurveyQuestion
          label="Can your patient be reduced off benzodiazepines?"
          value={state.patientCanReduceBenzos}
          onChange={handleAnswer('patientCanReduceBenzos')}
          options={yesNoUnsure}
        />
      ) : null}
      <Actions state={state} handleMarkAllNo={handleMarkAllNo} />
    </>
  );
};

interface Questions {
  patientHasHighMEDD: string;
  patientHasBehavioralHealthCondition: string;
  patientTakesBenzos: string;
  patientCanReduceBenzos: string;
}

interface ActionProps {
  state: Questions;
  handleMarkAllNo: () => void;
}
const Actions: React.FC<ActionProps> = ({ state, handleMarkAllNo }: ActionProps): ReactElement => {
  const anyAnsweredYes: boolean = Object.values(state).some((answer: string) => answer === 'yes');
  const allAnsweredNo: boolean = Object.values(state).every((answer: string) => answer === 'no' || answer === 'unsure');

  if (state.patientCanReduceBenzos === 'yes') {
    return (
      <>
        <RecommendationCard title="Benzodiazepine Care Pathway">
          Evidence indicates that opioids and benzodiazepines should not be prescribed concurrently. Click{' '}
          <NavLink to="faqs">here</NavLink> for information regarding the risk factors associated with continued use of
          both medications.
        </RecommendationCard>
        <SurveyContinue
          to={`${process.env.PUBLIC_URL}/files/AlertPathway2_Benzodiazepines_Q417.pdf`}
          text="Go to care pathway"
          isExternal={true}
        />
        <SurveyContinue to="/risk-benefit-analysis" text="I'm still not sure" />
      </>
    );
  } else if (
    (state.patientTakesBenzos === 'yes' && state.patientCanReduceBenzos !== '') ||
    (state.patientTakesBenzos !== 'yes' && anyAnsweredYes)
  ) {
    return (
      <>
        <RecommendationCard title="Let's move forward">
          If the patient is not managed by a pain specialist, opioid reduction should be considered.
        </RecommendationCard>
        <SurveyContinue
          to={`${moduleRoutes.opioidReduction}/report?type=${ReportType.medium}`}
          isSurvey={false}
          text="Go to report"
        />
        <SurveyContinue to="/risk-benefit-analysis" text="I'm still not sure" />
      </>
    );
  } else if (allAnsweredNo) {
    return (
      <>
        <RecommendationCard title="Risk Benefit Analysis">
          Patients taking opioids as part of a multimodal, multidisciplinary approach to treating pain can be safely
          maintained on their treatment regimen with appropriate follow up and monitoring. A formal, documented risk
          benefit analysis (RBA) should be conducted prior to initiating opioid therapy and performed routinely.
        </RecommendationCard>
        <SurveyContinue to="/risk-benefit-analysis" text="Continue to RBA" />
      </>
    );
  }

  return (
    <Button variant="contained" onClick={handleMarkAllNo}>
      Mark All 'No'
    </Button>
  );
};

export { OpioidReductionSecondView };
