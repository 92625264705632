import React from 'react';
import { Card } from '@axial-healthcare/axial-react';
import { DiagnosisTable } from '../components/diagnosis-table';

interface PatientDiagnosesViewProps {
  memberId: string;
}
const PatientDiagnosesView: React.FC<PatientDiagnosesViewProps> = ({
  memberId,
}: PatientDiagnosesViewProps): React.ReactElement => {
  return (
    <Card>
      <DiagnosisTable
        memberId={memberId}
        resultsPerPage={10}
        TableProps={{
          NoResultsProps: { resultsName: 'diagnosis history' },
        }}
      />
    </Card>
  );
};

export { PatientDiagnosesView };
