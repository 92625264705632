import React from 'react';
import { Select, SelectOption, SelectProps } from '@axial-healthcare/axial-react';
import { UserSwitchPostBody } from '../user-provider/interface';

export interface ContextSelectProps extends SelectProps {
  updateContext: (body: UserSwitchPostBody) => void;
  contextType: 'client' | 'npi';
}
const ContextSelect: React.FC<ContextSelectProps> = ({
  contextType,
  updateContext,
  options,
  ...restProps
}: ContextSelectProps): React.ReactElement => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const { value: eventValue } = event.target;
    if (eventValue !== restProps.value) {
      updateContext({ [contextType]: eventValue });
    }
  };

  const elementRole = `${contextType}-select`;

  return (
    <Select
      variant="outlined"
      options={(options || []).map((option: SelectOption) => ({
        ...option,
        idAttribute: `${elementRole}-option-${option.value}`,
      }))}
      id={elementRole}
      aria-label={elementRole}
      onChange={handleChange}
      {...restProps}
    />
  );
};

export { ContextSelect };
