import React from 'react';
import { AxiosResponse } from 'axios';
import { Typography } from '@material-ui/core';
import { axialColors, formatDateString, RequestWrapper, RequestWrapperState } from '@axial-healthcare/axial-react';
import { practiceApi, API_REPORTING_MONTH_FORMAT } from 'src/constants/api';
import { RimScoreDisplay } from '../provider-stratification/components/rim-score-display';
import { RimSummary } from '../provider-stratification/interfaces';

const ProviderStratSummary: React.FC = (): React.ReactElement => {
  return (
    <RequestWrapper<RimSummary[]>
      requestConfig={{
        request: ({ cancelToken }): Promise<AxiosResponse<RimSummary[]>> => {
          return practiceApi.get<RimSummary[]>('/provider/rim/summaries/', { cancelToken });
        },
      }}
    >
      {({ data: summaries }: RequestWrapperState<RimSummary[]>) => {
        const {
          overall_score: score,
          assessed_through_yyyy_mm: reportMonth,
          moving_average: scoreAverage,
        } = summaries[0] || {};
        return (
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: 20, display: 'inline-block' }}>
              <RimScoreDisplay size={180} score={score} scoreAverage={scoreAverage} />
            </div>
            <Typography variant="h4" style={{ color: axialColors.gray50 }}>
              Reporting Month
            </Typography>
            <Typography variant="h2" style={{ color: axialColors.gray75, marginBottom: 0 }}>
              {formatDateString(reportMonth, API_REPORTING_MONTH_FORMAT, 'MMM u')}
            </Typography>
          </div>
        );
      }}
    </RequestWrapper>
  );
};

export { ProviderStratSummary };
