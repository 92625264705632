import React, { useContext, useReducer } from 'react';
import { FilledTextFieldProps } from '@material-ui/core';
import {
  CheckboxListProps,
  CheckboxProps,
  StateReducer,
  stateReducer,
  DateFieldProps,
} from '@axial-healthcare/axial-react';

enum FilterNames {
  lastName = 'lastName',
  firstName = 'firstName',
  dateOfBirth = 'dateOfBirth',
  newNotifications = 'newNotifications',
  notificationType = 'notificationType',
}

export interface PatientFilters {
  [FilterNames.lastName]: string;
  [FilterNames.firstName]: string;
  [FilterNames.dateOfBirth]: Date | null;
  [FilterNames.newNotifications]: boolean;
  [FilterNames.notificationType]: string[];
}
export interface FilterConfig {
  key: FilterNames;
  type: 'text' | 'checkbox-list' | 'checkbox' | 'date';
  TextField?: Omit<FilledTextFieldProps, 'variant' | 'onChange'>;
  CheckboxList?: Omit<CheckboxListProps, 'onChange'>;
  Checkbox?: Omit<CheckboxProps, 'checked'>;
  DatePicker?: Omit<DateFieldProps, 'value' | 'onChange'>;
}

const defaultFiltersState: PatientFilters = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  newNotifications: false,
  notificationType: [],
};

interface FiltersContext {
  filters: PatientFilters;
  setFilters: (filters: Partial<PatientFilters>) => void;
}
const FiltersContext: React.Context<FiltersContext> = React.createContext({
  filters: defaultFiltersState,
  setFilters: (_filters: Partial<PatientFilters>): void => {
    return;
  },
});
const usePatientFilters = (): FiltersContext => {
  const context: FiltersContext = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('usePatientFilters must be used inside a FiltersProvider');
  }
  return context;
};

const PatientFiltersProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [filters, setFilters] = useReducer<StateReducer<PatientFilters>>(stateReducer, defaultFiltersState);

  return <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>;
};

export { PatientFiltersProvider, defaultFiltersState, usePatientFilters, FilterNames };
