import React, { ReactElement, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme } from '@material-ui/core';

interface InfoModalProps {
  text: string;
  style?: React.CSSProperties;
}
const InfoModal: React.FC<InfoModalProps> = ({
  text,
  style,
  children,
}: React.PropsWithChildren<InfoModalProps>): ReactElement => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { palette, typography } = useTheme();

  const toggleModal = (): void => {
    setModalOpen(!modalOpen);
  };

  return (
    <span style={style}>
      <span onClick={toggleModal} style={{ textDecoration: 'underline', cursor: 'pointer' }} role="button">
        {text}
      </span>
      <Dialog maxWidth="sm" open={modalOpen} onClose={toggleModal}>
        <DialogTitle style={{ color: palette.primary.main, borderBottom: `1px solid ${palette.divider}` }}>
          <span style={{ fontWeight: typography.fontWeightBold, fontSize: '1.4rem' }}>{text}</span>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={toggleModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export { InfoModal };
