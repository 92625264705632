import React, { ReactElement } from 'react';
import { MessageCard } from 'src/components/message-card';
import { useUser } from 'src/components/user-provider';
import { messages } from 'src/constants/messages';

const LoggedOutView: React.FC = (): ReactElement => {
  const { login, deactivated } = useUser();
  return (
    <MessageCard
      message={deactivated ? messages.deactivated : messages.logout}
      buttons={deactivated ? [] : [{ children: 'Log in', onClick: login, id: 'login-button' }]}
    />
  );
};

export { LoggedOutView };
