import React, { ReactElement } from 'react';
import { CardProps, styled } from '@material-ui/core';
import { ImageElementProps, maxWidthMediaQueries, Card } from '@axial-healthcare/axial-react';
import wayspringLogo from '@axial-healthcare/axial-branding/images/logo/wayspringLogo.png';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import { NewReferral } from './new-referral';
import { PatientForms } from './patient-forms';
import { ReferralTable } from './referral-table';
import { RecoveryFaqs } from './faqs';

const RecoveryDashboard: React.FC = (): React.ReactElement => {
  return (
    <>
      <TwoColumnLayout>
        <div>
          <WayspringSummary />
          <NewReferral />
          <PatientForms />
        </div>
        <div>
          <RecoveryFaqs />
        </div>
      </TwoColumnLayout>
      <ReferralTable />
    </>
  );
};

const WayspringSummary: React.FC = (): ReactElement => {
  return (
    <CardContainer>
      <TwoColumnLayout className="axialHeader">
        <div style={{ textAlign: 'center' }}>
          <LogoImage src={wayspringLogo} alt="Wayspring" />
          <div className="formerlyAxial">formerly axialHealthcare</div>
        </div>
        <div style={{ marginTop: 0 }}>
          Wayspring, formerly axialHealthcare, is a value-based care entity exclusively focused on supporting
          individuals with substance use disorder (SUD).
        </div>
      </TwoColumnLayout>
    </CardContainer>
  );
};

const CardContainer: React.ComponentType<CardProps> = styled(Card)({
  marginBottom: 10,
  '& .axialHeader': {
    padding: '20px 25px',
    alignItems: 'center',
    [maxWidthMediaQueries.desktop]: {
      padding: 20,
    },
    '& .formerlyAxial': {
      fontSize: '14px',
      [maxWidthMediaQueries.desktop]: {
        marginBottom: 20,
      },
    },
  },
});

const LogoImage: React.ComponentType<ImageElementProps> = styled('img')({
  height: 50,
  margin: '10px 0 -5px',
  display: 'inline-block',
  [maxWidthMediaQueries.tablet]: {
    height: 40,
  },
});

const wayspringColors = {
  green: '#71B48D',
};

export { RecoveryDashboard, wayspringColors };
