import React from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Typography, CardContent, useTheme } from '@material-ui/core';
import { AxialIcon, Results, Card, RequestWrapper, RequestWrapperState } from '@axial-healthcare/axial-react';
import { SubNavIconItem } from 'src/components/sub-nav';
import { practiceApi } from 'src/constants/api';

interface Resource {
  id: number;
  name: string;
  category: string;
  description: string;
  clients: string[];
  source: string;
  url: string;
  is_public: boolean;
}

interface ResourceList {
  category: string;
  categoryName: string;
}

const trackResourceClick = (resourceId: number): Promise<void | AxiosResponse<void>> => {
  return practiceApi.get(`/resource-library/resources/${resourceId}/`).catch((error: AxiosError) => {
    console.error(error);
  });
};

const ResourceList: React.FC<ResourceList> = ({ category, categoryName }: ResourceList): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <div className="resourceLibraryResources" style={{ flex: 1 }}>
      <Typography variant="h2" style={{ marginBottom: '0.6em' }}>
        {categoryName}
      </Typography>
      <RequestWrapper<Resource[]>
        requestConfig={{
          paginationOptions: { resultsPerPage: 5 },
          dependencies: [category],
          request: ({ cancelToken, paginationQueryParams }) => {
            return practiceApi
              .get<Results<Resource>>('/resource-library/resources/', {
                cancelToken,
                params: { ...paginationQueryParams, ...(category ? { category } : {}) },
              })
              .then((response: AxiosResponse<Results<Resource>>) => {
                return response.data.results;
              });
          },
        }}
      >
        {({ data: resources }: RequestWrapperState<Resource[]>) => {
          return (
            <>
              {resources.map((resource: Resource) => {
                const internal: boolean = resource.source === 'internal';
                const external: boolean = resource.source === 'external';
                const download: boolean = resource.source === 'download';

                return (
                  <Card key={resource.name} style={{ marginBottom: '10px' }}>
                    <CardContent style={{ paddingBottom: '14px' }}>
                      <Typography variant="h3" style={{ color: palette.text.primary, marginBottom: 20 }}>
                        {resource.name}
                      </Typography>
                      <p style={{ margin: '2px 0 20px' }}>{resource.description}</p>
                      <a
                        download={download || null}
                        href={resource.url}
                        rel={external ? 'noopener noreferrer' : undefined}
                        target={external ? '_blank' : undefined}
                        style={{ color: palette.primary.main }}
                        onClick={(): Promise<void | AxiosResponse<void>> => trackResourceClick(resource.id)}
                      >
                        <SubNavIconItem
                          icon={internal ? undefined : external ? AxialIcon.linkExternal : AxialIcon.download}
                          text={`${internal ? 'View' : external ? 'Visit' : 'Download'} Resource`}
                        />
                      </a>
                    </CardContent>
                  </Card>
                );
              })}
            </>
          );
        }}
      </RequestWrapper>
    </div>
  );
};

export { ResourceList };
