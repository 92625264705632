import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { StyledList, AxialIcon } from '@axial-healthcare/axial-react';
import { TextBlock } from 'src/components/text-block';
import { SurveyContinue } from '../../components/survey-continue';

const OpioidReductionMonitorView: React.FC = (): ReactElement => {
  return (
    <>
      <Typography variant="h2" color="primary">
        Tips for monitoring your patient
      </Typography>
      <TextBlock>
        If the benefits of opioid reduction outweigh the risks for your patient, consider maintaining your patient on
        chronic opioid therapy after properly addressing the expectations of therapy. Clearly communicate that achieving
        no pain is not a feasible goal as setting unrealistic expectations can lead to rapid and indefinite escalation
        of opioids. Develop an exit strategy with clear criteria for discontinuation such as lack of improvement in pain
        or function, failure to comply with the plan, unexpected findings in urine drug screens, or documented
        prescription misuse.
      </TextBlock>
      <Typography variant="h3">
        The tips below should become a routine component of follow up visits with patients receiving opioids.
      </Typography>
      <StyledList
        style={{ marginBottom: 30 }}
        bulletIcon={{ icon: AxialIcon.okCircledEmpty }}
        items={[
          <>
            Use of validated monitoring tools to address four relevant domains for patients with chronic pain on opioid
            therapy, including documenting the 4 A's at every visit and refill
          </>,
          'Patients should be reassessed at least quarterly',
          'Obtain drug tests at least yearly',
          'Ensure pain agreement signed in the past year',
          'Reassess the diagnosis',
          'Review Prescription Drug Monitoring Program (PDMP) with every prescription',
        ]}
      />
      <SurveyContinue to="" text="Begin a new assessment" />
    </>
  );
};

export { OpioidReductionMonitorView };
