import { RadioProps } from '@axial-healthcare/axial-react';

const yesNo: RadioProps[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const yesNoUnsure: RadioProps[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'unsure', label: "I don't know" },
];

export { yesNo, yesNoUnsure };
