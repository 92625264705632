import React from 'react';
import { CircularProgress, useTheme } from '@material-ui/core';
import { axialColors, AxialIcon, ElementProps } from '@axial-healthcare/axial-react';

interface UploadStatusDisplayProps extends ElementProps<HTMLDivElement> {
  status: 'success' | 'loading' | 'error';
}
const UploadStatusDisplay: React.FC<UploadStatusDisplayProps> = ({
  status,
}: UploadStatusDisplayProps): React.ReactElement => {
  const { palette, typography } = useTheme();

  let content = '';
  let icon: string | undefined;
  let backgroundColor: string | undefined;
  let color: string | undefined;

  switch (status) {
    case 'success':
      content = 'Upload successful!';
      icon = AxialIcon.ok;
      backgroundColor = axialColors.greenDark75;
      color = '#fff';
      break;
    case 'error':
      content = 'Upload failed.';
      icon = AxialIcon.alertPlain;
      backgroundColor = palette.error.main;
      color = '#fff';
      break;
    case 'loading':
      content = 'Uploading...';
      backgroundColor = axialColors.gray10;
      break;
    default:
      break;
  }
  return (
    <div
      style={{
        backgroundColor,
        color,
        padding: '12px 16px',
        borderRadius: 5,
        marginBottom: 10,
        fontFamily: typography.body1.fontFamily,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        fontSize: '1.1rem',
      }}
    >
      {icon ? <i style={{ fontSize: '1.3rem' }} className={icon} /> : <CircularProgress size={28} thickness={5} />}
      <div style={{ marginLeft: 14 }}>{content}</div>
    </div>
  );
};

export { UploadStatusDisplay };
