import React from 'react';
import { NavLinkButton, AxialIcon } from '@axial-healthcare/axial-react';
import { StaticRoutes } from 'src/modules/constants';
import { useTheme } from '@material-ui/core';

interface ContactUsButtonProps {
  onClick?: () => void;
}
const ContactUsButton: React.FC<ContactUsButtonProps> = ({ onClick }: ContactUsButtonProps): React.ReactElement => {
  const { palette } = useTheme();
  return (
    <NavLinkButton
      internalLink={{ to: StaticRoutes.contactUs }}
      style={{ borderRadius: 30, overflow: 'hidden' }}
      ButtonProps={{
        onClick,
        startIcon: <i className={AxialIcon.chat} />,
        style: {
          color: palette.primary.dark,
          backgroundColor: '#fff',
          textTransform: 'uppercase',
          fontWeight: 'normal',
          paddingLeft: 20,
          paddingRight: 20,
        },
      }}
    >
      Contact Us
    </NavLinkButton>
  );
};

export { ContactUsButton };
