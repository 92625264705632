import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { CardContent, CardProps, Typography } from '@material-ui/core';
import {
  Button,
  usePrintStyles,
  AxialIcon,
  mergeCssClasses,
  Card,
  RequestWrapper,
  RequestWrapperState,
} from '@axial-healthcare/axial-react';
import axialLogo from '@axial-healthcare/axial-branding/images/logo/axialLogo.png';
import { practiceApi } from 'src/constants/api';
import { thresholdColors } from 'src/constants/colors';
import { messages } from 'src/constants/messages';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import { useUser } from 'src/components/user-provider';
import { CategoryDetails } from './components/category-details';
import { FrequentlyAskedQuestions } from './components/frequently-asked-questions';
import { ProviderStratHeader } from './components/header';
import { RimReportCategory, RimSummary, RimReport } from './interfaces';

const ProviderStratificationView: React.FC = (): React.ReactElement => {
  const [selectedMonth, setSelectedMonth] = useState<string>('');

  const { onlyPrint, noPrint, printBlock } = usePrintStyles();
  const { name: providerName } = useUser().user.provider || {};

  return (
    <RequestWrapper<RimSummary[]>
      LoadingProps={{ fullPage: true }}
      requestConfig={{
        request: ({ cancelToken, isUnmounted }): Promise<AxiosResponse<RimSummary[]>> => {
          return practiceApi
            .get<RimSummary[]>('/provider/rim/summaries/', { cancelToken })
            .then((response: AxiosResponse<RimSummary[]>) => {
              if (!isUnmounted) {
                setSelectedMonth(response.data[0]?.assessed_through_yyyy_mm || '');
              }
              return response;
            });
        },
      }}
    >
      {({ data: summaries }: RequestWrapperState<RimSummary[]>) => {
        return (
          <RequestWrapper<RimReport>
            LoadingProps={{ fullPage: true }}
            requestConfig={{
              request: ({ cancelToken }): Promise<AxiosResponse<RimReport>> => {
                return practiceApi.get<RimReport>('/provider/rim/report/', {
                  cancelToken,
                  params: { assessed_through_yyyy_mm: selectedMonth },
                });
              },
              dependencies: [selectedMonth],
            }}
          >
            {({ data: report }: RequestWrapperState<RimReport>) => {
              if (!summaries || !selectedMonth) {
                return null;
              }

              const updateSelectedMonth = (month: string): void => {
                if (month !== selectedMonth) {
                  setSelectedMonth(month);
                }
              };

              const print = (): void => {
                window.print();
              };

              return (
                <div>
                  <div
                    style={{
                      marginBottom: 20,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap-reverse',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="h1"
                      style={{
                        marginRight: 20,
                        marginBottom: 0,
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                      }}
                    >
                      RIM Report
                      <span className={onlyPrint} style={{ fontSize: '1.4rem', marginLeft: 8 }}>
                        for {providerName}
                      </span>
                    </Typography>
                    <div>
                      <Button
                        style={{ whiteSpace: 'pre', fontSize: '0.9rem' }}
                        className={noPrint}
                        variant="outlined"
                        size="small"
                        startIcon={<i style={{ fontSize: 16 }} className={AxialIcon.printer} />}
                        onClick={print}
                      >
                        Print this Report
                      </Button>
                      <img style={{ height: '2.3rem' }} className={onlyPrint} src={axialLogo} alt="print-logo" />
                    </div>
                  </div>
                  <TwoColumnLayout
                    className={printBlock}
                    row={{ flexGrow: { columnOne: 3, columnTwo: 1 } }}
                    additionalStyles={{
                      '@media print': {
                        flexDirection: 'column',
                        '& > .faqs': { marginTop: 30, pageBreakBefore: 'always' },
                      },
                    }}
                  >
                    <div>
                      <ProviderStratHeader
                        score={report?.overall_score}
                        reportMonth={{
                          selectedMonth,
                          summaries,
                          handleMonthChange: updateSelectedMonth,
                        }}
                        patientCount={report?.total_members_count}
                      />
                      {report?.overall_score === undefined || report?.overall_score === null || !report?.categories ? (
                        <CardContainer>{messages.rim.scoreNotAvailable}</CardContainer>
                      ) : (
                        <ProviderStratContent categories={report.categories} isReportLatest={report.is_latest} />
                      )}
                    </div>
                    <FrequentlyAskedQuestions className="faqs" />
                  </TwoColumnLayout>
                </div>
              );
            }}
          </RequestWrapper>
        );
      }}
    </RequestWrapper>
  );
};

// 100 is the max rim score
// 5 is the number of threshold colors we have
const SCORE_RANGE_SIZE: number = 100 / 5;
const getScoreThreshold = (score: number | undefined | null): number => {
  if (score === undefined || score === null) {
    return 0;
  }
  return Math.trunc(score / SCORE_RANGE_SIZE) + 1;
};

const getScoreColor = (scoreThreshold: number | null): { color: string; borderColor?: string } => {
  const scoreColor: string = thresholdColors[scoreThreshold || 0];
  return { color: scoreColor, borderColor: scoreThreshold === 1 ? scoreColor : undefined };
};

const CardContainer: React.FC<CardProps> = ({ children, style, ...restProps }: CardProps): React.ReactElement => {
  const { noBorder, noPadding } = usePrintStyles();
  return (
    <Card {...restProps} className={mergeCssClasses(noBorder)} style={{ marginTop: 30, ...style }}>
      <CardContent style={{ padding: '24px 30px' }} className={noPadding}>
        {children}
      </CardContent>
    </Card>
  );
};

interface ProviderStratContentProps {
  categories: RimReportCategory[];
  isReportLatest: boolean;
}
const ProviderStratContent: React.FC<ProviderStratContentProps> = ({
  categories,
  isReportLatest,
}: ProviderStratContentProps): React.ReactElement => {
  return (
    <>
      <Typography variant="h2" style={{ marginTop: 20 }}>
        Patient Cohorts contributing to your RIM score:
      </Typography>
      {categories.map((category: RimReportCategory, index: number) => {
        return (
          <CardContainer style={index === 0 ? { marginTop: 10 } : { pageBreakBefore: 'always' }} key={category.name}>
            <CategoryDetails category={category} isReportLatest={isReportLatest} />
          </CardContainer>
        );
      })}
    </>
  );
};

export { ProviderStratificationView, getScoreThreshold, getScoreColor };
