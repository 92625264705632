import React from 'react';
import { Link } from 'react-router-dom';
import { styled, Typography } from '@material-ui/core';
import { axialColors, ElementProps, formatDateString } from '@axial-healthcare/axial-react';
import { DISPLAY_DATE_FORMAT, API_DATE_FORMAT } from 'src/constants/api';
import { moduleRoutes } from 'src/modules/constants';
import { getDisplayName, Gender } from 'src/constants/utilities';
import { MemberDetail } from '../../../interfaces';

interface PatientDetailHeaderProps extends ElementProps<HTMLDivElement> {
  member: MemberDetail;
}
const PatientDetailHeader: React.FC<PatientDetailHeaderProps> = ({
  member: { first_name: firstName, last_name: lastName, birth_date, gender },
  ...restProps
}: PatientDetailHeaderProps): React.ReactElement => {
  const displayName: string = getDisplayName({ firstName, lastName });
  const dateOfBirth: string = formatDateString(birth_date, API_DATE_FORMAT, DISPLAY_DATE_FORMAT);

  return (
    <div {...restProps}>
      <div style={{ marginBottom: 20 }}>
        <span style={{ color: axialColors.gray50 }}>
          <Link style={{ textDecoration: 'none', color: axialColors.gray50 }} to={moduleRoutes.patientIntelligence}>
            Patient Intelligence
          </Link>
          {' > '}
        </span>
        {displayName}
      </div>
      <Typography variant="h1">{displayName}</Typography>
      <PatientHeaderDetails>
        <div>
          <PatientHeaderDetailLabel>Date of Birth</PatientHeaderDetailLabel>
          <PatientHeaderDetailValue>{dateOfBirth}</PatientHeaderDetailValue>
        </div>
        <div>
          <PatientHeaderDetailLabel>Gender</PatientHeaderDetailLabel>
          <PatientHeaderDetailValue>{Gender[gender]}</PatientHeaderDetailValue>
        </div>
        {/* <div>
          <PatientHeaderDetailLabel>MEDD</PatientHeaderDetailLabel>
          <PatientHeaderDetailValue>{medd}</PatientHeaderDetailValue>
        </div> */}
      </PatientHeaderDetails>
    </div>
  );
};

const PatientHeaderDetails: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '& > div': { marginRight: 80, marginBottom: 15 },
});

const PatientHeaderDetailLabel: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  color: axialColors.gray50,
});
const PatientHeaderDetailValue: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

export { PatientDetailHeader };
