import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { MuiFormik, useRequest, AxialIcon, Button, isBelowFileSizeLimit_MB } from '@axial-healthcare/axial-react';
import { practiceApi, CMResponseWrapper } from 'src/constants/api';
import { wayspringColors } from 'src/modules/recovery-dashboard';
import { UploadCategory, UploadFormValues, ReferralDocumentUploadResponse } from './interfaces';
import { UploadStatusDisplay } from './status-display';

interface DocumentUploadFormProps {
  episodeId: string;
  categories: UploadCategory[];
  innerRef: React.RefObject<FormikProps<UploadFormValues>>;
  setLoadingCount: React.Dispatch<React.SetStateAction<number>>;
  setCompletedCount: React.Dispatch<React.SetStateAction<number>>;
  onRemove?: () => void;
}
const DocumentUploadForm: React.FC<DocumentUploadFormProps> = ({
  innerRef,
  episodeId,
  categories,
  setLoadingCount,
  setCompletedCount,
  onRemove,
}: DocumentUploadFormProps): React.ReactElement => {
  const [formValues, setFormValues] = useState<UploadFormValues>();
  const [postStatus, setPostStatus] = useState<number>();
  const [postError, setPostError] = useState<number>();

  const { palette, typography } = useTheme();
  const { uploadBtn } = useStyles({ wayspringColors });

  const submitDocuments = (values: UploadFormValues): void => {
    setFormValues(values);
  };

  const { status, httpStatus } = useRequest({
    condition: (): boolean => !!formValues,
    dependencies: [formValues],
    request: ({ cancelToken }): Promise<void> => {
      if (!formValues || !formValues.document) {
        return new Promise((reject: () => void): void => {
          reject();
        });
      }
      const formData: FormData = new FormData();
      formData.append('document', formValues.document, formValues.document.name);
      formData.append('category', formValues.category);
      formData.append('episode_id', episodeId);
      if (formValues.note) {
        formData.append('note', formValues.note);
      }

      return practiceApi
        .post<CMResponseWrapper<ReferralDocumentUploadResponse>>('/referrals/upload/', formData, { cancelToken })
        .then((response: AxiosResponse) => {
          setPostStatus(response.status);
          setCompletedCount((currentCompleted: number) => {
            return currentCompleted + 1;
          });
        });
    },
  });

  useEffect(() => {
    setLoadingCount((currentLoading: number) => {
      if (status === 'loading') {
        return currentLoading + 1;
      } else if (currentLoading > 0) {
        return currentLoading - 1;
      }
      return currentLoading;
    });
  }, [status, setLoadingCount]);

  useEffect(() => {
    if (httpStatus && httpStatus >= 500 && httpStatus < 600) {
      setPostError(httpStatus);
      setCompletedCount((currentCompleted: number) => {
        return currentCompleted + 1;
      });
    } else {
      setPostError(undefined);
    }
  }, [httpStatus, setCompletedCount]);

  return status === 'loading' || postError || postStatus ? (
    <UploadStatusDisplay status={status === 'loading' ? 'loading' : postError ? 'error' : 'success'} />
  ) : (
    <div
      style={{
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        border: `1px solid ${palette.divider}`,
        borderRadius: 5,
        padding: '14px 12px 4px',
      }}
    >
      <MuiFormik<UploadFormValues>
        innerRef={innerRef}
        initialValues={{ document: null, category: '', note: '' }}
        formElementProps={{ 'aria-label': 'Patient Document Upload Form' }}
        onSubmit={submitDocuments}
        noSubmitButton={true}
        validationSchema={Yup.object().shape({
          document: Yup.mixed()
            .nullable()
            .test('is-under-size-limit', 'The selected file must be 10MB or less', isBelowFileSizeLimit_MB(10))
            .required('Patient document is required'),
          category: Yup.string().required('Document category is required'),
        })}
        fields={[
          {
            type: 'file',
            name: 'document',
            FileFieldProps: {
              'aria-label': 'Patient Document',
              fileInputProps: {
                accept:
                  'image/bmp, ' +
                  'image/gif, ' +
                  'image/jpeg, ' +
                  'image/png, ' +
                  'image/tiff, ' +
                  'image/x-ms-bmp, ' +
                  'text/csv, ' +
                  'text/plain, ' +
                  'text/rtf, ' +
                  'text/x-vcard, ' +
                  'text/x-po, ' +
                  'application/msword, ' +
                  'application/ogg, ' +
                  'application/pdf, ' +
                  'application/vnd.ms-excel, ' +
                  'application/vnd.ms-office, ' +
                  'application/vnd.ms-powerpoint, ' +
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation, ' +
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ' +
                  'audio/mpeg, ' +
                  'audio/mp4, ' +
                  'audio/x-wav, ' +
                  'video/mpeg, ' +
                  'video/quicktime, ' +
                  'video/x-flv, ' +
                  'video/x-msvideo, ' +
                  'video/x-ms-asf',
              },
              ButtonProps: {
                className: uploadBtn,
              },
            },
          },
          {
            type: 'select',
            name: 'category',
            SelectProps: {
              label: 'Category',
              options: categories.map((category: UploadCategory) => ({
                value: category.id.toString(),
                label: category.display_name,
              })),
              formControlProps: { fullWidth: true },
            },
          },
          {
            type: 'text',
            name: 'note',
            TextFieldProps: {
              inputProps: { 'aria-label': 'Document Notes' },
              label: 'Notes',
              fullWidth: true,
              multiline: true,
              rows: 4,
              rowsMax: 4,
            },
          },
        ]}
      />
      {onRemove ? (
        <Button
          style={{ alignSelf: 'flex-end', marginBottom: 10, fontFamily: typography.body2.fontFamily }}
          startIcon={AxialIcon.cancel}
          variant="outlined"
          size="small"
          onClick={onRemove}
        >
          Remove
        </Button>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles<Theme, { wayspringColors: { green: string } }>((theme) => ({
  uploadBtn: ({ wayspringColors }) => ({
    background: wayspringColors.green,
    fontFamily: theme.typography.body2.fontFamily,
  }),
}));

export { DocumentUploadForm };
