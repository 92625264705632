import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { Accordion, usePrintStyles, mergeCssClasses, Card, useFeatureFlags } from '@axial-healthcare/axial-react';
import { PracticeFeatures } from 'src/constants/environment';
import { messages } from 'src/constants/messages';

interface FrequentlyAskedQuestionsProps {
  style?: React.CSSProperties;
  className?: string;
}
interface FaqSection {
  text: string;
  title: string;
  featureFlag?: PracticeFeatures;
}
const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({
  style,
  className,
}: FrequentlyAskedQuestionsProps): React.ReactElement => {
  const { typography } = useTheme();
  const { noBorder, noPadding } = usePrintStyles();
  const { features } = useFeatureFlags();

  return (
    <Card style={style} className={mergeCssClasses(noBorder, className || '')}>
      <Typography className={noPadding} variant="h3" style={{ padding: '14px 18px' }}>
        Frequently Asked Questions
      </Typography>
      <Accordion
        sections={messages.rim.faq
          .filter((faq: FaqSection) => faq.featureFlag === undefined || features[faq.featureFlag] === true)
          .map((faq: FaqSection, index: number) => ({
            key: faq.title,
            style: { pageBreakInside: 'avoid' },
            title: faq.title,
            children: faq.text,
            idAttribute: `faq-trigger-${index + 1}`,
            ButtonBaseProps: {
              style: {
                pageBreakInside: 'avoid',
                borderTopWidth: 1,
                fontFamily: typography.body2.fontFamily,
                fontWeight: typography.fontWeightBold,
              },
              className: noBorder,
            },
          }))}
      />
    </Card>
  );
};

export { FrequentlyAskedQuestions };
