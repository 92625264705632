import React, { ReactElement } from 'react';
import { styled, Theme } from '@material-ui/core';
import { axialColors, ElementProps } from '@axial-healthcare/axial-react';

interface RimBadgeProps {
  score?: number | null;
  className?: string;
  style?: React.CSSProperties;
  scoreColor?: string;
  size?: number;
  title?: React.ReactElement | string;
  subtitle?: React.ReactElement | string;
}

const RimBadge: React.FC<RimBadgeProps> = ({
  size = 180,
  title,
  subtitle,
  ...restProps
}: RimBadgeProps): React.ReactElement => {
  const { score } = restProps;
  return (
    <RimBadgeContainer size={size} {...restProps}>
      <div className="empty-circle">
        <div className="number-container">
          <RimBadgeNumber
            score={score ? score.toString() : 'N/A'}
            size={size}
            scoreColor={score ? undefined : axialColors.gray25}
            title={title}
            subtitle={subtitle}
          />
        </div>
      </div>
      <div className="filled-circle-container">
        <div className="filled-circle" />
      </div>
    </RimBadgeContainer>
  );
};

interface RimBadgeNumberProps {
  score: string;
  size: number;
  scoreColor?: string;
  title?: React.ReactElement | string;
  subtitle?: React.ReactElement | string;
}
const RimBadgeNumber: React.FC<RimBadgeNumberProps> = ({
  score,
  size,
  scoreColor,
  title,
  subtitle,
}: RimBadgeNumberProps): ReactElement => {
  return (
    <>
      <RimScoreTitle size={size}>{title || 'RIM Score'}</RimScoreTitle>
      <RimScoreNumberDisplay size={size} scoreColor={scoreColor}>
        {score}
      </RimScoreNumberDisplay>
      {subtitle ? <RimScoreSubtitle size={size}>{subtitle}</RimScoreSubtitle> : null}
    </>
  );
};

interface RimCircleProps extends ElementProps<HTMLDivElement> {
  size: number;
}
const RimCircleDiv: React.FC<RimCircleProps> = ({ size, ...restProps }: RimCircleProps): React.ReactElement => {
  return <div {...restProps} />;
};

interface RimBadgeContainerProps extends ElementProps<HTMLDivElement> {
  size: number;
  score?: number | null;
  scoreColor?: string;
}
const RimBadgeContainer: React.ComponentType<RimBadgeContainerProps> = styled(
  ({ size, score, scoreColor, ...restProps }: RimBadgeContainerProps) => <div {...restProps} />
)(({ size, score, scoreColor }: RimBadgeContainerProps) => {
  const borderRadius: number = size / 2;
  const borderWidth: number = size / 10;
  return {
    position: 'relative',
    minWidth: size,
    height: size,
    '& > .empty-circle': {
      height: '100%',
      borderRadius,
      borderWidth,
      borderStyle: 'solid',
      borderColor: axialColors.gray25,
      backgroundColor: '#fff',
    },
    '& > .empty-circle > .number-container': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
    '& > .filled-circle-container': {
      height: '100%',
      width: `${score || 0}%`,
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '& > .filled-circle-container > .filled-circle': {
      minWidth: size,
      height: size,
      borderRadius,
      borderWidth,
      borderStyle: 'solid',
      borderColor: scoreColor || '',
    },
  };
});

const RimScoreTitle: React.ComponentType<RimCircleProps> = styled(RimCircleDiv)(
  ({ theme, size }: { theme: Theme } & RimCircleProps) => ({
    color: axialColors.gray,
    fontSize: `${size * 0.006}rem`,
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'pre',
  })
);

interface RimScoreNumberDisplayProps extends ElementProps<HTMLDivElement> {
  size: number;
  scoreColor?: string;
}
const RimScoreNumberDisplay: React.ComponentType<RimScoreNumberDisplayProps> = styled(
  ({ size, scoreColor, ...restProps }: RimScoreNumberDisplayProps) => <div {...restProps} />
)(({ theme, size, scoreColor }: { theme: Theme } & RimScoreNumberDisplayProps) => ({
  color: scoreColor || axialColors.blueDark,
  fontSize: `${size * 0.02}rem`,
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '-0.08rem',
  lineHeight: '0.875em',
}));

const RimScoreSubtitle: React.ComponentType<RimCircleProps> = styled(RimCircleDiv)(({ size }: RimCircleProps) => ({
  color: axialColors.gray,
  fontSize: `${size * 0.004}rem`,
  lineHeight: '1.2em',
}));

export { RimBadge, RimBadgeNumber };
