import React from 'react';
import { Grid, styled, Typography, useTheme } from '@material-ui/core';
import {
  Accordion,
  axialColors,
  usePrintStyles,
  ElementProps,
  maxWidthMediaQueries,
  minWidthMediaQueries,
} from '@axial-healthcare/axial-react';
import { RimReportCategory, RimReportCategoryMeasure } from '../../interfaces';
import { getScoreThreshold, getScoreColor } from '../../index';
import { ScoreBar } from '../score-bar';
import { CategoryMembers } from './member-list';
import { QualityMeasureSummary, QualityMeasureDetail } from './quality-measures';

interface CategoryDetailsProps {
  category: RimReportCategory;
  isReportLatest: boolean;
}
const CategoryDetails: React.FC<CategoryDetailsProps> = ({
  category,
  isReportLatest,
}: CategoryDetailsProps): React.ReactElement => {
  const { display_name, score, members, measures, members_count, name } = category;
  const { palette } = useTheme();
  const { printBlock, noBorder } = usePrintStyles();

  return (
    <>
      <Grid container={true} direction="row" justify="space-between" alignItems="center" style={{ marginBottom: 14 }}>
        <Typography variant="h2" style={{ color: palette.primary.dark }}>
          {display_name}
        </Typography>
        <CategoryScoreBar score={score} />
      </Grid>
      <CategoryContentContainer className={printBlock}>
        <div className="quality-measures">
          <CategoryDetailHeader>Quality Measures</CategoryDetailHeader>
          <Accordion
            sections={(measures || []).map((measure: RimReportCategoryMeasure, index: number) => {
              const idAttribute = `rim-measure-trigger-${name}-${measure.name}`;
              return {
                key: measure.name,
                idAttribute,
                style: { fontSize: '0.8rem' },
                title: (collapsed: boolean): React.ReactElement => (
                  <QualityMeasureSummary measure={measure} collapsed={collapsed} idAttribute={idAttribute} />
                ),
                ButtonBaseProps: {
                  className: noBorder,
                  style: { borderTopWidth: index !== 0 ? 1 : 0, paddingLeft: 0, paddingRight: 0 },
                },
                CollapseIconProps: { none: true },
                children: <QualityMeasureDetail measure={measure} isReportLatest={isReportLatest} />,
                CollapseContentProps: { style: { padding: 0 } },
              };
            })}
          />
        </div>
        <CategoryMembers
          categoryName={name}
          members={members}
          memberCount={members_count}
          isReportLatest={isReportLatest}
          className="category-members"
        />
      </CategoryContentContainer>
    </>
  );
};

interface CategoryScoreBarProps {
  score: number | null;
}
const CategoryScoreBar: React.FC<CategoryScoreBarProps> = ({ score }: CategoryScoreBarProps): React.ReactElement => {
  return (
    <Grid container={true} direction="row" alignItems="center" style={{ width: 'auto' }}>
      <div style={{ color: axialColors.gray, marginRight: 10 }}>{score === null ? 'N/A' : `${score}/100`}</div>
      <div style={{ width: 100 }}>
        <ScoreBar score={score || 0} scoreMax={100} height={12} {...getScoreColor(getScoreThreshold(score))} />
      </div>
    </Grid>
  );
};

interface CategoryDetailHeaderProps {
  children: React.ReactNode;
}
const CategoryDetailHeader: React.FC<CategoryDetailHeaderProps> = ({
  children,
}: CategoryDetailHeaderProps): React.ReactElement => {
  return (
    <Typography variant="h3" style={{ marginBottom: 14 }}>
      {children}
    </Typography>
  );
};

const CategoryContentContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  '@media print': {
    '& > .category-members': {
      marginTop: 30,
    },
  },
  [maxWidthMediaQueries.tablet]: {
    flexDirection: 'column-reverse',
    '& > .category-members': {
      flexBasis: 'auto',
    },
    '& > .quality-measures': {
      marginTop: 30,
      flexBasis: 'auto',
    },
  },
  [minWidthMediaQueries.desktop]: {
    flexDirection: 'row-reverse',
    '& > .category-members': {
      flexBasis: 0,
      flexGrow: 1,
    },
    '& > .quality-measures': {
      flexGrow: 2,
      flexBasis: 0,
      marginLeft: 30,
    },
  },
});

export { CategoryDetails, CategoryDetailHeader };
