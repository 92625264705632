import React from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { Typography, useTheme } from '@material-ui/core';
import {
  Card,
  AxialIcon,
  RequestWrapper,
  RequestWrapperState,
  Results,
  LinkWrapper,
} from '@axial-healthcare/axial-react';
import { practiceApi } from 'src/constants/api';
import { wayspringColors } from '..';

export interface Resource {
  category: string;
  description: string;
  id: number;
  is_public: boolean;
  name: string;
  source: string;
  url: string;
}

const paginationLength = 5;
const trackResourceClick = (resourceId: number): Promise<void | AxiosResponse<void>> => {
  return practiceApi.get(`/resource-library/resources/${resourceId}/`).catch((error: AxiosError) => {
    console.error(error);
  });
};

const PatientForms: React.FC = (): React.ReactElement => {
  const { typography } = useTheme();
  return (
    <>
      <Typography variant="h2" style={{ fontFamily: typography.body2.fontFamily }}>
        <i className={AxialIcon.docs} style={{ color: wayspringColors.green }} /> Patient Forms
      </Typography>
      <p>
        The following forms should be completed and signed by the patient to give Wayspring permission to provide
        services through the Recovery Solutions program. Please download and share them with the patient at your
        convenience. Once completed, you can upload the documents by clicking the corresponding patient in the table
        below.
      </p>

      <RequestWrapper<Results<Resource>>
        requestConfig={{
          paginationOptions: { resultsPerPage: paginationLength },
          request: ({ cancelToken, paginationQueryParams }): Promise<AxiosResponse<Results<Resource>>> => {
            return practiceApi.get<Results<Resource>>('/referrals/resources/', {
              cancelToken,
              params: { ...paginationQueryParams },
            });
          },
        }}
      >
        {({ data }: RequestWrapperState<Results<Resource>>): React.ReactElement => (
          <div style={{ marginBottom: 30 }}>
            {data.results.map((resource: Resource): React.ReactElement | null => {
              const internal: boolean = resource.source === 'internal';
              const external: boolean = resource.source === 'external';
              const download: boolean = resource.source === 'download';

              if (!resource.url) {
                return null;
              } else {
                return (
                  <Card key={resource.id} style={{ marginBottom: 10 }}>
                    <div style={{ padding: '14px 20px', display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h4">{resource.name}</Typography>
                      <LinkWrapper
                        style={{ color: wayspringColors.green }}
                        externalLink={{
                          download: download || null,
                          href: resource.url,
                          rel: external ? 'noopener noreferrer' : undefined,
                          target: external ? '_blank' : undefined,
                          onClick: (): Promise<void | AxiosResponse<void>> => trackResourceClick(resource.id),
                        }}
                      >
                        {internal ? null : (
                          <i
                            style={{ marginRight: 4 }}
                            className={external ? AxialIcon.linkExternal : AxialIcon.download}
                          />
                        )}
                        {internal ? 'View' : external ? 'Visit' : 'Download'}
                      </LinkWrapper>
                    </div>
                  </Card>
                );
              }
            })}
          </div>
        )}
      </RequestWrapper>
    </>
  );
};

export { PatientForms };
