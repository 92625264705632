import React from 'react';
import { makeStyles } from '@material-ui/core';
import { maxWidthMediaQueries, FeatureFlag, formatDateString } from '@axial-healthcare/axial-react';
import { API_REPORTING_MONTH_FORMAT } from 'src/constants/api';
import { PracticeFeatures } from 'src/constants/environment';
import { getScoreColor, getScoreThreshold } from '../index';
import { RimReportAverage } from '../interfaces';
import { RimBadge } from './rim-badge';

interface RimScoreDisplayProps {
  score: number | null | undefined;
  size: number;
  scoreAverage?: RimReportAverage;
  className?: string;
}
const RimScoreDisplay: React.FC<RimScoreDisplayProps> = ({
  score,
  scoreAverage,
  size,
  className,
}: RimScoreDisplayProps): React.ReactElement => {
  const { portraitPhoneRimAverageTitle } = useStyles();
  return (
    <div style={{ position: 'relative' }} className={className}>
      <RimBadge
        score={score}
        scoreColor={getScoreColor(getScoreThreshold(score)).color}
        size={size}
        subtitle={
          <>
            <div>out of</div>
            <div>100 points</div>
          </>
        }
      />
      <FeatureFlag
        feature={PracticeFeatures.providerStratMovingAverage}
        inactive={null}
        active={
          <>
            {scoreAverage ? (
              <RimBadge
                score={scoreAverage.score}
                scoreColor={getScoreColor(getScoreThreshold(scoreAverage.score)).color}
                size={size * 0.46}
                style={{ position: 'absolute', bottom: 0, left: size * 0.7 }}
                title={
                  <div className={portraitPhoneRimAverageTitle}>
                    <div>
                      {formatDateString(scoreAverage.month_list[0], API_REPORTING_MONTH_FORMAT, 'MMM').toUpperCase()} -{' '}
                      {formatDateString(scoreAverage.month_list[2], API_REPORTING_MONTH_FORMAT, 'MMM').toUpperCase()}
                    </div>
                    <div style={{ lineHeight: '0.3rem', marginBottom: '0.3rem' }}>average</div>
                  </div>
                }
              />
            ) : null}
          </>
        }
      />
    </div>
  );
};

type StylesType = Record<'portraitPhoneRimAverageTitle', string>;
const useStyles: () => StylesType = makeStyles({
  portraitPhoneRimAverageTitle: {
    [maxWidthMediaQueries.portraitPhone]: { fontSize: 8 },
  },
});

export { RimScoreDisplay };
