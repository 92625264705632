import React from 'react';
import { Typography } from '@material-ui/core';
import { PrintPage } from './printout-wrapper';

const FaqPages: React.FC = (): React.ReactElement => {
  return (
    <>
      <PrintPage title="OPIOID REDUCTION FAQS">
        <Typography variant="h3" color="primary">
          What is an opioid reduction?
        </Typography>
        <p>
          An opioid reduction is a slow decrease in the dose of an opioid (pain medication) over time. This type of
          approach helps decrease the risks of taking this type of medication and can help decrease side effects while
          continuing to help your pain. You and your provider should collaborate to decide on a plan that is best for
          you.
        </p>

        <Typography variant="h3" color="primary" style={{ marginBottom: 14 }}>
          When does it make sense to decrease the dose?
        </Typography>
        <Typography variant="h4">When there’s a better way to treat your pain.</Typography>
        <Typography variant="h4">This might be true if:</Typography>
        <ul>
          <li>Your medication is not helping manage your pain anymore</li>
          <li>Other kinds of treatment are safer</li>
          <li>The benefits from your current opioid are not clear</li>
        </ul>
        <Typography variant="h4">When continuing with your current medication could cause you harm.</Typography>
        <Typography variant="h4">This might be true if you:</Typography>
        <ul>
          <li>Have had an overdose</li>
          <li>Have been taking opioids not prescribed to you</li>
          <li>Are using street drugs</li>
          <li>Are on a high dose</li>
          <li>Are taking other medications that interact with your opioid</li>
          <li>Have untreated or severe behavioral health conditions</li>
        </ul>

        <Typography variant="h3" color="primary" style={{ marginBottom: 14 }}>
          How will an opioid reduction help me?
        </Typography>
        <Typography variant="h4">A slow opioid reduction can help in many ways.</Typography>
        <Typography variant="h4">For example, you may:</Typography>
        <ul>
          <li>Experience fewer or less intense side effects</li>
          <li>Find it easier to think clearly</li>
          <li>Sleep better</li>
          <li>Experience less pain</li>
          <li>Find satisfaction in playing a more active role in your pain treatment plan</li>
          <li>Find other ways to cope with and treat your pain</li>
        </ul>
      </PrintPage>
      <PrintPage title="OPIOID REDUCTION FAQS">
        <Typography variant="h3" color="primary">
          What if I have concerns about reducing my opioid dose?
        </Typography>
        <p>
          Share these concerns with your doctor and his/her care team, they will talk through your concerns and create a
          plan based on your needs. The goal is to work collaboratively with your doctor in adjusting your opioid dose
          slowly to avoid withdrawal symptoms while continuing to help or improve your pain.
        </p>
        <Typography variant="h3" color="primary">
          Why would reducing my opioids work now if it has failed in the past?
        </Typography>
        <p>
          Unfortunately, dose reductions are often not done slowly and appropriately. Changing a dose too quickly can
          lead to discomfort, but a slow change over months can prevent withdrawal symptoms. When you work closely with
          your doctor, your experience can be positive and successful.
        </p>
        <Typography variant="h3" color="primary">
          What if my pain gets worse?
        </Typography>
        <p>
          If the opioid dose decrease is done slowly, your pain should not get worse. It is possible you will experience
          a couple of weeks where your pain seems worse - be assured this is not a sign that your condition is getting
          worse - it can happen when your body is getting used to smaller doses. It will get better and your hard work
          will pay off. In some instances, you and your doctor may decide that it may be helpful to pause your opioid
          reduction for a short period of time. In cases of chronic pain, flare-ups occur from time to time and you
          should contact your doctor in these cases.
        </p>
        <Typography variant="h3" color="primary">
          What can I do to help this go smoothly?
        </Typography>
        <p>
          When you and your provider agree on a plan, you have a voice in the process. You can help your provider by
          asking questions, sharing your concerns, and listening to his/her responses. Take notes and keep track of your
          care plan. Put energy and effort into your mental health to cope with normal fears and stressors in a healthy
          way. Many resources are available to help you learn about ways to cope with pain. You may also choose to meet
          with a counselor or psychologist/psychiatrist trained to help people with pain. It can be very helpful to have
          a team of people to walk with you through the process.
        </p>
      </PrintPage>
    </>
  );
};

export { FaqPages };
