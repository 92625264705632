import React from 'react';
import { Grid } from '@material-ui/core';
import { UserMenu } from '@axial-healthcare/axial-react';
import { User, UserSwitchPostBody } from 'src/components/user-provider/interface';
import { ClientSelect } from '../context-select/client-select';

interface UserSettingsProps {
  user: User;
  logout: () => void;
  updateContext: (body: UserSwitchPostBody) => void;
}
const UserSettings: React.FC<UserSettingsProps> = (props: UserSettingsProps): React.ReactElement => {
  const { user, logout, updateContext } = props;
  return (
    <Grid
      style={{ maxWidth: '50%' }}
      container={true}
      justify="flex-end"
      direction="row"
      alignItems="center"
      wrap="nowrap"
    >
      <UserMenu style={{ marginRight: 6 }} logout={logout} userName={user.name || ''} />
      <ClientSelect clients={user.clients || []} currentClient={user.client} updateContext={updateContext} />
    </Grid>
  );
};

export { UserSettings };
