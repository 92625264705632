import React from 'react';
import { CardProps, CardContent, Typography, styled, CardContentProps } from '@material-ui/core';
import {
  usePrintStyles,
  mergeCssClasses,
  maxWidthMediaQueries,
  ElementProps,
  Card,
} from '@axial-healthcare/axial-react';
import axialLogo from '@axial-healthcare/axial-branding/images/logo/axialLogo.png';

interface PrintPage extends CardProps {
  title: string;
}
const PrintPage: React.FC<PrintPage> = ({
  children,
  className,
  style,
  title,
  ...restProps
}: PrintPage): React.ReactElement => {
  const { noBorder, noMargin, noPadding, printBlock, fullPage } = usePrintStyles();
  return (
    <Card
      className={mergeCssClasses(noBorder, noMargin, printBlock, fullPage, className || '')}
      variant="elevation"
      raised={true}
      style={{ marginBottom: 40, minHeight: 900, fontSize: '10pt', ...style }}
      {...restProps}
    >
      <PaddedCardContent className={noPadding}>
        <PageHeaderContainer>
          <Typography variant="h2" style={{ marginTop: 5 }}>
            {title}
          </Typography>
          <img alt="axialHealthcare" src={axialLogo} style={{ display: 'block', height: 34 }} />
        </PageHeaderContainer>
        {children}
      </PaddedCardContent>
    </Card>
  );
};

const PaddedCardContent: React.ComponentType<CardContentProps> = styled(CardContent)({
  padding: 34,
  [maxWidthMediaQueries.portraitPhone]: { padding: '24px 20px' },
});

const PageHeaderContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  '& > *': { marginBottom: 20 },
  [maxWidthMediaQueries.portraitPhone]: {
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
  },
});

export { PrintPage };
