import React from 'react';
import { Redirect, Switch, useRouteMatch, Route } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { StyledList } from '@axial-healthcare/axial-react';
import { CollapsibleText } from 'src/components/collapsible-text';
import { SubNav } from 'src/components/sub-nav';
import { PageNotFoundView } from 'src/views/page-not-found';

const OpioidReductionFaqsView = (): React.ReactElement => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <Typography variant="h2">Frequently Asked Questions</Typography>
      <SubNav
        items={[
          { to: `${url}/providers`, children: 'Providers' },
          { to: `${url}/patients`, children: 'Patients' },
        ]}
      />
      <Switch>
        <Route exact={true} path={path}>
          <Redirect to={`${url}/providers`} />
        </Route>
        <Route path={`${path}/providers`}>
          <ProviderFaqs />
        </Route>
        <Route path={`${path}/patients`}>
          <PatientFaqs />
        </Route>
        <Route path="/*" component={PageNotFoundView} />
      </Switch>
    </>
  );
};

const ProviderFaqs = (): React.ReactElement => {
  return (
    <>
      <CollapsibleText title="Why is long-term opioid therapy often not effective in treating pain?">
        <p>
          There is weak evidence to support the long-term (&gt;12 weeks) benefit of opioids in pain and function.
          Long-term opioid therapy is associated with clinically significant impairment or distress (e.g., addiction,
          abuse, dependence, difficulty reducing/controlling use, social problems, and failure to fulfill major role
          obligations at work, school, or home). Opioid use may, however, be appropriate for short-term analgesia.{'\n'}
        </p>
        <p>
          Any consideration of long-term opioid therapy must follow a comprehensive evaluation and a thorough discussion
          with your patient regarding potential risks and benefits. Providers must first establish realistic treatment
          goals about improvement in function and reducing therapy if goals are not attained. Continuation of long-term
          opioid therapy should include an opioid agreement or consent, ongoing assessment, and appropriate monitoring.
        </p>
        <p>
          You may want to consider opioid reduction after performing a risk benefit analysis. Many patients who try a
          gradual opioid reduction have reported less pain, improved mood, increased function, and an overall better
          quality of life.
        </p>
      </CollapsibleText>
      <CollapsibleText title="What if my patient uses marijuana?">
        <p>
          There is limited research validating the role of marijuana in the treatment of pain or augmentation of opioid
          therapy. Marijuana does alter mood and may play a role in decreasing the anxiety present in many chronic pain
          patients. While many states may have medicalized or legalized marijuana, prescribing opioids is maintained by
          federal regulators (Drug Enforcement Agency) so physicians should counsel patients to stop smoking marijuana
          while receiving chronic opioid therapy.
        </p>
      </CollapsibleText>
      <CollapsibleText title="How to watch for and manage withdrawals?">
        <p>
          Close monitoring of the patient and early intervention can help mitigate symptoms of withdrawal including
          restlessness, nausea, diarrhea, abdominal cramping, anxiety, dysphoria, muscle pain, sleep disturbance, and
          sweating.
        </p>
        <p>Medications can be prescribed to decrease the severity of the symptoms of withdrawal.</p>
        <StyledList
          items={[
            <>
              Clonidine and tizanidine are alpha-2 agonists that can decrease the sympathetically mediated symptoms of
              withdrawal including sweating, restlessness, and tremors
            </>,
            <>
              Anti-nausea medications such as ondansetron or prochlorperazine and antidiarrheal medications such as
              loperamide or bismuth subsalicylate can also help offset the effects of withdrawal
            </>,
            <>Trazodone can be used to help decrease sleep disturbances</>,
            <>NSAIDs or acetaminophen can reduce the muscle pain associated with opioid withdrawal syndrome</>,
          ]}
        />
      </CollapsibleText>
      <CollapsibleText title="What are the risks of reducing opioids too fast?">
        <p>
          Reducing opioids too fast may result in increased withdrawal symptoms and decreased patient buy-in. Without
          patient acceptance and buy-in, the success of tapering dramatically reduces and can result in other aberrant
          behaviors. It is important to ensure patients receive adequate psychosocial support throughout the process. It
          is important to recognize that tapering is an art, not an exact science and the speed and duration of opioid
          reduction should be tailored to the individual needs of the patient.
        </p>
      </CollapsibleText>
      <CollapsibleText title="How to handle short and long-acting combination?">
        <p>
          Offer your patient the option to choose eliminating short- or long-acting medication first. Remember, patient
          buy-in is paramount to compliance and success with opioid reduction. If your patient does not choose their
          preference, select the option that will be the safest and most successful for the patient.
        </p>
      </CollapsibleText>
      <CollapsibleText title="What if a patient does not tolerate the opioid reduction?">
        <p>
          Assess the patient for withdrawal symptoms and consider alternative treatments or opioid reduction approaches.
        </p>
      </CollapsibleText>
      <CollapsibleText title="What are the risks of a forced/provider-initiated opioid reduction?">
        <p>
          In response to the CDC’s 2016 opioid prescribing guidelines, several physicians wrote to the CDC to express
          concern over the guidelines’ misapplication, particularly with regard to tapering and the discontinuation of
          opioid therapy for chronic pain patients. The letter emphasized that involuntary opioid reduction or
          discontinuation has resulted in adverse outcomes, including suicide. In response to that letter, the CDC
          issued a clarifying statement, in which it stated that the guideline "does not endorse mandated or abrupt dose
          reduction or discontinuation, as these actions can result in patient harm." The CDC clarified that the
          guideline includes "recommendations for clinicians to work with patients to taper or reduce dosage only when
          patient harm outweighs patient benefit of opioid therapy."
        </p>
      </CollapsibleText>
      <CollapsibleText title="What are the risk factors associated with concurrent opioid and benzodiazepine use?">
        <StyledList
          items={[
            <>
              Greater pain severity, pain interference with life, and lower feelings of self-efficacy with respect to
              their pain
            </>,
            <>Higher doses of opioids (&gt;200 morphine milligram equivalent, MME)</>,
            <>Use of antidepressant and/or antipsychotic medications</>,
            <>
              Substance use disorders (including illicit and injection drug use, alcohol use disorder, and daily
              nicotine use)
            </>,
            <>Greater mental health comorbidity</>,
          ]}
        />
        <p>
          In rare instances (e.g., severe acute pain in a patient taking long-term, stable low-dose benzodiazepine
          therapy), combined use may be appropriate
        </p>
      </CollapsibleText>
      <CollapsibleText title="Where do these opioid reduction recommendations come from?">
        <p>
          axialHealthcare’s Scientific Advisory Board members developed the opioid reduction care plans and content
          within this tool based on national guidelines and recommendations (including the CDC). As national leaders in
          pain management, behavioral health, and substance use disorder treatment, the recommendations were developed
          with expert consideration of the latest clinical evidence and treatment best practices. Guidance needs to be
          tailored to each individual patient’s needs and should not substitute for the advice of a provider or other
          health care professional.
        </p>
      </CollapsibleText>
    </>
  );
};

const PatientFaqs = (): React.ReactElement => {
  return (
    <>
      <CollapsibleText title="What is an opioid reduction?">
        <p>
          An opioid reduction is a slow decrease in the dose of an opioid (pain medication) over time. This type of
          approach helps decrease the risks of taking this type of medication and can help decrease side effects while
          continuing to help your pain. You and your provider should collaborate to decide on a plan that is best for
          you.
        </p>
      </CollapsibleText>
      <CollapsibleText title="When does it make sense to decrease the dose?">
        <Typography variant="h4" color="primary">
          When there’s a better way to treat your pain. This might be true if:
        </Typography>
        <StyledList
          items={[
            'Your medication is not helping manage your pain anymore',
            'Other kinds of treatment are safer',
            'The benefits from your current opioid are not clear',
          ]}
        />
        <Typography variant="h4" color="primary">
          When continuing with your current medication could cause you harm. This might be true if you:
        </Typography>
        <StyledList
          items={[
            'Have had an overdose',
            'Have been taking opioids not prescribed to you',
            'Are using street drugs',
            'Are on a high dose',
            'Are taking other medications that interact with your opioid',
            'Have untreated or severe behavioral health conditions',
          ]}
        />
      </CollapsibleText>
      <CollapsibleText title="How will an opioid reduction help me?">
        <Typography variant="h4" color="primary">
          A slow opioid reduction can help in many ways. For example, you may:
        </Typography>
        <StyledList
          items={[
            'Experience fewer or less intense side effects',
            'Find it easier to think clearly',
            'Sleep better',
            'Experience less pain',
            'Find satisfaction in playing a more active role in your pain treatment plan',
            'Find other ways to cope with and treat your pain',
          ]}
        />
      </CollapsibleText>
      <CollapsibleText title="What if I have concerns about reducing my opioid dose?">
        <p>
          Share these concerns with your doctor and his/her care team, they will talk through your concerns and create a
          plan based on your needs. The goal is to work collaboratively with your doctor in adjusting your opioid dose
          slowly to avoid withdrawal symptoms while continuing to help or improve your pain.
        </p>
      </CollapsibleText>
      <CollapsibleText title="Why would reducing my opioids work now if it has failed in the past?">
        <p>
          Unfortunately, dose reductions are often not done slowly and appropriately. Changing a dose too quickly can
          lead to discomfort, but a slow change over months can prevent withdrawal symptoms. When you work closely with
          your doctor, your experience can be positive and successful.
        </p>
      </CollapsibleText>
      <CollapsibleText title="What if my pain gets worse?">
        <p>
          If the opioid dose decrease is done slowly, your pain should not get worse. It is possible you will experience
          a couple of weeks where your pain seems worse - be assured this is not a sign that your condition is getting
          worse - it can happen when your body is getting used to smaller doses. It will get better and your hard work
          will pay off. In some instances, you and your doctor may decide that it may be helpful to pause your opioid
          reduction for a short period of time. In cases of chronic pain, flare-ups occur from time to time and you
          should contact your doctor in these cases.
        </p>
      </CollapsibleText>
      <CollapsibleText title="What can I do to help this go smoothly?">
        <p>
          When you and your provider agree on a plan, you have a voice in the process. You can help your provider by
          asking questions, sharing your concerns, and listening to his/her responses. Take notes and keep track of your
          care plan. Put energy and effort into your mental health to cope with normal fears and stressors in a healthy
          way. Many resources are available to help you learn about ways to cope with pain. You may also choose to meet
          with a counselor or psychologist/psychiatrist trained to help people with pain. It can be very helpful to have
          a team of people to walk with you through the process.
        </p>
      </CollapsibleText>
    </>
  );
};

export { OpioidReductionFaqsView };
