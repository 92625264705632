import React from 'react';
import { Typography, styled, RadioGroup, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Radio, axialColors, ElementProps, maxWidthMediaQueries, dateToString } from '@axial-healthcare/axial-react';
import { DISPLAY_DATE_FORMAT } from 'src/constants/api';
import { ReportType } from '../../constants';
import { InputValues } from './index';
import { PrintPage } from './printout-wrapper';

const reportConfig: { [key: string]: { title: string; subtitle: string } } = {
  [ReportType.slow]: {
    title: "Let's take this slow",
    subtitle: '10% every 2 weeks',
  },
  [ReportType.medium]: {
    title: "Let's move forward",
    subtitle: '10-20% every 2-4 weeks',
  },
  [ReportType.fast]: {
    title: "Let's jump right in",
    subtitle: '20% every two weeks',
  },
};

interface ReportPagesProps {
  inputValues: InputValues;
  reportType: ReportType;
}
const ReportPages: React.FC<ReportPagesProps> = ({ inputValues, reportType }: ReportPagesProps): React.ReactElement => {
  const { disabledRadio, indentedSectionContent, sectionContainer } = useStyles();
  return (
    <>
      {/* Page One - reduction goal, dose instructions, non-opioid medications */}
      <ReportPage reportType={reportType} inputValues={inputValues}>
        {/* Section One: Reduction Goal */}
        <div className={sectionContainer}>
          <Typography variant="h3" style={{ fontWeight: 'normal' }}>
            Reduction Goal
          </Typography>
          <ReductionGoalContainer>
            <RadioGroup className="goal-input" value={inputValues.reduceOff}>
              <Radio
                value={'reduce'}
                label="Reduce"
                RadioProps={{ classes: { disabled: disabledRadio } }}
                FormControlLabelProps={{ disabled: true, classes: { disabled: disabledRadio } }}
              />
              <Radio
                value={'off'}
                label="Reduce Off"
                RadioProps={{ classes: { disabled: disabledRadio } }}
                FormControlLabelProps={{ disabled: true, classes: { disabled: disabledRadio } }}
              />
            </RadioGroup>
            <div className="medd-inputs">
              <div>
                <span>Starting MEDD:</span>
                <UnderlinedPrintField>{inputValues.startingMEDD}</UnderlinedPrintField>
              </div>
              <div>
                <span>MEDD Target:</span>
                <UnderlinedPrintField>{inputValues.targetMEDD}</UnderlinedPrintField>
              </div>
            </div>
            <div className="note">
              Note: Once 1/4 - 1/3 of the original opioid dosage is reached, then consider slowing the reduction to 10%
              every 2-4 weeks to prevent withdrawal.
            </div>
          </ReductionGoalContainer>
        </div>
        {/* Section Two: Dose Instructions */}
        <div className={sectionContainer}>
          <Typography variant="h3" style={{ fontWeight: 'normal' }}>
            Dose Instructions
          </Typography>
          <DoseInstructionsContainer className={indentedSectionContent}>
            <div className="phase">
              <span>Phase 1:</span>
              <div>
                <span>Take</span>
                <div className="field">
                  <UnderlinedPrintField />
                  <div className="label">dose</div>
                </div>
                <span>of</span>
                <div className="field">
                  <UnderlinedPrintField />
                  <div className="label">medication</div>
                </div>
                <span>for</span>
                <div className="field">
                  <UnderlinedPrintField />
                  <div className="label">length</div>
                </div>
                <span>week(s)</span>
              </div>
            </div>
            <div className="phase">
              <span>Phase 2:</span>
              <div>
                <span>Take</span>
                <div className="field">
                  <UnderlinedPrintField />
                  <div className="label">dose</div>
                </div>
                <span>of</span>
                <div className="field">
                  <UnderlinedPrintField />
                  <div className="label">medication</div>
                </div>
                <span>for</span>
                <div className="field">
                  <UnderlinedPrintField />
                  <div className="label">length</div>
                </div>
                <span>week(s)</span>
              </div>
            </div>
            <span>or wait until follow-up visit for next dose adjustment</span>
          </DoseInstructionsContainer>
        </div>
        {/* Section Three: Non-Opioid Pain Medications */}
        <div className={sectionContainer}>
          <Typography variant="h3" style={{ fontWeight: 'normal' }}>
            Non-Opioid Pain Medications
          </Typography>
          <NonOpioidPainMedicationContainer className={indentedSectionContent}>
            <div>
              <span>Currently prescribed:</span>
              <UnderlinedPrintField />
            </div>
            <div>
              <span>New medication(s) prescribed:</span>
              <UnderlinedPrintField />
            </div>
            <div>
              <span>Over-the-counter medication instructions (if applicable):</span>
              <UnderlinedPrintField />
            </div>
            <UnderlinedPrintField style={{ width: '100%', marginLeft: 0 }} />
          </NonOpioidPainMedicationContainer>
        </div>
      </ReportPage>
      {/* Page Two - wellness plan, communication plan */}
      <ReportPage reportType={reportType} inputValues={inputValues}>
        <div className={sectionContainer}>
          <Typography variant="h3" style={{ fontWeight: 'normal' }}>
            Wellness Plan
          </Typography>
          <WellnessPlanContainer className={indentedSectionContent}>
            <div className="pain-management-activities">
              <Typography variant="h5">Non Medication Pain Management Activities</Typography>
              <span>
                Examples: Physical therapy, massage, yoga, meditation, walking, counseling or cognitive behavioral
                therapy
              </span>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                  <Typography variant="h5" style={{ whiteSpace: 'pre' }}>
                    Continue with
                  </Typography>
                  <UnderlinedPrintField />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                  <Typography variant="h5" style={{ whiteSpace: 'pre' }}>
                    New activities to try
                  </Typography>
                  <UnderlinedPrintField />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5">Focus on the following things we discussed:</Typography>
              <ul>
                <li>Sleep hygiene</li>
                <li>Healthy eating habits</li>
                <li>Relaxation practices</li>
                <li>Steady, daily exercise</li>
              </ul>
            </div>
          </WellnessPlanContainer>
        </div>
        <div className={sectionContainer}>
          <Typography variant="h3" style={{ fontWeight: 'normal' }}>
            Communication Plan
          </Typography>
          <CommunicationPlanContainer className={indentedSectionContent}>
            <Typography variant="h5">
              <div className="schedule">
                <span>Schedule next visit within the next</span>
                <UnderlinedPrintField style={{ marginRight: 6 }} />
                <span>week(s) or</span>
                <UnderlinedPrintField style={{ marginRight: 6 }} />
                <span>month(s)</span>
              </div>
            </Typography>
            <Typography variant="h5" style={{ marginTop: 10 }}>
              Contact your provider if:
            </Typography>
            <p className={indentedSectionContent} style={{ marginTop: 0 }}>
              You have an event that causes new pain, you have withdrawal symptoms, or you have new or worsening
              anxiety, depression or mood disorder
            </p>
            <Typography variant="h5">
              Your opioid reduction plan is designed to prevent withdrawal symptoms. If you experience the following
              symptoms, talk to your doctor:
            </Typography>
            <p className={indentedSectionContent} style={{ marginTop: 0 }}>
              Restlessness, nausea, diarrhea, abdominal cramping, anxiety, feelings of depression and/or agitation,
              muscle pain, trouble sleeping, sweating
            </p>
          </CommunicationPlanContainer>
        </div>
      </ReportPage>
    </>
  );
};

// Encapsulates a report page with header, patient info, report type information, and a 'notes' footer
const ReportPage: React.FC<ReportPagesProps> = ({
  reportType,
  inputValues,
  children,
}: React.PropsWithChildren<ReportPagesProps>): React.ReactElement => {
  const { sectionContainer } = useStyles();
  return (
    <PrintPage title="OPIOID REDUCTION PLAN">
      <div style={{ border: `2px solid ${axialColors.gray50}`, marginBottom: '10px', padding: '14px' }}>
        <div style={{ color: axialColors.gray75, lineHeight: 1, marginBottom: 10 }}>Patient Information</div>
        <PatientInformationContainer>
          <div style={{ flexGrow: 2 }}>
            <span>Name:</span>
            <UnderlinedPrintField style={{ marginTop: 0 }}>{inputValues.patientName}</UnderlinedPrintField>
          </div>
          <div style={{ flexGrow: 1 }}>
            <span>DOB:</span>
            <UnderlinedPrintField style={{ marginTop: 0 }}>
              {inputValues.patientDOB !== null ? dateToString(inputValues.patientDOB, DISPLAY_DATE_FORMAT) : null}
            </UnderlinedPrintField>
          </div>
        </PatientInformationContainer>
      </div>
      <ReportSpeedContainer className={sectionContainer} style={{ padding: '20px 0' }}>
        <div style={{ flexGrow: 1 }}>
          <Typography variant="h2" color="primary" style={{ margin: 0 }}>
            {reportConfig[reportType].title}
          </Typography>
          <Typography variant="h3" style={{ margin: 0, fontWeight: 'normal' }}>
            {reportConfig[reportType].subtitle}
          </Typography>
        </div>
        <div style={{ flexGrow: 2, width: '100%' }}>
          You and your provider will collaborate to gradually decrease your opioid dose and connect often to make sure
          opioid reduction efforts are going well for you. This approach will help you meet your goals while preventing
          you from experiencing withdrawals or worsening pain.
        </div>
      </ReportSpeedContainer>
      {children}
      <div className={sectionContainer}>
        <Typography variant="h3" style={{ fontWeight: 'normal' }}>
          Notes:
        </Typography>
        <UnderlinedPrintField />
        <UnderlinedPrintField />
        <UnderlinedPrintField />
        <UnderlinedPrintField />
      </div>
    </PrintPage>
  );
};

const UnderlinedPrintField: React.ComponentType<ElementProps<HTMLSpanElement>> = styled('span')(({ theme }) => ({
  display: 'block',
  marginLeft: 6,
  marginTop: 30,
  width: '100%',
  borderBottom: `1px ${theme.palette.text.primary} solid`,
}));

const ReductionGoalContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  '& > *': { flexBasis: 0 },
  '& > .goal-input': { flexGrow: 2 },
  '& > .medd-inputs, & > .note': { flexGrow: 3 },
  '& > .note': { paddingLeft: 20 },
  '& > .medd-inputs > div': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  '& > .medd-inputs > div > span': { whiteSpace: 'pre', fontWeight: theme.typography.fontWeightBold, marginTop: 0 },
  '& > .medd-inputs > div + div': { marginTop: 10 },
  [maxWidthMediaQueries.portraitPhone]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': { flexBasis: 'auto', width: '100%', marginTop: 10 },
    '& > .note': { paddingLeft: 0 },
  },
}));

const DoseInstructionsContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  '& > .phase': { display: 'flex', flexDirection: 'row' },
  '& > .phase span': { whiteSpace: 'pre' },
  '& > .phase > span:first-child': { marginRight: 6 },
  '& > .phase .field': { width: '100%', marginLeft: 5, marginRight: 5 },
  '& > .phase .field > span': { marginLeft: 0, marginTop: 16 },
  '& > .phase .field .label': { width: '100%', textAlign: 'center', fontSize: '0.56rem' },
  '& > .phase > div': { display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' },
  [maxWidthMediaQueries.portraitPhone]: {
    '& > .phase': { flexDirection: 'column', marginBottom: 10 },
  },
});

const NonOpioidPainMedicationContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(
  ({ theme }) => ({
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    '& > div > span:first-child': { whiteSpace: 'pre', fontWeight: theme.typography.fontWeightBold },
    [maxWidthMediaQueries.portraitPhone]: {
      '& > div': { flexDirection: 'column', alignItems: 'flex-start' },
      '& > div > span:last-child': { marginLeft: 0, marginTop: 21 },
      '& > div:last-of-type > span': { whiteSpace: 'normal' },
    },
  })
);

const WellnessPlanContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& > .pain-management-activities': { width: '50%' },
  marginTop: 10,
  [maxWidthMediaQueries.portraitPhone]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& > .pain-management-activities': { width: '100%', marginBottom: 10 },
  },
});

const CommunicationPlanContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  '& .schedule': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
    fontWeight: theme.typography.fontWeightBold,
  },
  '& .schedule > span': { whiteSpace: 'pre' },
  [maxWidthMediaQueries.portraitPhone]: {
    '& .schedule': { flexDirection: 'column', alignItems: 'flex-start' },
  },
}));

const PatientInformationContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  '& > div': { flexBasis: 0, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-end' },
  '& > div + div': { marginLeft: 10 },
  '& > div > span': { fontWeight: theme.typography.fontWeightBold },
  [maxWidthMediaQueries.portraitPhone]: {
    flexDirection: 'column',
    '& > div': { flexBasis: 'auto' },
    '& > div + div': { marginLeft: 0 },
  },
}));

const ReportSpeedContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  '& > div': { flexBasis: 0 },
  '& > div + div': { marginLeft: 10 },
  [maxWidthMediaQueries.portraitPhone]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > div': { flexBasis: 'auto' },
    '& > div + div': { marginLeft: 0 },
  },
});

const useStyles: () => Record<'disabledRadio' | 'indentedSectionContent' | 'sectionContainer', string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      disabledRadio: {
        color: `${theme.palette.text.primary} !important`,
        '&.MuiRadio-root.Mui-checked': { color: `${theme.palette.primary.main} !important` },
        '&.MuiRadio-root': { color: `${axialColors.gray75} !important` },
      },
      indentedSectionContent: {
        marginLeft: 20,
        [maxWidthMediaQueries.portraitPhone]: {
          marginLeft: 0,
        },
      },
      sectionContainer: {
        padding: '14px 0',
        '& + div': {
          borderTop: `2px solid ${axialColors.gray50}`,
        },
      },
    })
);

export { ReportPages };
