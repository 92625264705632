import React, { ReactElement, ChangeEvent, useReducer, useState, useEffect } from 'react';
import { Typography, RadioGroup, styled, useTheme } from '@material-ui/core';
import {
  Radio,
  StateReducer,
  stateReducer,
  usePrintStyles,
  ElementProps,
  maxWidthMediaQueries,
  minWidthMediaQueries,
} from '@axial-healthcare/axial-react';
import { isValid } from 'date-fns';
import { TextBlock } from 'src/components/text-block';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import { useRouteQueryParams } from 'src/constants/utilities';
import { ReportForm } from './form';
import { ReportPages } from './printout-report';
import { FaqPages } from './printout-faqs';
import { ReportType } from '../../constants';

export interface InputValues {
  patientName: string;
  patientDOB: Date | null;
  reduceOff: string;
  includeFAQs: boolean;
  startingMEDD: string;
  targetMEDD: string;
}

const OpioidReductionReportView: React.FC = (): ReactElement => {
  const query: URLSearchParams = useRouteQueryParams();
  const [reportType, setReportType] = useState<ReportType>((query.get('type') as ReportType) || ReportType.none);

  const [inputValues, setState] = useReducer<StateReducer<InputValues>>(stateReducer, {
    patientName: '',
    patientDOB: null,
    reduceOff: '',
    includeFAQs: true,
    startingMEDD: '',
    targetMEDD: '',
  });

  const { printBlock, noPrint } = usePrintStyles();

  useEffect(() => {
    if (inputValues.reduceOff === 'off') {
      setState({ targetMEDD: '0' });
    }
  }, [inputValues.reduceOff]);

  const handleStateChange =
    (attr: string): ((ev: ChangeEvent<HTMLInputElement>) => void) =>
    (ev: ChangeEvent<HTMLInputElement>): void => {
      setState({ [attr]: ev.target.value });
    };

  const handleDateChange = (date: Date | null): void => {
    if (date === null || isValid(date)) {
      setState({ patientDOB: date });
    }
  };

  const togglePatientFAQs = (ev: ChangeEvent<HTMLInputElement>): void => {
    setState({ includeFAQs: ev.target.checked });
  };

  const handleReportTypeChanged = (ev: ChangeEvent<HTMLInputElement>): void => {
    setReportType(ev.target.value as ReportType);
  };

  return (
    <>
      <Typography className={noPrint} variant="h2" color="primary">
        Choose an opioid reduction care plan
      </Typography>
      <TextBlock className={noPrint}>
        While an opioid reduction plan can be temporarily slowed or paused, the opioid dose should not be increased.
      </TextBlock>
      <TaperingSpeed reportType={reportType} handleReportTypeChanged={handleReportTypeChanged} />
      {reportType !== '' ? (
        <TwoColumnLayout
          className={printBlock}
          row={{ flexGrow: { columnOne: 3, columnTwo: 1 } }}
          column={{ reverse: true }}
        >
          <div>
            <ReportPages inputValues={inputValues} reportType={reportType} />
            {inputValues.includeFAQs ? <FaqPages /> : null}
          </div>
          <ReportForm
            className={noPrint}
            inputValues={inputValues}
            handleStateChange={handleStateChange}
            handleDateChange={handleDateChange}
            togglePatientFAQs={togglePatientFAQs}
          />
        </TwoColumnLayout>
      ) : null}
      <p className={noPrint} style={{ margin: 0, padding: 0, fontSize: '0.8rem' }}>
        DISCLAIMER: The content within these pages is intended to provide support to practicing clinicians as they make
        patient care decisions. The clinician should decide whether the suggested concepts and scripting should be
        adapted for each provider/patient conversation and relationship. The suggested opioid reduction approaches are
        not fixed protocols and need to be considered in light of each unique patient. Recommendations were informed by
        national guidelines and recommendations (including the CDC) and the expertise of Axial’s scientific advisory
        board. The clinician should decide whether the suggested language should be used as written; provided that it
        does not serve as a substitute for standard medical practices, protocols or procedures. The resources within
        this clinical decision support platform are not intended to substitute for the advice of a provider or other
        health care professional.
      </p>
    </>
  );
};

interface TaperingSpeedProps {
  reportType: ReportType;
  handleReportTypeChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const TaperingSpeed: React.FC<TaperingSpeedProps> = ({
  reportType,
  handleReportTypeChanged,
}: TaperingSpeedProps): React.ReactElement => {
  const { noPrint } = usePrintStyles();
  const TaperingSpeedOptionContainerStyle: React.CSSProperties = { height: 'auto', alignItems: 'flex-start' };

  return (
    <RadioGroup
      className={noPrint}
      value={reportType}
      onChange={handleReportTypeChanged}
      style={{ marginBottom: '50px' }}
    >
      <TaperingSpeedContainer>
        <Radio
          FormControlLabelProps={{ style: TaperingSpeedOptionContainerStyle }}
          label={
            <TaperingSpeedOption
              title="Let's take this slow"
              description={
                'Patients who have been on opioids for many years and/or those who are hesitant to ' +
                'reduce their dose may be candidates for this option and may require a very gradual ' +
                'reduction to optimize patient buy-in.'
              }
            />
          }
          value={ReportType.slow}
        />
        <Radio
          FormControlLabelProps={{ style: TaperingSpeedOptionContainerStyle }}
          label={
            <TaperingSpeedOption
              title="Let's move forward"
              description={
                'Most patients are candidates for this option, which is a gradual approach that is ' +
                'well accepted and tolerated.'
              }
            />
          }
          value={ReportType.medium}
        />
        <Radio
          FormControlLabelProps={{ style: TaperingSpeedOptionContainerStyle }}
          label={
            <TaperingSpeedOption
              title="Let's jump right in"
              description={
                'Patients highly motivated to reduce their opioid dose or those with notable risks for ' +
                'poor outcomes associated with certain risk factors or behaviors are good candidates for ' +
                'this option. This option may be preferred when the risks outweigh the benefits.'
              }
            />
          }
          value={ReportType.fast}
        />
      </TaperingSpeedContainer>
    </RadioGroup>
  );
};

interface TaperingSpeedOptionProps {
  title: string;
  description: string;
}
const TaperingSpeedOption: React.FC<TaperingSpeedOptionProps> = ({
  title,
  description,
}: TaperingSpeedOptionProps): React.ReactElement => {
  const { typography } = useTheme();
  return (
    <>
      <div
        style={{
          fontSize: '1.25rem',
          fontWeight: typography.fontWeightBold,
          fontFamily: typography.body1.fontFamily,
        }}
      >
        {title}
      </div>
      <p style={{ fontSize: '10pt', margin: 0 }}>{description}</p>
    </>
  );
};

const TaperingSpeedContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [maxWidthMediaQueries.tablet]: {
    flexDirection: 'column',
    '& > *': { width: '100%' },
    '& > * + *': { marginTop: 20 },
  },
  [minWidthMediaQueries.desktop]: {
    flexDirection: 'row',
    '& > *': {
      flexGrow: 1,
      flexBasis: '0%',
    },
  },
});

export { OpioidReductionReportView };
