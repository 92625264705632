import React from 'react';
import { Theme, Typography, useTheme } from '@material-ui/core';
import { axialColors } from '@axial-healthcare/axial-react';
import { TwoColumnLayout } from 'src/components/two-column-layout';
import {
  NotificationTypesProvider,
  NotificationWithCount,
  useNotificationTypesContext,
} from '../../components/notification-types-provider';
import { PatientIntelligenceFilters as Filters } from './filters';
import { usePatientFilters, FilterNames, FilterConfig } from './filters-provider';
import { PatientTableWithData } from './table';

const PatientSearchView: React.FC = (): React.ReactElement => {
  return (
    <NotificationTypesProvider LoadingProps={{ fullPage: true }}>
      <Content />
    </NotificationTypesProvider>
  );
};

const Content: React.FC = (): React.ReactElement => {
  const { filters, setFilters } = usePatientFilters();
  const notificationsWithCount = useNotificationTypesContext();

  const theme = useTheme();

  const filterConfigs: FilterConfig[] = buildFilterConfigs(notificationsWithCount, theme);

  return (
    <>
      <Typography variant="h1">Patient Intelligence</Typography>
      <TwoColumnLayout style={{ width: '100%' }} row={{ flexGrow: { columnOne: 1, columnTwo: 3 } }}>
        <Filters filterConfigs={filterConfigs} initialValues={filters} setFilters={setFilters} />
        <div>
          <PatientTableWithData filters={filters} />
        </div>
      </TwoColumnLayout>
    </>
  );
};

const buildFilterConfigs = (notificationsWithCounts: NotificationWithCount[], theme: Theme): FilterConfig[] => {
  return [
    {
      key: FilterNames.firstName,
      type: 'text',
      TextField: {
        label: 'First Name',
      },
    },
    {
      key: FilterNames.lastName,
      type: 'text',
      TextField: {
        label: 'Last Name',
      },
    },
    {
      key: FilterNames.dateOfBirth,
      type: 'date',
      DatePicker: {
        label: 'Date of Birth',
      },
    },
    {
      key: FilterNames.newNotifications,
      type: 'checkbox',
      Checkbox: {
        label: 'Only New',
        customColor: axialColors.yellow,
      },
    },
    {
      key: FilterNames.notificationType,
      type: 'checkbox-list',
      CheckboxList: {
        label: (
          <span style={{ fontSize: '0.8rem', color: axialColors.gray50, fontWeight: theme.typography.fontWeightBold }}>
            By Notifications
          </span>
        ),
        spacing: 10,
        options: notificationsWithCounts.map(({ key, label, count }: NotificationWithCount) => {
          return {
            key,
            label: <NotificationCheckbox label={label} count={count} />,
            value: key,
          };
        }),
      },
    },
  ];
};

interface NotificationCheckboxProps {
  label: string;
  count: number;
}
const NotificationCheckbox: React.FC<NotificationCheckboxProps> = ({
  label,
  count,
}: NotificationCheckboxProps): React.ReactElement => {
  return (
    <div>
      <div style={{ lineHeight: 1 }}>{label}</div>
      <div style={{ fontSize: '0.74rem', color: count === 0 ? axialColors.gray25 : axialColors.gray75, marginTop: 4 }}>
        {count} total
      </div>
    </div>
  );
};

export { PatientSearchView };
