import { useLocation } from 'react-router-dom';

const getDisplayName = ({ firstName, lastName }: { firstName: string; lastName: string }): string => {
  return `${firstName || ''} ${lastName || ''}`.trim();
};

const Gender: { [key: string]: string } = {
  M: 'Male',
  F: 'Female',
  U: 'Unknown',
};

const useRouteQueryParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export { getDisplayName, useRouteQueryParams, Gender };
