import React, { ReactElement, useState, ChangeEvent } from 'react';
import { Typography } from '@material-ui/core';
import { TextBlock } from 'src/components/text-block';
import { SurveyContinue } from 'src/modules/opioid-reduction/components/survey-continue';
import { SurveyQuestion } from '../../components/survey-question';
import { moduleRoutes } from 'src/modules/constants';

const OpioidReductionIntroView: React.FC = (): ReactElement => {
  const [selection, setSelection] = useState('');

  const updateSelection = (ev: ChangeEvent<HTMLInputElement>): void => {
    setSelection(ev.target.value);
  };

  return (
    <>
      <Typography variant="h2" color="primary">
        Are you and your patient considering reducing opioids?
      </Typography>
      <TextBlock>
        Patient buy-in and motivation is critical to successfully reducing opioids. Fear may drive a patient's
        resistance to opioid reduction, requiring a thoughtful and collaborative care approach.
      </TextBlock>
      <SurveyQuestion
        label="Choose the option that most applies"
        onChange={updateSelection}
        layout="column"
        options={[
          {
            value: `${moduleRoutes.opioidReduction}/report`,
            label: 'Patient could benefit from reducing opioids and is ready',
          },
          {
            value: '/1',
            label: 'Patient risk benefit assessment of continued opioid use is needed',
          },
          {
            value: '/patient-engagement',
            label: 'Patient could benefit from reducing opioids but is hesistant',
          },
        ]}
      />
      {selection ? <SurveyContinue to={selection} isSurvey={!selection.includes('report')} /> : null}
    </>
  );
};

export { OpioidReductionIntroView };
