import React, { ReactElement, ChangeEvent, useReducer } from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@axial-healthcare/axial-react';
import { SurveyContinue } from 'src/modules/opioid-reduction/components/survey-continue';
import { moduleRoutes } from 'src/modules/constants';
import { yesNo, yesNoUnsure } from 'src/constants/survey-answers';
import { SurveyQuestion } from '../../components/survey-question';
import { RecommendationCard } from '../../components/recommendation-card';
import { ReportType } from '../../constants';

const OpioidReductionFirstView: React.FC = (): ReactElement => {
  const [state, setState] = useReducer(
    (currentState: Questions, newState: { [key: string]: string }): Questions => {
      return { ...currentState, ...newState };
    },
    {
      patientHasOverdosed: '',
      patientTakesNonPrescribedOpioids: '',
      patientUsesIllicitDrugs: '',
      patientExhibitsOUD: '',
    }
  );

  const handleMarkAllNo = (): void => {
    setState({
      patientHasOverdosed: 'no',
      patientTakesNonPrescribedOpioids: 'no',
      patientUsesIllicitDrugs: 'no',
      patientExhibitsOUD: 'no',
    });
  };

  const handleAnswer =
    (question: string): ((ev: ChangeEvent<HTMLInputElement>) => void) =>
    (ev: ChangeEvent<HTMLInputElement>): void => {
      setState({ [question]: ev.target.value });
    };

  return (
    <>
      <Typography variant="h2" color="primary">
        Please answer the following questions.
      </Typography>
      <SurveyQuestion
        label="Has your patient had an opioid overdose in the past 3 months?"
        value={state.patientHasOverdosed}
        onChange={handleAnswer('patientHasOverdosed')}
        options={yesNoUnsure}
      />
      <SurveyQuestion
        label="Has your patient reported taking non-prescribed opioids?"
        value={state.patientTakesNonPrescribedOpioids}
        onChange={handleAnswer('patientTakesNonPrescribedOpioids')}
        options={yesNo}
      />
      <SurveyQuestion
        label="Has your patient reported using illicit drugs?"
        value={state.patientUsesIllicitDrugs}
        onChange={handleAnswer('patientUsesIllicitDrugs')}
        options={yesNo}
      />
      <SurveyQuestion
        label="Does your patient exhibit signs of opioid misuse or abuse?"
        value={state.patientExhibitsOUD}
        onChange={handleAnswer('patientExhibitsOUD')}
        options={yesNoUnsure}
      />
      <Actions state={state} handleMarkAllNo={handleMarkAllNo} />
    </>
  );
};

interface Questions {
  patientHasOverdosed: string;
  patientTakesNonPrescribedOpioids: string;
  patientUsesIllicitDrugs: string;
  patientExhibitsOUD: string;
}

interface ActionProps {
  state: Questions;
  handleMarkAllNo: () => void;
}
const Actions: React.FC<ActionProps> = ({ state, handleMarkAllNo }: ActionProps): ReactElement => {
  const anyAnsweredYes: boolean = Object.values(state).some((answer: string) => answer === 'yes');
  const allAnsweredNo: boolean = Object.values(state).every((answer: string) => answer === 'no' || answer === 'unsure');

  if (anyAnsweredYes) {
    return (
      <>
        <RecommendationCard title="Let's jump right in!">
          A collaborative discussion focused on assessing patient motivation and opioid reduction readiness is
          recommended. Patients exhibiting signs of misuse and/or abuse are at an increased risk of overdose and should
          be promptly evaluated for opioid use disorder.
        </RecommendationCard>
        <SurveyContinue
          to={`${moduleRoutes.opioidReduction}/report?type=${ReportType.fast}`}
          isSurvey={false}
          text="Go to report"
        />
        <SurveyContinue to="/2" text="I'm still not sure" />
      </>
    );
  } else if (allAnsweredNo) {
    return <SurveyContinue to="/2" />;
  }

  return (
    <Button variant="contained" onClick={handleMarkAllNo}>
      Mark All 'No'
    </Button>
  );
};

export { OpioidReductionFirstView };
