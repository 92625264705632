import React, { ReactElement } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Typography, styled } from '@material-ui/core';
import {
  usePrintStyles,
  mergeCssClasses,
  ElementProps,
  minWidthMediaQueries,
  maxWidthMediaQueries,
  Card,
} from '@axial-healthcare/axial-react';
import { SubNav } from '../../components/sub-nav';
import { PageNotFoundView } from '../../views/page-not-found';
import { OpioidReductionFaqsView } from './views/faqs';
import { OpioidReductionReportView } from './views/report';
import { OpioidReductionSurveyView } from './views/survey';

const OpioidReductionView: React.FC = (): ReactElement => {
  const { path, url } = useRouteMatch();
  const { noPrint, noBorder, noPadding } = usePrintStyles();

  return (
    <>
      <HeaderContainer className={noPrint}>
        <Typography variant="h1">Opioid Reduction Tool</Typography>
        <SubNav
          ListProps={{ className: 'nav', style: { marginBottom: 0 } }}
          items={[
            { to: `${url}/survey`, children: 'Survey' },
            { to: `${url}/faqs`, children: 'FAQs' },
          ]}
        />
      </HeaderContainer>

      <Card className={mergeCssClasses(noBorder, noPadding)} style={{ padding: '30px' }}>
        <Switch>
          <Route exact={true} path={path}>
            <Redirect to={`${url}/survey`} />
          </Route>
          <Route path={`${path}/survey`}>
            <OpioidReductionSurveyView faqsUrl={`${url}/faqs`} />
          </Route>
          <Route exact={true} path={`${path}/report`}>
            <OpioidReductionReportView />
          </Route>
          <Route path={`${path}/faqs`}>
            <OpioidReductionFaqsView />
          </Route>
          <Route path="/*" component={PageNotFoundView} />
        </Switch>
      </Card>
    </>
  );
};

const HeaderContainer: React.ComponentType<ElementProps<HTMLDivElement>> = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  [maxWidthMediaQueries.tablet]: {
    flexDirection: 'column',
    '& > .nav': { marginTop: '-10px', marginBottom: 10 },
  },
  [minWidthMediaQueries.desktop]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export { OpioidReductionView };
