import Axios, { AxiosInstance } from 'axios';
import { environment } from './environment';

// dates
const API_DATE_FORMAT = 'yyyy-MM-dd';
const API_REPORTING_MONTH_FORMAT = 'yyyy-MM';
const DISPLAY_DATE_FORMAT = 'M/d/yyyy';
const DISPLAY_REPORTING_MONTH_FORMAT = 'MMMM u';

const PROFILE_CHECK_INTERVAL = 1000 * 60 * 15;
const PRACTICE_API_AUTH_HEADER = 'X-CSRFTOKEN';

const practiceApi: AxiosInstance = Axios.create({
  baseURL: environment.api,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export interface ApiPaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}
export interface CMResponseWrapper<T> {
  data: T;
  status: string;
}

export interface ApiOption {
  description: string;
  display_name: string;
  id: number;
  name: string;
  order: number;
}

export {
  practiceApi,
  PROFILE_CHECK_INTERVAL,
  PRACTICE_API_AUTH_HEADER,
  DISPLAY_DATE_FORMAT,
  API_DATE_FORMAT,
  API_REPORTING_MONTH_FORMAT,
  DISPLAY_REPORTING_MONTH_FORMAT,
};
