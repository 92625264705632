import React, { ReactElement } from 'react';
import { NavLinkButton } from '@axial-healthcare/axial-react';
import { moduleRoutes } from 'src/modules/constants';

interface SurveyContinueProps {
  to: string;
  text?: string;
  isExternal?: boolean;
  isSurvey?: boolean;
}
const SurveyContinue: React.FC<SurveyContinueProps> = ({
  to,
  text = 'Continue',
  isExternal,
  isSurvey = true,
}: SurveyContinueProps): ReactElement => {
  return (
    <NavLinkButton
      internalLink={
        isExternal
          ? undefined
          : { to: isSurvey ? `${moduleRoutes.opioidReduction}/survey${to}` : to, style: { marginRight: 4 } }
      }
      externalLink={isExternal ? { href: to, target: '_blank', style: { marginRight: 4 } } : undefined}
      ButtonProps={{
        variant: 'contained',
        color: 'primary',
      }}
    >
      {text}
    </NavLinkButton>
  );
};

export { SurveyContinue };
