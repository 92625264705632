import React from 'react';
import { Grid, makeStyles, createStyles, useTheme } from '@material-ui/core';
import { SelectProps, SelectOption, mergeCssClasses } from '@axial-healthcare/axial-react';
import { UserProvider, UserSwitchPostBody } from 'src/components/user-provider/interface';
import { ContextSelect } from '../index';

interface ProviderSelectProps {
  providers: UserProvider[];
  updateContext: (body: UserSwitchPostBody) => void;
  currentProvider?: UserProvider;
  selectProps?: Partial<SelectProps>;
}
const ProviderSelect: React.FC<ProviderSelectProps> = ({
  providers,
  currentProvider,
  selectProps: { classes, ...restSelectProps } = {},
  updateContext,
}: ProviderSelectProps): React.ReactElement => {
  const { inputPadding } = useStyles();
  const { select, ...restSelectClasses } = classes || {};

  const providerOptions: SelectOption[] = providers.map((provider: UserProvider) => ({
    label: provider.name,
    value: provider.npi,
  }));

  const providerValue: string = currentProvider ? currentProvider.npi : '';

  return (
    <ContextSelect
      contextType="npi"
      updateContext={updateContext}
      options={providerOptions}
      value={providerValue}
      OptionComponent={ProviderOption}
      classes={{ select: mergeCssClasses(inputPadding, select || ''), ...restSelectClasses }}
      {...restSelectProps}
    />
  );
};

const ProviderOption: React.FC<SelectOption> = ({ value, label, idAttribute }: SelectOption): React.ReactElement => {
  const { typography } = useTheme();
  return (
    <Grid id={idAttribute} container={true} direction="column" wrap="nowrap">
      <div
        id={idAttribute}
        style={{
          textTransform: 'capitalize',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: typography.fontWeightBold,
          fontSize: '0.875rem',
        }}
      >
        {label.toLowerCase()}
      </div>
      <div id={idAttribute} style={{ fontSize: '0.75rem', marginTop: '-4px' }}>
        {value}
      </div>
    </Grid>
  );
};

const useStyles: () => Record<'inputPadding', string> = makeStyles(() =>
  createStyles({
    inputPadding: {
      padding: '5px 8px',
    },
  })
);

export { ProviderSelect };
