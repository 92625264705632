import React from 'react';
import { styled, Theme } from '@material-ui/core';
import { ElementProps, maxWidthMediaQueries } from '@axial-healthcare/axial-react';
import { MemberNotification } from '../interfaces';
import { NewIndicator } from './new-indicator';

interface MemberNotificationTagsProps {
  notifications: MemberNotification[];
  color?: string;
}
const MemberNotificationTags: React.FC<MemberNotificationTagsProps> = ({
  notifications,
  color,
}: MemberNotificationTagsProps): React.ReactElement | null => {
  return notifications.length ? (
    <Container color={color}>
      {notifications.map((notification: MemberNotification) => {
        return (
          <div key={notification.member_notification_hash_key}>
            {notification.unviewed && notification.active ? (
              <NewIndicator style={{ display: 'inline-block', marginRight: 6 }} />
            ) : null}
            {notification.notification_type.notification_type_short_name}
          </div>
        );
      })}
    </Container>
  ) : null;
};

interface ContainerProps extends ElementProps<HTMLDivElement> {
  color?: string;
}
const Container = styled(({ color, ...restProps }) => <div {...restProps} />)(
  ({ theme, color }: { theme: Theme } & ContainerProps) => ({
    color,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '0.8rem',
    '& > div': {
      alignSelf: 'flex-start',
      padding: '2px 8px',
      border: `1px solid ${color || theme.palette.text.primary}`,
      borderRadius: 20,
      marginRight: 6,
      marginBottom: 3,
      marginTop: 3,
      whiteSpace: 'pre',
    },
    '& > div:last-child': {
      marginRight: 0,
    },
    [maxWidthMediaQueries.landscapePhone]: {
      flexDirection: 'column',
      '& > div': {
        padding: '1px 6px',
        marginBottom: 1,
        marginTop: 1,
        marginRight: 0,
      },
    },
  })
);

export { MemberNotificationTags };
